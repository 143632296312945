import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PumpBattleIcon from '../../assets/images/pump_battle_icon.png';


function NftBattle({ nftData }: any) {
  const { t } = useTranslation();

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Box>
        <Typography fontWeight={600} fontSize="16px" lineHeight='20.4px' fontFamily="Montserrat" textAlign="end">
          {t('Pump')}
        </Typography>
        <Typography fontWeight={600} fontSize="16px" lineHeight='20.4px' fontFamily="Montserrat">
          {t('battle')}
        </Typography>
      </Box>
      <Box
        component="img"
        // src={`https://reboot-bucket.s3.eu-north-1.amazonaws.com/${nftData?.image}`}
        src={PumpBattleIcon}
        alt={'pump_battle_icon'}
        sx={{
          width: '40px',
          height: '44px',
          objectFit: 'cover',
          borderRadius: '10px',
        }}
      />
    </Stack>
  );
}

export default NftBattle;
