import { useEffect, useState } from 'react';
import { Button, Typography, Theme, Box, TextField, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ThemeNames } from 'resources/theme';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store';
import { changeTheme } from 'store/app';
import { getUserColors, chooseColor, buyColor } from 'store/colors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { routsPatterns, appThemesInfo, privateThemesColors } from 'resources/constants';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';

import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Checked } from 'assets/icons/checked.svg';
import { ReactComponent as Verify } from 'assets/icons/verify.svg';
import Loading from 'components/main/Template/Loading';
import Header from './Header';

function ApplicationColors() {
  const dispatch = useAppDispatch();
  const { loading, userData } = useAppSelector((state: RootState) => state.colors);
  const checkedThemeName = useAppSelector((state: RootState) => state.app).themeName;
  const selectedColor = userData?.data?.colors.find((color: any) => !!color.usersColors.isActive);
  const [selectedColorName, setSelectedColorName] = useState<string>(checkedThemeName);
  const [selectedPrivateColor, setSelectedPrivateColor] = useState<string>('');
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const currentTheme = useTheme();

  const handleOnSaveThemeColor = async () => {
    const resultAction = await dispatch(chooseColor({ colorName: selectedColorName }));
    if (chooseColor.fulfilled.match(resultAction)) {
      dispatch(changeTheme(selectedColorName as ThemeNames));
      setIsStateUpdated(!isStateUpdated);
    }
  };

  const handleBuyColor = async () => {
    const resultAction = await dispatch(buyColor({ colorName: selectedPrivateColor }));
    if (buyColor.fulfilled.match(resultAction)) {
      dispatch(changeTheme(selectedPrivateColor as ThemeNames));
      setIsStateUpdated(!isStateUpdated);
    }
  }

  useEffect(() => {
    dispatch(getUserColors());
  }, [isStateUpdated]);

  useEffect(() => {
    if (selectedColor?.name) {
      setSelectedColorName(selectedColor.name);
    }
  }, [selectedColor?.name]);

  const reportProblemModalContent = (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '14.63px',
        }}
      >
        {t('application_color_buy_modal_content_text')}
      </Typography>
      <Typography
        sx={{
          mb: 3,
          mt: 1,
          lineHeight: '17.07px',
          textAlign: 'start',
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Montserrat',
        }}
        color='primary'
      >
        {`${t('price')}: 10 TON`}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          setIsOpen(false);
          handleBuyColor();
        }}
        sx={(theme: Theme) => ({
          // background: theme.palette.primary.main,
          background: theme.palette.gradient.primaryVertical,
          border: '1px solid rgba(255, 255, 255, 0.5)',
          textTransform: 'none',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '12px',
        })}
      >
        {t('application_color_buy_modal_button_text')}
      </Button>
    </Box>
  );

  const handleOnChoosePremiumThemeColor = (event: React.KeyboardEvent | React.MouseEvent) => {
    const themeName = event.currentTarget.getAttribute('name') as string;
    const isPaid = userData?.data?.colors.find((color: any) => color.name === themeName);
    if (isPaid) {
      setSelectedColorName(themeName);
    } else {
      setIsOpen(true);
      setSelectedPrivateColor(themeName);
    }
  };

  const isColorSelected = (themeName: string, isPrivateState?: boolean) => {
    if (selectedColorName === themeName) {
      return <Checked />;
    }
    const isColorPaid = userData?.data?.colors.find((color: any) => color.name === themeName);
    if (!isColorPaid && isPrivateState) {
      return <Lock />;
    }
    return false;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#000',
        color: '#fff',
        minHeight: '100vh',
        maxHeight: '100vh',
        justifyContent: 'space-between',
        overflow: 'auto',
        p: 1.8,
      }}
    >
      <Grid container>
        <Header title="Application color" path={routsPatterns.SETTINGS} />
        <Typography variant="body2" mt={4} lineHeight="16px" fontFamily="Montserrat">
          {t('choose_color')}
        </Typography>
        <Grid container spacing={2} display="flex" justifyContent="space-between" marginTop={3}>
          {appThemesInfo.publicThemes.map((appTheme) => (
            <Button
              key={appTheme.themeName}
              name={appTheme.themeName}
              onClick={() => setSelectedColorName(appTheme.themeName)}
              sx={(theme: Theme) => ({
                color: appTheme.background,
                borderRadius: '15px',
                height: '74px',
                width: '74px',
                borderColor: theme.palette.white.main,
                borderWidth: 3,
                borderStyle: 'solid',
                background: appTheme.background,
              })}
            >
              {isColorSelected(appTheme.themeName)}
            </Button>
          ))}
        </Grid>

        <Grid container height={20} width="100%" marginTop={3} marginBottom={1}>
          <Verify />
          <Typography
            color="white"
            variant="subtitle2"
            fontFamily="Montserrat"
            sx={{
              fontSize: '14px',
              lineHeight: '17.07px',
              marginLeft: '4px',
            }}
          >
            {t('premium_theme')}
          </Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="space-between"
          // paddingTop={2}
        >
          {appThemesInfo.privateThemes.map((appTheme) => (
            <Button
              key={appTheme.themeName}
              name={appTheme.themeName}
              onClick={handleOnChoosePremiumThemeColor}
              sx={(theme: Theme) => ({
                color: appTheme.background,
                borderRadius: '15px',
                height: '74px',
                width: '74px',
                borderColor: theme.palette.white.main,
                borderWidth: 3,
                borderStyle: 'solid',
                background: appTheme.background,
              })}
            >
              {isColorSelected(appTheme.themeName, true)}
            </Button>
          ))}
        </Grid>
      </Grid>

      <Grid container sx={{ textAlign: 'center', mt: 2 }}>
        <Button
          onClick={handleOnSaveThemeColor}
          variant="contained"
          sx={(theme: Theme) => ({
            color: '#fff',
            background: theme.palette.gradient.primaryVertical,
            border: '1px solid rgba(255, 255, 255, 0.5)',
            textTransform: 'none',
            mb: 1,
            width: '100%',
            borderRadius: '12px',
          })}
        >
          {t('save')}
        </Button>
      </Grid>
      <BottomDrawerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('application_color_buy_modal_title')}
        boxShadow={currentTheme.palette.primary.main}
      >
        {reportProblemModalContent}
      </BottomDrawerModal>
    </Grid>
  );
}

export default ApplicationColors;
