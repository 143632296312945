import routes from 'resources/routes';
import { instance } from './instance';
import { BuyNftPayload, SellNftPayload } from './interface/nft';

const getNftsList = (searchParams: any): Promise<any> => {
  return instance.get(routes.nftsList, {
    params: {
      ...searchParams,
    },
  });
};

const getOneNft = (id: any): Promise<any> => {
  return instance.get(routes.oneNFt.replace(':id', id));
};

const getUserNft = (): Promise<any> => {
  return instance.get(routes.userNfts);
};

const createNft = (data: any): Promise<any> => {
  return instance.post(routes.createNft, data);
}

const sellNft = ({ id, price, count }: SellNftPayload): Promise<any> => {
  return instance.put(routes.sellNft.replace(':id', id), {
    price,
    count,
  });
};

const buyNft = async ({ id, ownerId, count }: BuyNftPayload) => {
  const response = await instance.put(routes.buyNft.replace(':id', id), {
    ownerId,
    count,
  });
  return response.data;
};

export default {
  getNftsList,
  getOneNft,
  getUserNft,
  createNft,
  sellNft,
  buyNft
};
