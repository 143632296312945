import { Stack, Typography } from '@mui/material';
import BackArrowButton from 'components/main/Template/BackArrowButton';

interface HeaderProps {
  title: string;
  path: string;
  withoutArrowBackBtn?: boolean;
}

function Header({ title, path, withoutArrowBackBtn }: HeaderProps) {
  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-between" sx={{ width: '100%' }}>
      {!withoutArrowBackBtn && <BackArrowButton path={path} />}
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          mr: 3,
        }}
        fontFamily="Montserrat"
      >
        {title}
      </Typography>
    </Stack>
  );
}

export default Header;
