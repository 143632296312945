import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { environment, routsPatterns } from 'resources/constants';
import React, { useEffect } from 'react';
import HeaderButtons from './HeaderButtons';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-down2.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-up (1).svg';
import theme from 'resources/theme';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHistoryTokensData } from 'store/history/thunks';
const { AWS_S3_BUCKET_URL } = environment;

function StoryPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const HistoryState = useAppSelector((state) => state.history);

  useEffect(() => {
    dispatch(getHistoryTokensData());
  }, [dispatch]);

  return (
    <Box
      sx={{
        bgcolor: '#000',
        color: '#fff',
        minHeight: '97vh',
        p: 1,
        mt: 1,
      }}>
      <Header title={t('history')} path={routsPatterns.HOME} />
      <Box p={2}>
        <HeaderButtons />
        <List>
          {HistoryState?.getHistoryTokensData?.data?.map((transaction: any) => (
            <React.Fragment key={transaction.id}>
              <ListItem
                sx={{
                  backgroundColor: '#252525',
                  borderRadius: '15px',
                  marginBottom: '4px',
                  padding: 1,
                }}>
                <ListItemAvatar>
                  <Avatar
                    src={`${AWS_S3_BUCKET_URL}/${transaction.token?.imageUrl}`}
                    alt="BYBIT Logo"
                    sx={{ width: 40, height: 40 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 500, fontSize: '12px', fontFamily: 'Montserrat' }}>
                      {transaction.name}
                      <Typography component="span" sx={{ fontWeight: 400, fontSize: '10px', fontFamily: 'Montserrat' }}>
                        ({transaction.amountTon}) {/* Update this as needed */}
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Stack flexDirection="row" alignItems="center" gap="3px">
                      {transaction.type === 'sale' ? (
                        <ArrowUpIcon width={15} height={15} />
                      ) : (
                        <ArrowDownIcon width={15} height={15} />
                      )}
                      <Typography
                        sx={{
                          color: transaction.type === 'sale' ? theme.palette.highlightRed.main : '#33CC66',
                          fontWeight: 600,
                          fontSize: '10px',
                          fontFamily: 'Montserrat',
                        }}>
                        {transaction.type}
                      </Typography>
                    </Stack>
                  }
                />
                <Stack alignItems="end">
                  {/* Displaying the amountTon */}
                  <Typography
                    sx={{
                      color: transaction.type === 'sale' ? theme.palette.highlightRed.main : '#33CC66',
                      fontWeight: 600,
                      fontSize: '12px',
                      fontFamily: 'Montserrat',
                    }}>
                    {transaction.amountTon}
                  </Typography>
                  {/* Displaying the createdAt as the date */}
                  <Typography sx={{ color: 'FFFFFF', fontWeight: 500, fontSize: '10px', fontFamily: 'Montserrat' }}>
                    {new Date(transaction.createdAt).toLocaleString()} {/* Format the date as needed */}
                  </Typography>
                </Stack>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default StoryPage;
