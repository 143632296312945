import { Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { dynamicRouts } from 'resources/constants';
import useTokenDetails from 'hooks/useTokenDetails';

const token = {
  id: '660ec1a8-9abc-4f2d-9a44-b9a1621e3643',
  title: 'Closed for Sale',
  duration: '29 Дней 19 ч. 25 м. 26 с.',
  name: 'PBS1',
  src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSMScOPD5Nn9PNRHiPWLN9bPqfVwrgqyxSBQ&s',
  lastSeen: '2 months ago',
  mc: 'MC: $3,349,597.47',
  percent: '4117.87 %',
  ownerId: '1A1...fNa',
  price: '0.00402095',
};

function TokenInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tokenId } = useParams();

  const goAboutTokenPage = (tokenId: string) => () => {
    navigate(dynamicRouts.TOKENS_ABOUT_TOKEN(tokenId));
  };
  const { tokenDetails } = useTokenDetails(tokenId ?? '');

  const date1 = new Date(Date.now());
  const date2 = new Date(tokenDetails?.info?.startAt * 1000);

  // Subtract the dates (in milliseconds)
  const differenceInMillis = date2.getTime() - date1.getTime();

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));
  const hours = Math.floor((differenceInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000);

  // Format the result
  const result = `${days} d ${hours} h ${minutes} m ${seconds} s`;

  return (
    <Grid className="content" sx={{ px: 2 }}>
      {differenceInMillis > 0 ? (
        <Grid
          container
          width="100%"
          justifyContent="space-between"
          sx={(theme: Theme) => ({
            mt: 0.5,
            p: 2,
            borderRadius: '15px',
            bgcolor: theme.palette.secondary.main,
          })}
        >
          <Typography color="primary" variant="capture" fontSize={18}>
            Closed for Sale
          </Typography>
          <Typography color="primary" variant="capture" fontSize={18}>
            {result}
          </Typography>
        </Grid>
      ) : null}

      <Grid
        container
        width="100%"
        justifyContent="space-between"
        sx={(theme: Theme) => ({
          mt: 0.5,
          p: 1,
          borderRadius: '15px',
          bgcolor: theme.palette.secondary.main,
        })}
        onClick={goAboutTokenPage(tokenDetails?.id)}
      >
        {/* Section 1 */}
        <Grid container alignItems="center">
          <img
            src={`https://reboot-bucket.s3.eu-north-1.amazonaws.com/${tokenDetails?.info?.imageUrl}`}
            alt={token.name}
            width={40}
            height={40}
            style={{ borderRadius: '50%' }}
          />
          <Grid container direction="column" justifyContent="center">
            <Typography color="white" variant="body2" px={1}>
              {tokenDetails?.info?.shortName}
              <CopyIcon style={{ marginLeft: 4 }} />
            </Typography>
            <Link
              color="primary"
              onClick={(event) => event.stopPropagation()}
              to={tokenDetails?.info?.telegramChannel || '#'}
              style={{ display: 'flex', textDecoration: 'none' }}
            >
              <Typography color="primary" variant="capture" ml={1}>
                {'('} by {tokenDetails?.info?.walletAddress || ''}
                {')'}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="flex-end" pt={1}>
          <Typography color="white" variant="body2">
            {tokenDetails?.info?.fullName}
          </Typography>
          <Typography color="primary" variant="capture">
            {tokenDetails?.info?.telegramChannel || ''}
          </Typography>
        </Grid>
      </Grid>

      {/* Section 2 */}
      <Grid
        container
        width="100%"
        justifyContent="space-between"
        sx={(theme: Theme) => ({
          mt: 0.5,
          p: 1,
          borderRadius: '15px',
          bgcolor: theme.palette.secondary.main,
        })}
      >
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_price')}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            {`${tokenDetails?.details?.price} USDT`}
          </Typography>
        </Grid>
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_change', { hours: 24 })}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            {`${tokenDetails?.details?.changed24Hours}%`}
          </Typography>
        </Grid>
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_volume', { hours: 24 })}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            ${tokenDetails?.details?.changed}
          </Typography>
        </Grid>
      </Grid>

      {/* Section 3 */}
      <Grid
        container
        width="100%"
        justifyContent="space-between"
        sx={(theme: Theme) => ({
          mt: 0.5,
          p: 1,
          borderRadius: '15px',
          bgcolor: theme.palette.secondary.main,
        })}
      >
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_market_capital')}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            ${tokenDetails?.details?.tonWithNanoTon}
          </Typography>
        </Grid>
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_liquidity', { hours: 24 })}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            {tokenDetails?.details?.liquidation} TON
          </Typography>
        </Grid>
        <Grid size={4} container direction="column" alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            variant="capture"
            sx={{
              color: '#9FA8B1',
              fontSize: 11,
              fontWeight: 500,
              lineHeight: '16.5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {t('view_token_holders', { hours: 24 })}
            <InfoCircleIcon style={{ height: 10, width: 10, marginLeft: 2 }} />
          </Typography>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 10,
              fontWeight: 600,
              lineHeight: '15px',
              color: theme.palette.white.main,
            })}
          >
            {tokenDetails?.details?.holder}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TokenInfo;
