import { createAsyncThunk } from '@reduxjs/toolkit';
import routes from 'resources/routes';
import services from 'services';

const getHistoryAllData = createAsyncThunk(
  routes.historyAll,
  async (): Promise<any> => {
    const response = await services.getHistoryAll();

    return response.data;
  },
);

const getHistoryBalanceData = createAsyncThunk(
  routes.historyBalance,
  async (): Promise<any> => {
    const response = await services.getHistoryBalance();
    return response.data;
  },
);

const getHistoryTokensData = createAsyncThunk(
  routes.historyTokens,
  async (): Promise<any> => {
    const response = await services.getHistoryTokens();
    return response.data;
  },
);

export { getHistoryAllData, getHistoryBalanceData, getHistoryTokensData };
