import { Box } from '@mui/material';
import Header from './Header';
import { routsPatterns } from 'resources/constants';
import { useTranslation } from 'react-i18next';
import RuleSection from './RuleSection';

function Rules() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#000',
        color: '#fff',
        height: '100vh',
        overflow: 'scroll',
        p: 2,
      }}
    >
      <Header title={t('usage_rules')} path={routsPatterns.SETTINGS} />
      <RuleSection title={t('rules')} description={t('rules_part1')} />
      <RuleSection title={t('rules')} description={t('rules_part2')} />
      <RuleSection title={t('rules')} description={t('rules_part3')} />
      <RuleSection title={t('rules')} description={t('rules_part4')} />
      <RuleSection title={t('rules')} description={t('rules_part5')} />
      <RuleSection title={t('rules')} description={t('rules_part6')} />
      <RuleSection title={t('rules')} description={t('rules_part7')} />
      <RuleSection title={t('rules')} description={t('rules_part8')} lastSection />
    </Box>
  );
}

export default Rules;
