import { instance } from './instance';

const RouletteStart = '/roulette/start'
const RouleteRotate = '/roulette/rotate'
const RouleteNFTs = '/roulette/nfts'

export const rouletteStart = (): Promise<any> => {
    return instance.get(RouletteStart).then(response => response.data);
};

export const fetchNFTs = (): Promise<any> => {
    return instance.get(RouleteNFTs).then(response => response.data);
}

export const rouletteRotate = (payload: any): Promise<any> => {
    return instance.post(RouleteRotate, payload).then(response => response.data)
}