import { createSlice } from '@reduxjs/toolkit';
import type { Color } from 'resources/types';
import { getColors, getUserColors, chooseColor, buyColor } from './thunks';

export interface ColorState {
  data: Array<any>;
  userData: any;
  loading: boolean;
}

const initialState: ColorState = {
  data: [],
  userData: {},
  loading: false,
};

/* eslint-disable no-param-reassign */

const colorSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    reset: () => initialState,
    
  },

  extraReducers: (builder) => {
    builder.addCase(getColors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getColors.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getColors.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getUserColors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserColors.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserColors.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    })

    builder.addCase(chooseColor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(chooseColor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(chooseColor.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(buyColor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(buyColor.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(buyColor.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

const { reset } = colorSlice.actions;

export { reset, getColors, getUserColors, chooseColor, buyColor };

export default colorSlice;
