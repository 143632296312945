import { createTheme, Theme } from '@mui/material/styles';

const defaultTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#75FF47',
      dark: '#119E04',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4F4F4F',
      main: '#252525',
      dark: '#070707',
      contrastText: '#fff',
    },
    warning: {
      light: '#FCEAB8',
      main: '#F9A53E',
      dark: '#F6943E',
      contrastText: '#fff',
    },
    error: {
      light: '#F8B9C4',
      main: '#E9425E',
      dark: '#A62636',
      contrastText: '#fff',
    },
    highlightRed: {
      main: '#F14A38',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#344052',
    },
    black: {
      main: '#252525',
      contrastText: '#fff',
    },
    gradient: {
      primary: `linear-gradient(0deg, #75FF47 0%, #119E04 70%)`,
      primaryVertical: 'linear-gradient(to bottom, #75FF47 0%, #119E04 100%)',
    },
  },

  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#FFFFFF66 !important',
          borderWidth: '0.8px !important',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& legend': {
            display: 'none',
          },
          fieldset: {
            top: 0,
          },
          '.MuiSelect-icon': {
            transition: '350ms',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F2F2',
          borderRadius: '12px !important',
          input: {
            '::placeholder': {
              lineHeight: 14,
              fontWeigh: 400,
              fontSize: 14,
            },
            '&.MuiInputBase-input': {
              padding: '15px 14px',
            },
          },
        },
      },
    },
  },

  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    h6: {
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 800,
      LineHeight: '14.63px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '14.63px',
    },
    capture: {
      fontSize: 10,
      lineHeight: '12.19px',
      fontWeight: 500,
    },
    captureBold: {
      fontSize: 10,
      lineHeight: '12.19px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: '19.98px',
      fontWeight: 700,
    },
    subtitle3: {
      fontSize: 16,
      lineHeight: '17.76px',
      fontWeight: 600,
    },
    inputLabel: {
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 400,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
});

const softCyanTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#6dd5ed',
      dark: '#2193b0',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
      primaryVertical: 'linear-gradient(to bottom, #2193B0 0%, #6DD5ED 100%)',
    },
  },
});

const vividMagentaTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#DA22FF',
      light: '#9733EE',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #DA22FF 1.54%, #9733EE 100%)',
      primaryVertical: 'linear-gradient(to bottom, #DA22FF 0%, #9733EE 100%)',
    },
  },
});

const lightOrangeTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#FFB347',
      light: '#FFCC33',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #FFB347 1.54%, #FFCC33 100%)',
      primaryVertical: 'linear-gradient(to bottom, #FFB347 0%, #FFCC33 100%)',
    },
  },
});

const vividRedTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#FF512F',
      light: '#DD2476',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #FF512F 1.54%, #DD2476 100%)',
      primaryVertical: 'linear-gradient(to bottom, #FF512F 0%, #DD2476 100%)',
    },
  },
});

const veryDarkGrayTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#4C4C4C',
      light: '#9D9EA3',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #4C4C4C 1.54%, #9D9EA3 100%)',
      primaryVertical: 'linear-gradient(to bottom, #4C4C4C 0%, #9D9EA3 100%)',
    },
  },
});

const lightGrayishMagentaTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#F4C4F3',
      dark: '#FC67FA',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #F4C4F3 1.54%, #FC67FA 100%)',
      primaryVertical: 'linear-gradient(to bottom, #F4C4F3 0%, #FC67FA 100%)',
    },
  },
});

const softPinkTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#FD746C',
      dark: '#FF9068',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(360deg, #FD746C 1.54%, #FF9068 100%)',
      primaryVertical: 'linear-gradient(to bottom, #FD746C 0%, #FF9068 100%)',
    },
  },
});

const darkModerateYellowTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#B1993A',
      light: '#F4E683',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary:
        'linear-gradient(135.34deg, #856220 15.43%, #F4E683 34.91%, #BF923D 50.85%, #4E341B 68.56%, #F1EA82 86.26%)',
      primaryVertical:
        'linear-gradient(to bottom, #856220 15.43%, #F4E683 34.91%, #BF923D 50.85%, #4E341B 68.56%, #F1EA82 86.26%)',
    },
  },
});

const darkGrayishBlueTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(138.8deg, #CCE8FE 5.7%, #CDA0FF 27.03%, #8489F5 41.02%, #CDF1FF 68.68%, #B591E9 94%)',
      primaryVertical:
        'linear-gradient(138.8deg, #CCE8FE 5.7%, #CDA0FF 27.03%, #8489F5 41.02%, #CDF1FF 68.68%, #B591E9 94%)',
    },
  },
});

const slightlyDesaturatedGreenTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(314.54deg, #EBC738 3.25%, #86B861 51.02%, #1B9C85 96%)',
      primaryVertical: 'linear-gradient(314.54deg, #EBC738 3.25%, #86B861 51.02%, #1B9C85 96%)',
    },
  },
});

const verySoftCyanTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary:
        'linear-gradient(218.51deg, #FDFFFE 11%, #7ABBAC 31.04%, #B1FFEF 44.99%, #8AD2C3 57.63%, #CFFEF4 75.93%, #6CA196 90.75%, #35544E 98.15%)',
      primaryVertical:
        'linear-gradient(218.51deg, #FDFFFE 11%, #7ABBAC 31.04%, #B1FFEF 44.99%, #8AD2C3 57.63%, #CFFEF4 75.93%, #6CA196 90.75%, #35544E 98.15%)',
    },
  },
});

const vividOrangeTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(135deg, #1E9600 0%, #FFF200 49.16%, #FF0000 100%)',
      primaryVertical: 'linear-gradient(135deg, #1E9600 0%, #FFF200 49.16%, #FF0000 100%)',
    },
  },
});

const slightlyDesaturatedCyan: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(135deg, #FF1E56 0%, #F9C942 50%, #1E90FF 100%)',
      primaryVertical: 'linear-gradient(135deg, #FF1E56 0%, #F9C942 50%, #1E90FF 100%)',
    },
  },
});

const darkCyanTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(135deg, #051937 0%, #004D7A 25%, #008793 50%, #00BF72 75%, #A8EB12 100%)',
      primaryVertical: 'linear-gradient(135deg, #051937 0%, #004D7A 25%, #008793 50%, #00BF72 75%, #A8EB12 100%)',
    },
  },
});

const vividGoldTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#8489F5',
      light: '##CDA0FF',
      contrastText: '#fff',
    },
    highlightRed: defaultTheme.palette.highlightRed,
    secondary: defaultTheme.palette.secondary,
    error: defaultTheme.palette.error,
    white: defaultTheme.palette.white,
    black: defaultTheme.palette.black,
    gradient: {
      primary: 'linear-gradient(135deg, #FF0000 0%, #F7A600 50.11%, #FFE49E 100%)',
      primaryVertical: 'linear-gradient(135deg, #FF0000 0%, #F7A600 50.11%, #FFE49E 100%)',
    },
  },
});

export const themes = {
  lightGreenTheme: defaultTheme,
  softCyanTheme: softCyanTheme,
  vividMagentaTheme: vividMagentaTheme,
  lightOrangeTheme: lightOrangeTheme,
  vividRedTheme: vividRedTheme,
  veryDarkGrayTheme: veryDarkGrayTheme,
  lightGrayishMagentaTheme: lightGrayishMagentaTheme,
  softPinkTheme: softPinkTheme,
  darkModerateYellowTheme: darkModerateYellowTheme,
  darkGrayishBlueTheme: darkGrayishBlueTheme,
  slightlyDesaturatedGreenTheme: slightlyDesaturatedGreenTheme,
  verySoftCyanTheme: verySoftCyanTheme,
  vividOrangeTheme: vividOrangeTheme,
  slightlyDesaturatedCyan: slightlyDesaturatedCyan,
  darkCyanTheme: darkCyanTheme,
  vividGoldTheme: vividGoldTheme,
};

export type ThemeNames =
  | 'lightGreenTheme'
  | 'softCyanTheme'
  | 'vividMagentaTheme'
  | 'lightOrangeTheme'
  | 'vividRedTheme'
  | 'veryDarkGrayTheme'
  | 'lightGrayishMagentaTheme'
  | 'softPinkTheme'
  | 'darkModerateYellowTheme'
  | 'darkGrayishBlueTheme'
  | 'slightlyDesaturatedGreenTheme'
  | 'verySoftCyanTheme'
  | 'vividOrangeTheme'
  | 'slightlyDesaturatedCyan'
  | 'darkCyanTheme'
  | 'vividGoldTheme';

export default softCyanTheme;
