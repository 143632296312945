import routes from 'resources/routes';
import { instance } from './instance';

const getSliderFullData = (): Promise<any> => {
  return instance.get(routes.getSliderData);
};

const getLastNftData = (): Promise<any> => {
  return instance.get(routes.getLastNftData);
};

const getLastTransactionData = (): Promise<any> => {
  return instance.get(routes.getLastTransactionData);
};

export default { getSliderFullData, getLastNftData, getLastTransactionData };
