import { ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import routes from 'resources/routes';
import { instance } from 'services/instance';
import { saveLoginData } from 'store/login';

export default function RestoreUser({ children }: any) {
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const accessToken = params.get('accessToken');
  const refreshToken = params.get('refreshToken');
  
  useEffect(() => {
    try {
      const getData = async () => {
        const { data } = await instance.get(routes.getUserData);
        dispatch(saveLoginData(data.data));
      };
      if (!accessToken && !refreshToken) {
        getData();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return children;
}
