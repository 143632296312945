import { ChangeEvent, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { createNft, getNFTList } from 'store/nft';
import { fileToBase64 } from 'utils';
import * as Yup from 'yup'; // For validation

const CreateModal = ({ searchParams, setIsOpen }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [imageFile, setImageFile] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      image: null as string | null, // Base64 image
      video: null as File | null,  // Video file for upload
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('required', { defaultValue: 'This field is required' })),
      description: Yup.string().required(t('required', { defaultValue: 'This field is required' })),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      if (values.image) {
        formData.append('image', values.image); // Add image as base64 string
      }
      if (values.video) {
        formData.append('video', values.video); // Append video file
      }

      // Dispatch the action or make API request
      try {
        await dispatch(createNft(formData));
      } catch (error) {
        console.error('Error creating NFT:', error);
      }

      await dispatch(getNFTList(searchParams));
      formik.resetForm();
      setImageFile(null);
      setIsOpen(false);
    },
  });

  const onUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const base64 = await fileToBase64(file);
        formik.setFieldValue('image', base64);
        setImageFile(file)
      } catch (error) {
        console.error('Error converting file to Base64:', error);
      }
    }
  };

  // const onUploadVideo = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     formik.setFieldValue('video', file);
  //   }
  // };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      {/* NFT Name Input */}
      <Typography fontSize="12px" fontWeight={400} color="#fff" fontFamily="Montserrat" mb="6px">
        {t('nft_name_label')}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          p: 1,
          mb: 2,
        }}
      >
        <input
          type="text"
          name="name"
          placeholder={t('nft_name_placeholder')}
          style={{
            width: '100%',
            border: 'none',
            backgroundColor: 'transparent',
            color: '#000',
            fontSize: '14px',
            outline: 'none',
          }}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name && (
          <Typography color="red" fontSize="10px">{formik.errors.name}</Typography>
        )}
      </Box>

      <Typography fontSize="12px" fontWeight={400} color="#fff" fontFamily="Montserrat" mb="10px">
        {t('nft_description_label')}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          p: 1,
          mb: 1,
        }}
      >
        <textarea
          name="description"
          placeholder={t('description_placeholder')}
          style={{
            width: '100%',
            border: 'none',
            backgroundColor: 'transparent',
            color: '#000',
            fontSize: '14px',
            outline: 'none',
            resize: 'none',
            height: '60px',
          }}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.description && formik.errors.description && (
          <Typography color="red" fontSize="10px">{formik.errors.description}</Typography>
        )}
      </Box>

      {/* Upload Image */}
      <Typography fontSize="12px" fontWeight={400} color="#fff" fontFamily="Montserrat" mb="10px">
        {t('upload_image_label', { defaultValue: 'Upload Image' })}
      </Typography>
      <Button
        variant="contained"
        component="label"
        startIcon={<AttachFileIcon />}
        sx={(theme: Theme) => ({
          background: theme.palette.gradient.primaryVertical,
          color: '#fff',
          textTransform: 'none',
          borderRadius: '10px',
          mb: 1,
        })}
      >
        {t('choose_file', { defaultValue: 'Choose File' })}
        <input
          hidden
          type="file"
          accept="image/*"
          onChange={onUploadImage}
          value={imageFile ? undefined : ''}
        />
      </Button>
      {formik.values.image && (
        <Typography fontSize="10px" color="green">
          Image uploaded successfully
        </Typography>
      )}

      {/* Upload Video */}
      {/* <Typography fontSize="12px" fontWeight={400} color="#fff" fontFamily="Montserrat" mb="10px">
        {t('upload_video_label', { defaultValue: 'Upload Video (Optional)' })}
      </Typography>
      <Button
        variant="contained"
        component="label"
        startIcon={<AttachFileIcon />}
        sx={(theme: Theme) => ({
          background: theme.palette.gradient.primaryVertical,
          color: '#fff',
          textTransform: 'none',
          borderRadius: '10px',
          mb: 1,
        })}
      >
        {t('choose_file', { defaultValue: 'Choose File' })}
        <input
          hidden
          type="file"
          accept="video/*"
          onChange={onUploadVideo}
        />
      </Button>
      {formik.values.video && (
        <Typography fontSize="10px" color="green">
          {formik.values.video.name}
        </Typography>
      )} */}

      {/* Submit Button */}
      <Button
        variant="contained"
        type="submit"
        sx={(theme: Theme) => ({
          background: theme.palette.gradient.primaryVertical,
          color: '#fff',
          textTransform: 'none',
          borderRadius: '12px',
          width: '100%',
          height: '40px',
          fontWeight: 700,
          fontSize: '14px',
          border: '2px solid #FFFFFF80',
        })}
      >
        {t('create_button', { defaultValue: 'Create' })}
      </Button>
    </Box>
  );
};

export default CreateModal;
