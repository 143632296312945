import { Box, Button, Stack, Theme, Typography } from '@mui/material';

import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';
import Loading from 'components/main/Template/Loading';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { getNFTOne} from 'store/nft';
import { sellNft } from 'store/nft/thunks';

import EmptyUserIcon from '../../assets/images/user_empty.png';
import { useFormik } from 'formik';


function SellNft() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { loading, nftOneData } = useAppSelector((state) => state.nft);

  const formatWallet = (str: string, startChars = 4, endChars = 4) => {
    if (str.length <= startChars + endChars) {
      return str; // No need to truncate if string is short enough
    }
    return `${str.slice(0, startChars)}...${str.slice(-endChars)}`;
  };

  useEffect(() => {
    dispatch(getNFTOne(id));
  }, [dispatch]);

  // if (loading) {
  //   return <Loading />;
  // }

  const formik = useFormik({
    initialValues: {
      id: '',
      price: 0,
      count: 0,
    },
    onSubmit: async (values) => {
      console.log(values);
      // Dispatch the action or make API request
      try {
        id && await dispatch(sellNft({
          id,
          price: values.price,
          count: values.count
        }));
      } catch (error) {
        console.error('Error creating NFT:', error);
      }

      formik.resetForm();
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}
      sx={{
        bgcolor: '#000',
        color: '#fff',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '97vh',
        overflow: 'scroll',
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Header title={t('about_nft')} path={routsPatterns.NFT_MARKET} />
        <Box
          component="img"
          src={`https://reboot-bucket.s3.eu-north-1.amazonaws.com/${nftOneData?.data?.nft.image}`}
          alt={nftOneData?.data.nft.name}
          sx={{
            width: '100%',
            height: '350px',
            objectFit: 'cover',
            borderRadius: '15px',
            mb: '6px',
            mt: 1,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography sx={{ fontWeight: 600, fontSize: '16px', fontFamily: 'Montserrat' }}>
              {nftOneData?.data.nft.name}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontFamily: 'Montserrat' }}>({nftOneData?.data?.quantity} {t('available')})</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#75FF47', fontFamily: 'Montserrat' }}>
                {t('actual_price')}{' '}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#75FF47', fontFamily: 'Montserrat' }}>
                {' '}
                {nftOneData?.data.price}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '100px', // Adjust width as needed
              height: '50px',
              padding: 2,
              backgroundColor: 'black', // Background color
              color: 'white', // Text color
              borderRadius: 2, // Rounded corners
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Stack>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Montserrat' }}>
                {t("creator")}{' '}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Montserrat' }}>
                {formatWallet(nftOneData?.data?.nft?.users[0]?.walletAddress || 'N/A')}
              </Typography>
            </Stack>
            <Box
              sx={{
                width: '50px', // Adjust the size of the avatar placeholder
                height: '50px',
                backgroundColor: 'white', // Placeholder color
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden', // For image cropping
              }}
            >
              {/* Replace this with an <img> tag for a real image */}
              <Typography variant="body2" sx={{ color: 'black' }}>
              <img src={EmptyUserIcon} alt={'user_empty'} width={40} height={40}/>
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack mt={1}>
          <Typography fontSize="12px" fontWeight={400} color="#fff" fontFamily="Montserrat">
            {t('number_nft')}
          </Typography>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              p: 1,
              mb: 1,
            }}>
            <input
              type="number"
              name='count'
              value={formik.values.count}
              onChange={formik.handleChange}
              placeholder={t('number_nft')}
              style={{
                width: '100%',
                border: 'none',
                backgroundColor: 'transparent',
                color: '#000',
                fontSize: '14px',
                outline: 'none',
                padding: '6px',
              }}
            />
          </Box>

          <Typography fontWeight={400} fontSize="12px" fontFamily="Montserrat" mb="6px">
            {t('price')}
          </Typography>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              p: 1,
              mb: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <input
              type="number"
              name='price'
              value={formik.values.price}
              onChange={formik.handleChange}
              placeholder={t('price')}
              style={{
                width: '100%',
                border: 'none',
                backgroundColor: 'transparent',
                color: '#000',
                fontSize: '14px',
                outline: 'none',
                flexGrow: 1,
                padding: '6px',
              }}
            />
            <Typography
              sx={{
                mr: 1,
                fontSize: '14px',
                fontWeight: 600,
                color: '#070707',
              }}>
              TON
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Button
          variant="contained"
          type="submit"
          sx={(theme: Theme) => ({
            background: theme.palette.gradient.primary,
            color: '#fff',
            mb: 2,
            width: '100%',
            borderRadius: '12px',
            textTransform: 'none',
            height: '40px',
            fontWeight: 700,
            fontSize: '14px',
            border: '2px solid #FFFFFF80',
          })}>
          {t('sell')}
        </Button>
      </Box>
    </Box>
  );
}

export default SellNft;
