import { Box, Button, Checkbox, FormControlLabel, Stack, TextField, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import Header from './Header';
import { routsPatterns } from 'resources/constants';
import { useTranslation } from 'react-i18next';

function PasswordAndSecurity() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [checkboxState, setCheckboxState] = useState({
    withdrawalConfirmation: false,
    tokenPurchase: true,
    tokenSale: false,
    nftPackagePurchase: false,
    nftMarketPurchase: true,
    nftSale: false,
  });
  const { t } = useTranslation();

  const handleCheckboxChange = (key: string) => {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: '#000',
        color: '#fff',
        minHeight: '97vh',
        p: 2,
      }}
    >
      <Header title={t('security')} path={routsPatterns.SETTINGS} />

      <Box mb="55%">
        <Box>
          <Typography variant="body1" fontFamily="Montserrat" gutterBottom>
            {t('enter_password')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('enter_password_placeholder')}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                bgcolor: '#fff',
                borderRadius: '12px',
              },
            }}
          />
          <Typography variant="body1" fontFamily="Montserrat" gutterBottom>
            {t('confirm_password')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t('confirm_password_placeholder')}
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                bgcolor: '#fff',
                borderRadius: '12px',
              },
            }}
          />
        </Box>

        <Stack>
          <Typography variant="body1" fontWeight="bold" fontFamily="Montserrat" gutterBottom>
            {t('use_password_for')}
          </Typography>
          {[
            { label: t('withdrawal_confirmation'), key: 'withdrawalConfirmation' },
            { label: t('token_purchase'), key: 'tokenPurchase' },
            { label: t('token_sale'), key: 'tokenSale' },
            { label: t('nft_package_purchase'), key: 'nftPackagePurchase' },
            { label: t('nft_market_purchase'), key: 'nftMarketPurchase' },
            { label: t('nft_sale'), key: 'nftSale' },
          ].map((item) => (
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  // checked={checkboxState[item.key]}
                  onChange={() => handleCheckboxChange(item.key)}
                  sx={{
                    color: '#75FF47',
                    '&.Mui-checked': {
                      color: '#75FF47',
                    },
                  }}
                />
              }
              label={<Typography sx={{ color: '#fff', fontFamily: 'Montserrat' }}>{item.label}</Typography>}
            />
          ))}
        </Stack>
      </Box>

      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button
          variant="contained"
          sx={(theme: Theme) => ({
            color: '#fff',
            background: theme.palette.gradient.primaryVertical,
            border: '1px solid rgba(255, 255, 255, 0.5)',
            textTransform: 'none',
            mb: 1,
            width: '100%',
            borderRadius: '12px',
          })}
        >
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
}

export default PasswordAndSecurity;
