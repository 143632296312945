import { createSlice } from '@reduxjs/toolkit';
import { getSliderData, getLastNftData, getLastTransactionData } from './thunks';

export interface SliderState {
  data: any;
  nftData: any;
  transactionData: any;
  loading: boolean;
  error: string | null;
}

const initialState: SliderState = {
  data: [],
  nftData: [],
  transactionData: [],
  loading: false,
  error: null,
};

const SliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    resetSlider: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSliderData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSliderData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch slider data';
    });
    builder.addCase(getSliderData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    // NFT Data
    builder.addCase(getLastNftData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLastNftData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch NFT data';
    });
    builder.addCase(getLastNftData.fulfilled, (state, action) => {
      state.loading = false;
      state.nftData = action.payload;
    });

    // Transaction Data
    builder.addCase(getLastTransactionData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLastTransactionData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch transaction data';
    });
    builder.addCase(getLastTransactionData.fulfilled, (state, action) => {
      state.loading = false;
      state.transactionData = action.payload;
    });
  },
});

export const { resetSlider } = SliderSlice.actions;
export default SliderSlice;
