import { Box, Typography } from '@mui/material';
import BackgroundImg from 'assets/images/Blur.png';
import { useTranslation } from 'react-i18next';

function ComingSoonPage() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'scroll',
        textAlign: 'center',
        padding: 1,
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography fontWeight={400} fontSize="36px" fontFamily="Audiowide" color="#fff">
          {t('coming_soon')}
        </Typography>
      </Box>
    </Box>
  );
}

export default ComingSoonPage;
