import { useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ReactComponent as TonIcon } from 'assets/icons/Vector1.svg';
import { ReactComponent as PolicyIcon } from 'assets/icons/note-favorite.svg';
import { ReactComponent as LanguageIcon } from 'assets/icons/language-square.svg';
import { ReactComponent as BrushIcon } from 'assets/icons/brush.svg';
// import { ReactComponent as SecurityIcon } from 'assets/icons/shield.svg';
import { ReactComponent as RulesIcon } from 'assets/icons/note.svg';
import { ReactComponent as ChannelIcon } from 'assets/icons/simple-icons_telegram.svg';
import SettingsCard from './SettingsCard';
import { generateUserInviteLink, routsPatterns } from 'resources/constants';
import Header from './Header';
import Languages from './Languages';
import OurChannel from './OurChannel';
import DeleteAccount from './DeleteAccount';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const settingsCardIcons = [
  {
    text: 'connect_wallet',
    icon: () => <TonIcon height={22} width={22} />,
    bgColor: '#FFFFFF',
    key: 'connectWallet',
  },
  // To open after 7 of December
  // {
  //   text: 'security',
  //   icon: () => <SecurityIcon height={22} width={22} />,
  //   bgColor: '#FFFFFFE5',
  //   path: routsPatterns.PASSWORD_AND_SECURITY,
  // },
  {
    text: 'select_language',
    icon: () => <LanguageIcon height={22} width={22} />,
    bgColor: '#FFFFFFCC',
    size: 6,
  },
  {
    text: 'channel',
    icon: () => <ChannelIcon height={22} width={22} />,
    bgColor: '#FFFFFFCC',
    size: 6,
  },
  {
    text: 'choose_theme',
    icon: () => <BrushIcon height={22} width={22} />,
    bgColor: '#FFFFFFE5',
    path: routsPatterns.THEME,
  },
  {
    text: 'rules',
    icon: () => <RulesIcon height={22} width={22} />,
    bgColor: '#FFFFFFCC',
    size: 6,
    path: 'rules',
  },
  {
    text: 'policy',
    icon: () => <PolicyIcon height={22} width={22} />,
    bgColor: '#FFFFFFCC',
    size: 6,
    path: 'policy',
  },
];

function SettingsPage() {
  const [activeCard, setActiveCard] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const userId = 6037620642;

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyLink = async () => {
    try {
      const inviteLink = generateUserInviteLink(userId);
      await navigator.clipboard.writeText(inviteLink);
      enqueueSnackbar('Link copied successfully!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to copy link. Please try again.', { variant: 'error' });
      console.error('Clipboard write error:', error);
    }
  };

  const handleDeleteAccountClick = () => {
    setActiveCard('deleteAccount');
    setIsOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: '#000',
        color: '#fff',
        minHeight: '97vh',
        p: 2,
      }}
    >
      <Box>
        <Header title={t('settings')} path={routsPatterns.HOME} />

        <Grid container spacing={1} mt={3}>
          {settingsCardIcons.map((item, index) => (
            <SettingsCard
              name={item?.key || null}
              key={item.text + index}
              icon={item.icon()}
              title={item.text}
              bgColor={item.bgColor}
              size={item.size}
              path={item.path}
              setActiveCard={setActiveCard}
              setIsOpen={setIsOpen}
            />
          ))}
        </Grid>
      </Box>

      <Box sx={{ textAlign: 'center', mt: 2, mb: 1 }}>
        <Button
          variant="contained"
          sx={(theme) => ({
            color: '#fff',
            background: theme.palette.gradient?.primaryVertical || '#1976d2',
            border: '2px solid #FFFFFF80',
            textTransform: 'none',
            mb: 1,
            width: '100%',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 700,
          })}
          onClick={handleCopyLink}
        >
          {t('copy_link')}{' '}
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#FFFFFF33',
            border: '2px solid #FFFFFF80',
            color: '#fff',
            textTransform: 'none',
            width: '100%',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 700,
          }}
          onClick={handleDeleteAccountClick}
        >
          {t('delete_account')}
        </Button>
      </Box>
      {activeCard === 'select_language' && <Languages isOpen={isOpen} setIsOpen={setIsOpen} />}
      {activeCard === 'channel' && <OurChannel isOpen={isOpen} setIsOpen={setIsOpen} />}
      {activeCard === 'deleteAccount' && <DeleteAccount isOpen={isOpen} setIsOpen={setIsOpen} />}
    </Box>
  );
}

export default SettingsPage;
