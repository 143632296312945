import { Stack, Typography } from '@mui/material';
import BackArrowButton from 'components/main/Template/BackArrowButton';
import { ReactNode } from 'react';
import Grid from '@mui/material/Grid2';
interface HeaderProps {
  title: string;
  path?: string;
  starIcon?: ReactNode;
  endIcon?: ReactNode;
}

function Header(props: HeaderProps) {
  const { title, path, starIcon, endIcon } = props;
  return (
    <Grid py={2} container alignItems="center" flexDirection="row" justifyContent="space-between">
      <Grid size={{ xs: 2 }}>{starIcon}</Grid>
      <Grid size={{ xs: 8 }}>
        <Typography
          variant="h6"
          fontWeight="bold"
          color="white"
          sx={{
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container justifyContent="end" size={{ xs: 2 }} sx={{ px: 1 }}>
        {endIcon}
      </Grid>
    </Grid>
  );
}

export default Header;
