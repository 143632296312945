import { useState, useCallback } from 'react';

export const useAsyncState = (initialValue:any) => {
  const [state, setState] = useState(initialValue);

  const setAsyncState = useCallback((value:any) => {
    return new Promise((resolve) => {
      setState((prevState:any) => {
        const nextState = typeof value === 'function' ? value(prevState) : value;
        resolve(nextState);
        return nextState;
      });
    });
  }, []);

  return [state, setAsyncState];
};