import { ChangeEvent, useCallback, useRef, useState } from 'react';
import {
  Theme,
  Select,
  Button,
  MenuItem,
  InputLabel,
  Typography,
  OutlinedInput,
  useTheme,
  Box,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';
import { fileToBase64 } from 'utils';
import { ReactComponent as AttachFileIcon } from 'assets/icons/attach-file.svg';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import  services  from 'services';
import { CreateTokenRequestParams } from 'resources/types';
import { showMessage } from 'store';

const blankValues: Record<string, unknown> = {
  name: '',
  shortName: '',
  salesFreeze: '',
  description: '',
  investment: '',
  imageBase64: '',
  // telegramUrl: ''
};

function TokenForm() {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const currentTheme = useTheme();
  const [open, setOpen] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [imageFile, setImageFile] =useState<any>(null)

  const onSubmit = useCallback(
    (values: Record<string, unknown>, helpers: FormikHelpers<Record<string, unknown>>) => {
      const timeStamp = Math.floor(Date.now() / 1000) + Number(values.salesFreeze);

      services.createToken({
        fullName: values.name,
        shortName: values.shortName,
        description: values.description,
        initialInvestment: values.investment,
        startAt: timeStamp,
        image: values.imageBase64,
        // telegramUrl: values.telegramUrl,
      } as CreateTokenRequestParams)
      .then(() => {
        showMessage({
          messages: ['Successfully created!'],
          options: { variant: 'success' },
        });
      })
      .catch((error) => {
        showMessage({
          messages: [`${error?.response?.data?.message}`],
          options: { variant: 'error' },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
        helpers.resetForm();
        setIsDisable(true)
        setImageFile(null)
      });
    },
    [],
  );

  const formik = useFormik({
    onSubmit,
    initialValues: blankValues,
    enableReinitialize: true,
  });

  const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    
    const file = event.target.files?.[0];
    if (file) {
      try {
        const base64 = await fileToBase64(file);
        formik.setFieldValue('imageBase64', base64);
        setImageFile(file);
        setIsDisable(false)
      } catch (error) {
        console.error('Error converting file to Base64:', error);
      }
    }
  };

  const handleSendAttachTelegramChanel = () => {
    setOpen(false)
  };

  const reportProblemModalContent = (
    <Box>
      <Typography
        sx={{ mb: 1, mt: 3, textAlign: 'start', fontSize: '12px', fontWeight: 400, fontFamily: 'Montserrat' }}
      >
        {t('text_appeal')}{' '}
      </Typography>
      {/* <TextField
        placeholder={t('appeal')}
        variant="outlined"
        name='telegramUrl'
        onChange={formik.handleChange}
        fullWidth
        multiline
        rows={2}
        sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', mb: 5 }}
      /> */}
      <Button
        variant="contained"
        fullWidth
        sx={(theme: Theme) => ({
          background: theme.palette.highlightRed.main,
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '12px',
          textTransform: 'none',
          border: '2px solid #FFFFFF80',
        })}
        onClick={handleSendAttachTelegramChanel}
      >
        {t('send')}
      </Button>
    </Box>
  );


  const handleClickTelegramChanel = () => {
    setOpen(true);
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
      <Typography variant="body2" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
        {t('create_token_description')}
      </Typography>
      <Link to={'#'} style={{ textDecoration: 'none' }}>
        <Typography
          sx={(theme: Theme) => ({ color: theme.palette.primary.main, fontSize: 12, fontWeight: 700 })}
          variant="subtitle2">
          {t('create_token_show_more')}
        </Typography>
      </Link>

      <InputLabel sx={{ py: 0.5 }} htmlFor="token-name">
        <Typography color="inherit" variant="inputLabel" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
          {t('create_token_input_name_label')}
        </Typography>
      </InputLabel>
      <OutlinedInput
        fullWidth
        id="token-name"
        name="name"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.name}
        disabled={formik.isSubmitting}
        placeholder={t('create_token_input_name_placeholder')}
      />

      <InputLabel sx={{ py: 0.5 }} htmlFor="token-short-name">
        <Typography color="inherit" variant="inputLabel" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
          {t('create_token_input_short_name_label')}
        </Typography>
      </InputLabel>
      <OutlinedInput
        fullWidth
        id="token-short-name"
        name="shortName"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.shortName}
        disabled={formik.isSubmitting}
        placeholder={t('create_token_input_short_name_placeholder')}
      />

      <InputLabel sx={{ py: 0.5 }} htmlFor="token-sales-freeze">
        <Typography color="inherit" variant="inputLabel" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
          {t('create_token_input_sales_freeze_label')}
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        defaultValue={1}
        id="token-sales-freeze"
        labelId="demo-simple-select-label"
        name="salesFreeze"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.salesFreeze}
        disabled={formik.isSubmitting}
      >
        <MenuItem value={3600}>{t('create_token_input_sales_freeze_option_1')}</MenuItem>
        <MenuItem value={10800}>{t('create_token_input_sales_freeze_option_2')}</MenuItem>
        <MenuItem value={28800}>{t('create_token_input_sales_freeze_option_3')}</MenuItem>
        <MenuItem value={86400}>{t('create_token_input_sales_freeze_option_4')}</MenuItem>
        <MenuItem value={259200}>{t('create_token_input_sales_freeze_option_5')}</MenuItem>
        <MenuItem value={1296000}>{t('create_token_input_sales_freeze_option_6')}</MenuItem>
        <MenuItem value={2592000}>{t('create_token_input_sales_freeze_option_7')}</MenuItem>

      </Select>

      <InputLabel sx={{ py: 0.5 }} htmlFor="token-description">
        <Typography color="inherit" variant="inputLabel" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
          {t('create_token_input_short_description_label')}
        </Typography>
      </InputLabel>
      <OutlinedInput
        fullWidth
        id="token-description"
        multiline
        rows={3}
        name="description"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.description}
        disabled={formik.isSubmitting}
        placeholder={t('create_token_input_short_description_placeholder')}
      />

      {/* <Button
        sx={{
          mt: 2,
          py: 1.2,
          width: '100%',
          borderWidth: 2,
          borderRadius: '12px',
          backgroundColor: 'rgba(128, 128, 128, 0.5)',
        }}
        color='white'
        variant='outlined'
        onClick={handleClickTelegramChanel}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '17px',
          }}>
          {t('create_token_input_attach_telegram_chanel')}
        </Typography>
      </Button> */}

      <InputLabel sx={{ py: 0.5 }} htmlFor="token-min-ton">
        <Typography
          color="inherit"
          variant="inputLabel"
          sx={(theme: Theme) => ({
            color: theme.palette.white.main
          })}
        >
          {t('create_token_input_investment_label')}
        </Typography>
      </InputLabel>
      <OutlinedInput
        fullWidth
        id="token-min-ton"
        name="investment"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.investment}
        disabled={formik.isSubmitting}
        placeholder={t('create_token_input_investment_placeholder')}
      />

      <InputLabel sx={{ py: 1 }} htmlFor="token-upload-image">
        <Typography color="inherit" variant="inputLabel" sx={(theme: Theme) => ({ color: theme.palette.white.main })}>
          {formik.values.imageUrl ? 'Image uploaded' : t('create_token_input_upload_image_label')}
        </Typography>
      </InputLabel>

      <Button
        role={undefined}
        component="label"
        variant="contained"
        tabIndex={-1}
        startIcon={<AttachFileIcon />}
        sx={(theme: Theme) => ({
          borderWidth: 2,
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          background: theme.palette.gradient.primaryVertical,
        })}>
        <input
          hidden
          ref={fileInputRef}
          type="file"
          onChange={onUpload}
          id="token-image-input"
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          value={imageFile ? undefined : ''}
        />
        {t('create_token_input_upload_image')}
      </Button>

      <Button
        type="submit"
        variant="contained"
        disabled={(formik.isSubmitting || !formik.dirty) || isDisable}
        sx={(theme: Theme) => ({
          mt: 4,
          width: '100%',
          borderWidth: 2,
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          background: theme.palette.gradient.primaryVertical,
        })}>
        {t('create_token_input_create')}
      </Button>
      <BottomDrawerModal
        isOpen={open}
        setIsOpen={setOpen}
        title={t('Attach a Telegram channel')}
        boxShadow={currentTheme.palette.primary.main}
      >
        {reportProblemModalContent}
      </BottomDrawerModal>
    </form>
  );
}

export default TokenForm;
