import { useTonConnectUI } from '@tonconnect/ui-react';
import { beginCell } from "@ton/ton";
import { environment } from 'resources/constants';

type Message = {
  address: string;
  amount: string;
  payload?: string;
};

function useTransaction(amount?: string, transactionIdForBackend?: string) {
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 600,
    messages: [
      {
        address: environment.WALLET_ADDRESS as string,
        amount: `${Number(amount) * Number(environment.SUPPLY_TOTAL)}`,
      },
    ] as Message[],
  };

  const body = beginCell()
    .storeUint(0, 32) // Metadata
    .storeStringTail(transactionIdForBackend as string)
    .endCell();

  transaction.messages[0].payload = body.toBoc().toString("base64");

  return { tonConnectUI, transaction };
}


export default useTransaction;
