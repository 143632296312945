import { ReactComponent as CardGame } from 'assets/icons/element-4.svg';
import Grid from '@mui/material/Grid2';
import { Theme, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { actionBarRoutes, routsPatterns } from 'resources/constants';
import {
  HomeButtonSVGActiveicon,
  TokenButtonSVGActiveIcon,
  NftButtonSVGActiveIcon,
  TasksButtonSVGActiveIcon,
} from 'assets/icons/DynamicIcons';

function ActionBar() {
  const currentTheme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: any) => location.pathname === path;

  const activeIconColor = currentTheme.palette.primary.main;
  const inactiveIconColor = currentTheme.palette.secondary.main;

  const currentRoutPattern: string =
    Object.values(routsPatterns).find((pattern) => matchPath(pattern, location.pathname)) || '';

  const showAppBar: boolean = actionBarRoutes.includes(currentRoutPattern);

  return showAppBar ? (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={(theme: Theme) => ({
        height: '80px',
        borderTopLeftRadius: '38px 38px',
        borderTopRightRadius: '38px 38px',
        boxShadow: `0px 1px 32px ${theme.palette.primary.main}`,
        zIndex: 10,
      })}
    >
      <IconButton
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(isActive('/') && {
            backgroundColor: currentTheme.palette.action.selected,
          }),
        }}
        onClick={() => navigate('/')}
      >
        {isActive('/') ? HomeButtonSVGActiveicon(activeIconColor) : HomeButtonSVGActiveicon('white')}
        <Typography color="white" variant="captureBold" fontFamily="Audiowide">
          {t('home')}
        </Typography>
      </IconButton>

      <IconButton
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(isActive(routsPatterns.TOKENS) && {
            backgroundColor: currentTheme.palette.action.selected,
          }),
        }}
        onClick={() => navigate(routsPatterns.TOKENS)}
      >
        {isActive(routsPatterns.TOKENS) ? TokenButtonSVGActiveIcon(activeIconColor) : TokenButtonSVGActiveIcon('white')}
        <Typography color="white" variant="captureBold" fontFamily="Audiowide">
          {t('tokens')}
        </Typography>
      </IconButton>

      <IconButton
        sx={(theme: Theme) => ({
          height: 60,
          borderRadius: '18px',
          borderColor: theme.palette.white.main,
          borderWidth: 2.5,
          borderStyle: 'solid',
          background: theme.palette.gradient.primary,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(isActive('/card-game') && {
            backgroundColor: currentTheme.palette.action.selected,
          }),
        })}
        onClick={() => navigate('/card-game')}
      >
        <CardGame fill={isActive('/card-game') ? activeIconColor : inactiveIconColor} />
        <Typography color="white" variant="captureBold" fontFamily="Audiowide" fontSize="10px">
          {t('card_game')}
        </Typography>
      </IconButton>

      <IconButton
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(isActive(routsPatterns.TOKENS) && {
            backgroundColor: currentTheme.palette.action.selected,
          }),
        }}
        onClick={() => navigate(routsPatterns.NFT_MARKET)}
      >
        {isActive('/nft/market') ? NftButtonSVGActiveIcon(activeIconColor) : NftButtonSVGActiveIcon('white')}
        <Typography color="white" variant="captureBold" fontFamily="Audiowide">
          {t('nft_market')}
        </Typography>
      </IconButton>

      <IconButton
        onClick={() => navigate('/tasks')}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...(isActive('/tasks') && {
            backgroundColor: currentTheme.palette.action.selected,
          }),
        }}
      >
        {isActive('/tasks') ? TasksButtonSVGActiveIcon(activeIconColor) : TasksButtonSVGActiveIcon('white')}
        <Typography color="white" variant="captureBold" fontFamily="Audiowide">
          {t('tasks')}
        </Typography>
      </IconButton>
    </Grid>
  ) : null;
}

export default ActionBar;
