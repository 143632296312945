import { useTranslation } from 'react-i18next';
import { Box, TextField, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';

interface AddGameModalProps {
  open: boolean;
  setIsModalOpenAddGameModal: (value: boolean) => void;
}

const AddGameModal = ({ open, setIsModalOpenAddGameModal }: AddGameModalProps) => {
  const { t } = useTranslation();
  if (!open) return null;

  const fontFamily = "'Montserrat', sans-serif";

  const gameModalContent = (
    <Box>
      <Typography variant="body2" sx={{ mb: 3, fontFamily }}>
        {t('instructions')}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={t('name')}
        sx={{
          mb: 2,
          bgcolor: '#2E2E30',
          color: '#070707',
          borderRadius: '8px',
          input: { color: '#070707', fontFamily, textAlign: 'left' },
          '& fieldset': { border: 'none' },
          '& ::placeholder': { color: '#070707' },
        }}
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder={t('telegramNick')}
        sx={{
          mb: 2,
          color: '#070707',
          bgcolor: '#2E2E30',
          borderRadius: '8px',
          input: { color: '#fff', fontFamily, textAlign: 'left' },
          '& fieldset': { border: 'none' },
          '& ::placeholder': { color: '#070707' },
        }}
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder={t('gameDescription')}
        multiline
        rows={3}
        sx={{
          mb: 2,
          color: '#070707',
          bgcolor: '#2E2E30',
          borderRadius: '8px',
          input: { color: '#fff', fontFamily, textAlign: 'left' },
          '& fieldset': { border: 'none' },
          '& ::placeholder': { color: '#070707' },
        }}
      />
      <Box sx={{ textAlign: 'left', mb: 2 }}>
        <Typography
          component="h1"
          sx={{
            fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('uploadFiles')}
        </Typography>
        <Button
          variant="contained"
          component="label"
          sx={{
            width: '50%',
            backgroundImage: 'linear-gradient(91.15deg, #86201C 0.94%, #3B0F0D 99.02%)',

            mt: 1,
            textTransform: 'none',

            borderRadius: '8px',
            fontFamily,
          }}
        >
          {t('selectFile')}
          <input type="file" hidden />
        </Button>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: '#86201C',
              '&.Mui-checked': {
                color: '#86201C',
              },
            }}
          />
        }
        label={
          <Typography variant="caption" sx={{ color: '#fff', fontFamily }}>
            {t('agreeRules')}{' '}
            <span style={{ color: '#FF4949', textDecoration: 'underline', cursor: 'pointer' }}>{t('rules')}</span>
          </Typography>
        }
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundImage: 'linear-gradient(91.15deg, #86201C 0.94%, #3B0F0D 99.02%)',
          textTransform: 'none',
          py: 1,
          borderRadius: '8px',
          fontFamily,
          border: '2px solid #FFFFFF80',
        }}
      >
        {t('submit')}
      </Button>
    </Box>
  );

  return (
    <BottomDrawerModal
      isOpen={open}
      setIsOpen={setIsModalOpenAddGameModal}
      title={t('add_own_game')}
      boxShadow="#9A0E13"
    >
      {gameModalContent}
    </BottomDrawerModal>
  );
};

export default AddGameModal;
