import { Box, Button, Theme, Typography } from '@mui/material';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { useTranslation } from 'react-i18next';
import theme from 'resources/theme';
import { useDispatch } from 'react-redux';
import {deleteUser} from '../../services/users'
import { useNavigate } from 'react-router-dom';

interface DeleteAccountProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function DeleteAccount({ isOpen, setIsOpen }: DeleteAccountProps) {
  const { t } = useTranslation();
  const navigation = useNavigate();


  const handleDeleteBtn = async(e:any) => {
    const response = await deleteUser();
    if(response.data.success) {
      navigation('/404')
    }
  }

  const deleteAccountContent = (
    <Box sx={{ textAlign: 'start', mt: 2 }}>
      <Typography fontSize="12px" fontWeight={500} color="#fff" sx={{ mb: 2 }} fontFamily="Montserrat">
        {t('delete_check')}
      </Typography>
      <Button
        variant="contained"
        sx={(theme: Theme) => ({
          background: theme.palette.highlightRed.main,
          color: '#fff',
          mb: 1,
          width: '100%',
          height: '39px',
          borderRadius: '12px',
          textTransform: 'none',
          fontSize:"14px",
          fontWeight: 700,
          border: '2px solid #FFFFFF80'
        })}
        onClick={(e) => handleDeleteBtn(e)}
      >
        {t('delete')}
      </Button>
      <Button
        variant="contained"
        sx={{
          bgcolor: '#FFFFFF33',
          color: '#fff',
          textTransform: 'none',
          width: '100%',
          height: '39px',
          borderRadius: '12px',
          fontSize:"14px",
          fontWeight: 700,
          border: '2px solid #FFFFFF80'
        }}
        onClick={() => setIsOpen(false)}
      >
        {t('cancel')}
      </Button>
    </Box>
  );
  return (
    <>
      <BottomDrawerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('delete_account')}
        boxShadow={theme.palette.highlightRed.main}
      >
        {deleteAccountContent}
      </BottomDrawerModal>
    </>
  );
}

export default DeleteAccount;
