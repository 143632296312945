import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Home } from 'pages/Home';
import { routsPatterns } from 'resources/constants';
import { Grid2 } from '@mui/material';
import AppTemplate from '../Template';
import DocumentationPage from 'pages/documentation/DocumentationPage';
import SettingsPage from 'pages/Settings/SettingsPage';
import PasswordAndSecurity from 'pages/Settings/PasswordAndSecurity';
import Rules from 'pages/Settings/Rules';
import PrivacyPolicy from 'pages/Settings/PrivacyPolicy';
import ApplicationColors from 'pages/Settings/ApplicationColors';
// import TasksPage from 'pages/Tasks/TasksPage';
import InvitationsPage from 'pages/Tasks/InvitationsPage';
import TokenPage from 'pages/Token';
import CreateToken from 'pages/CreateToken';
import ViewToken from 'pages/ViewToken';
import OwnerToken from 'pages/OwnerToken';
import StoryPage from 'pages/Story/StoryPage';
import Balance from 'pages/Story/Balance';
import TransactionsStory from 'pages/Story/TransactionsStory';
import NftMarketPage from 'pages/NftMarket/NftMarketPage';
import BuyNft from 'pages/NftMarket/BuyNft';
import GamePage from 'pages/Game/GamePage';
import SellNft from 'pages/NftMarket/SellNft';
import AboutToken from 'pages/AboutToken';
import Loading from '../Template/Loading';
import LoginPage from 'pages/Login';
import ComingSoonPage from 'pages/ComingSoonPage';

import HistoryAll from 'pages/Story/HistoryAll';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeTheme } from 'store/app';
import { ThemeNames } from 'resources/theme';


function Router() {
  const [isFirstVisit, setIsFirstVisit] = useState<boolean | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const userLoginInfo = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();


  useEffect(() => {
    userLoginInfo && dispatch(changeTheme(userLoginInfo.colorName as ThemeNames));
  }, [userLoginInfo])


  const routObjects: RouteObject[] = [
    {
      path: routsPatterns.LOGIN,
      element: <LoginPage />,
    },
    {
      path: routsPatterns.HOME,
      element: !isFirstVisit && <AppTemplate />,
      errorElement: <Grid2 />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: routsPatterns.TASKS,
          // element: <TasksPage />,
          element: <ComingSoonPage />,
        },
        {
          path: routsPatterns.TOKENS,
          children: [
            {
              index: true,
              element: <TokenPage />,
            },
            {
              path: routsPatterns.TOKENS_CREATE,
              element: <CreateToken />,
            },
            {
              path: routsPatterns.TOKENS_VIEW_TOKEN,
              element: <ViewToken />,
            },
            {
              path: routsPatterns.TOKEN_OWNER,
              element: <OwnerToken />,
            },
            {
              path: routsPatterns.TOKENS_ABOUT_TOKEN,
              element: <AboutToken />,
            },
          ],
        },
        {
          path: routsPatterns.HISTORYALL,
          element: <HistoryAll />,
        },
        {
          path: routsPatterns.STORY,
          element: <StoryPage />,
        },
        {
          path: routsPatterns.BALANCE,
          element: <Balance />,
        },
        {
          path: routsPatterns.NFT_MARKET,
          element: <NftMarketPage />,
        },
        {
          path: routsPatterns.GAME,
          element: <GamePage />,
        },
      ],
    },
    {
      path: routsPatterns.SETTINGS,
      element: <SettingsPage />,
    },
    {
      path: routsPatterns.PASSWORD_AND_SECURITY,
      element: <PasswordAndSecurity />,
    },
    {
      path: routsPatterns.RULES,
      element: <Rules />,
    },
    {
      path: routsPatterns.POLICY,
      element: <PrivacyPolicy />,
    },
    {
      path: routsPatterns.THEME,
      element: <ApplicationColors />,
    },
    {
      path: routsPatterns.INVITATION,
      element: <InvitationsPage />,
    },
    {
      path: routsPatterns.TRANSACTIONS_STORY,
      element: <TransactionsStory />,
    },
    {
      path: routsPatterns.BUY_NFT,
      element: <BuyNft />,
    },
    {
      path: routsPatterns.SELL_NFT,
      element: <SellNft />,
    },
  ];
  const router = createBrowserRouter(routObjects);
  return <RouterProvider router={router} />;
}

export default Router;
