import React from 'react';

const SVGLeft = () => {
  return (
    <svg viewBox="0 0 44 180" className="css-qs6ru0">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16V70C11.0457 70 20 78.9543 20 90C20 101.046 11.0457 110 0 110V164C0 172.837 7.16344 180 16 180H42V156H44V144H42V136H44V124H42V116H44V104H42V96H44V84H42V76H44V64H42V56H44V44H42V36H44V24H42V16H44V4H42V0H16Z"></path>
    </svg>
  );
};

export default SVGLeft;
