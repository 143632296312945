export const colorsRoulette = [
  { background: '#39FF88', border: '#39FF88' },
  { background: '#FF39DF', border: '#FF39DF' },
  { background: '#3FA1FC', border: '#3FA1FC' },
  { background: '#7D7D7D', border: '#7D7D7D' },
];

export const gamesMockData = [
  { id: 1, name: 'NFT Battle', image: "https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/games/1.webp" },
  { id: 2, name: 'NFT Battle', image: "https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/games/2.webp" },
  { id: 3, name: 'NFT Battle', image: "https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/games/3.webp" },
  { id: 4, name: 'NFT Battle', image: "https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/games/4.webp" },
];
