import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import gamescreenbg from '../../assets/images/gamescreenbg.png';
import { gamesMockData } from './mockupData';
import RouletteModal from './Roulette/Roulette';
import AddGameModal from './AddGamesModal';
import { ReactComponent as RouletteIcon } from '../../assets/icons/RouletteIcon.svg';
import { useTranslation } from 'react-i18next';

function GamePage() {
  const [showRoulette, setShowRoulette] = useState(false);
  const [isModalOpenAddGameModal, setIsModalOpenAddGameModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenAddGameModal = () => {
    setIsModalOpenAddGameModal(true);
  };
  const handleCloseAddGameModal = () => setIsModalOpenAddGameModal(false);
  const handleShowRoulette = () => {
    setShowRoulette(true);
  };

  const handleCloseRoulette = () => {
    setShowRoulette(false);
  };

  return (
    <Box
      sx={{
        bgcolor: '#000',
        color: '#fff',
        height: '100%',
        px: 2,
        backgroundImage: `url(${gamescreenbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'Audiowide, sans-serif',
      }}>
      <h1
        style={{
          fontSize: '34px',
          fontWeight: 400,
          textAlign: 'center',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
        }}>
        {t('game_center')}
      </h1>
      <Box sx={{ height: '84%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {gamesMockData.map((game) => (
            <Grid item xs={6} sm={6} md={3} key={game.id}>
              <Box
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.15)',
                  borderRadius: '20px',
                  overflow: 'hidden',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '8rem',
                    backgroundImage: `url(${game.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '18px',
                    marginTop: '2%',
                    border: '3px solid rgba(255, 255, 255, 0.4)',
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    position: 'absolute',
                    width: '116px',
                    height: '32px',
                    borderRadius: '10px',
                    top: '40%',
                    background: 'linear-gradient(180deg, #7E0004 0%, #410001 100%)',
                    fontSize: '14px',
                    fontWeight: 700,
                    border: '2px solid rgba(255, 255, 255, 0.5)',
                  }}>
                  {t('start')}
                </Button>
                <Box sx={{ color: '#fff', mt: 1, fontSize: '14px' }}>{game.name}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              width: '70%',
              height: 39,
              fontSize: '16px',
              fontWeight: 700,
              borderRadius: '8px',
              border: '2px solid rgba(255, 255, 255, 0.5)',
              textTransform: 'unset',
              backgroundImage: 'linear-gradient(180deg, #7E0004 0%, #410001 100%)',
              position: 'fixed',
              bottom: '13%',
            }}
            onClick={handleOpenAddGameModal}>
            {t('add_your_game')}
          </Button>
          <Button
            variant="contained"
            sx={{
              width: '1%',
              height: 39,
              borderRadius: '12px',
              border: '2px solid rgba(255, 255, 255, 0.5)',
              background: 'rgba(255, 255, 255, 0.2)',
              position: 'fixed',
              bottom: '13%',
              right: '3%',
            }}
            onClick={handleShowRoulette}>
            <RouletteIcon width={30} height={30} />
          </Button>
        </Box>
      </Box>
      <AddGameModal open={isModalOpenAddGameModal} setIsModalOpenAddGameModal={setIsModalOpenAddGameModal} />

      {showRoulette && <RouletteModal open={showRoulette} onClose={handleCloseRoulette} />}
    </Box>
  );
}

export default GamePage;
