import { createSlice } from '@reduxjs/toolkit';
import { getNFTList, getNFTOne, getNFTUser, createNft, sellNft, buyNft } from './thunks';

export interface NftState {
  nftAllData: any;
  nftOneData: any;
  nftUserData: any;
  loading: boolean;
}

const initialState: NftState = {
  nftAllData: [],
  nftOneData: null,
  nftUserData: null,
  loading: false,
};

/* eslint-disable no-param-reassign */

const NftSlice = createSlice({
  name: 'Nft',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getNFTList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNFTList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getNFTList.fulfilled, (state, action) => {
      state.loading = false;
      state.nftAllData = action.payload;
    });
    builder.addCase(getNFTOne.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNFTOne.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getNFTOne.fulfilled, (state, action) => {
      state.loading = false;
      state.nftOneData = action.payload;
    });
    builder.addCase(getNFTUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNFTUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getNFTUser.fulfilled, (state, action) => {
      state.loading = false;
      state.nftUserData = action.payload;
    });
    builder.addCase(createNft.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNft.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createNft.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sellNft.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sellNft.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(sellNft.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(buyNft.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(buyNft.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(buyNft.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

const { reset } = NftSlice.actions;

export { reset, getNFTList, getNFTUser, getNFTOne, createNft, sellNft, buyNft };

export default NftSlice;
