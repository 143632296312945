import React from 'react';
import { Box, Button, IconButton, Stack, SwipeableDrawer, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface BottomDrawerModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title?: string;
  buttonText?: string;
  boxShadow?: string;
  children?: React.ReactNode;
  topUpHandler?: any;
}

function BottomDrawerModal({
  isOpen,
  setIsOpen,
  title,
  buttonText,
  boxShadow,
  children,
  topUpHandler,
}: BottomDrawerModalProps) {
  const { t } = useTranslation();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="bottom"
      open={isOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={(theme: Theme) => ({
        height: 0,
        '& .MuiDrawer-paper': {
          borderRadius: '24px 24px 0 0',
          padding: '16px 8px',
          backgroundColor: '#000',
          color: '#fff',
          boxShadow: `0px 1px 32px ${boxShadow ? boxShadow : theme.palette.primary.main}`,
        },
      })}
    >
      <Box sx={{ textAlign: 'start', p: 2 }}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ fontWeight: 400, fontSize: '18px', fontFamily: 'Audiowide' }}>{t(`${title}`)}</Typography>
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{
              color: '#000',
              backgroundColor: '#fff',
              borderRadius: '30%',
              width: '24px',
              height: '24px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        {children}
        {buttonText && (
          <Button
            variant="contained"
            fullWidth
            onClick={topUpHandler}
            sx={(theme: Theme) => ({
              background: theme.palette.gradient.primary,
              color: '#fff',
              padding: '12px',
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: '12px',
              border: '2px solid #75FF47',
            })}
          >
            {t(buttonText)}
          </Button>
        )}
      </Box>
    </SwipeableDrawer>
  );
}

export default BottomDrawerModal;
