import { HistoryVariant } from 'pages/ViewToken/TransactionsHistory';
import { useCallback, useEffect, useState } from 'react';
import services from 'services';

function useTokenTransactions(tokenId: string, category: HistoryVariant) {
  const [transactionsData, setTransactionsData] = useState<any[]>([]);
  const [tokenInvestorsData, setTokenInvestments] = useState<any[]>([]);

  // Memoize the fetch function using useCallback
  const fetchData = useCallback(async () => {
    try {
      if (category === 'investments') {
        console.log('params', tokenId, category);
        const tokenInvestments = await services.getTokenInvestors(tokenId)

        setTokenInvestments(tokenInvestments?.data?.data);
      } else {
        const transactionsHistory = await services.getTransactionsByCategory( category, tokenId );
        setTransactionsData(transactionsHistory?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  }, [tokenId, category]);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchData, category]); // Dependency array only includes fetchData

  return {transactionsData, tokenInvestorsData};
}

export default useTokenTransactions;
