export default {
  getColors: '/colors',
  getUserColors: '/colors/user',
  chooseColor: '/colors/choose',
  buyColor: '/colors/buy',
  nftsList: '/nfts',
  oneNFt: '/nfts/:id',
  userNfts: '/nfts/user',
  createNft: '/nfts/create',
  sellNft: 'nfts/:id/sell',
  buyNft: 'nfts/:id/buy',
  getSliderData: '/slides/slides-data',
  getLastNftData: '/slides/last-nfts',
  getLastTransactionData: 'slides/last-transactions',
  tokensAll: 'tokens/all',
  tokensCreate: '/tokens/create',
  tokensByOwner: '/users/tokens/:ownerId',
  tokenDeatial: '/tokens/details/{{tokenId}}',
  tokenCharts: '/tokens/prices/{{tokenId}}',
  tokenInvestors: '/tokens/investors/top/:{{tokenId}}',
  historyAll: '/history/all',
  historyBalance: '/history/balance',
  historyTokens: '/history/tokens',
  tokenSell: '/tokens/sell/{{tokenId}}',
  tokenBuy: '/tokens/buy/{{tokenId}}',
  getUserData: '/users/me',
  createTransaction: '/users/create-transaction',
  withdraw: '/wallet/withdraw',
};
