export const HomeButtonSVGActiveicon = (color: string) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 9V7C2 4 4 2 7 2H8.5M22 9V7C22 4 20 2 17 2H15"
      stroke={color}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 15V17C2 20 4 22 7 22H17C20 22 22 20 22 17V15"
      stroke={color}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6898 8.47836L13.3298 6.12752C12.414 5.48586 11.0081 5.52086 10.1273 6.20336L7.20481 8.48419C6.62148 8.93919 6.16064 9.87252 6.16064 10.6075V14.6325C6.16064 16.12 7.36814 17.3334 8.85564 17.3334H15.144C16.6315 17.3334 17.839 16.1259 17.839 14.6384V10.6834C17.839 9.89586 17.3315 8.92752 16.6898 8.47836ZM12.4373 15C12.4373 15.2392 12.239 15.4375 11.9998 15.4375C11.7606 15.4375 11.5623 15.2392 11.5623 15V13.25C11.5623 13.0109 11.7606 12.8125 11.9998 12.8125C12.239 12.8125 12.4373 13.0109 12.4373 13.25V15Z"
      fill={color}
    />
  </svg>
);

export const TokenButtonSVGActiveIcon = (color: string) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M16.9898 10.93V13.07C16.9898 14.95 15.9498 16.6 14.4098 17.45C14.2398 17.54 14.0598 17.63 13.8698 17.71C13.5898 17.82 13.2998 17.91 12.9998 17.97C12.6798 18.03 12.3498 18.07 12.0098 18.07C11.9998 18.07 11.9998 18.07 11.9898 18.07C11.6498 18.07 11.3198 18.03 10.9998 17.97C10.7098 17.91 10.4198 17.82 10.1398 17.71L9.58976 17.45C9.45976 17.37 9.32976 17.3 9.20976 17.21C7.88976 16.32 7.00977 14.8 7.00977 13.07V10.93C7.00977 10.07 7.22976 9.25999 7.60976 8.54999C7.68976 8.40999 7.76976 8.27001 7.85976 8.14001C7.94976 8.00001 8.04976 7.88 8.14977 7.75C8.24977 7.63 8.35976 7.50999 8.46976 7.39999C8.69976 7.16999 8.94976 6.97001 9.20976 6.79001C9.34976 6.70001 9.47976 6.61 9.62976 6.53C9.74976 6.47 9.87977 6.4 10.0098 6.34C10.1798 6.27 10.3498 6.2 10.5198 6.16C10.6598 6.11 10.7998 6.07999 10.9398 6.04999C10.9698 6.02999 11.0098 6.02999 11.0498 6.01999C11.3598 5.95999 11.6698 5.92999 11.9898 5.92999H12.0098C12.3298 5.92999 12.6498 5.95999 12.9598 6.01999C12.9698 6.02999 12.9898 6.03 12.9998 6.03C13.1598 6.07 13.3198 6.11 13.4798 6.16C13.6398 6.2 13.7898 6.26001 13.9398 6.32001C14.0898 6.39001 14.2398 6.46 14.3798 6.53C14.7998 6.77 15.1898 7.05999 15.5298 7.39999C16.4298 8.29999 16.9898 9.54999 16.9898 10.93Z"
      fill={color}
    />
    <path
      d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V7C21.25 4.42 19.58 2.75 17 2.75H15C14.59 2.75 14.25 2.41 14.25 2C14.25 1.59 14.59 1.25 15 1.25H17C20.44 1.25 22.75 3.56 22.75 7V9C22.75 9.41 22.41 9.75 22 9.75Z"
      fill={color}
    />
    <path
      d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9V7C1.25 3.56 3.56 1.25 7 1.25H9C9.41 1.25 9.75 1.59 9.75 2C9.75 2.41 9.41 2.75 9 2.75H7C4.42 2.75 2.75 4.42 2.75 7V9C2.75 9.41 2.41 9.75 2 9.75Z"
      fill={color}
    />
    <path
      d="M17 22.75H15C14.59 22.75 14.25 22.41 14.25 22C14.25 21.59 14.59 21.25 15 21.25H17C19.58 21.25 21.25 19.58 21.25 17V15C21.25 14.59 21.59 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15V17C22.75 20.44 20.44 22.75 17 22.75Z"
      fill={color}
    />
    <path
      d="M9 22.75H7C3.56 22.75 1.25 20.44 1.25 17V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17C2.75 19.58 4.42 21.25 7 21.25H9C9.41 21.25 9.75 21.59 9.75 22C9.75 22.41 9.41 22.75 9 22.75Z"
      fill={color}
    />
  </svg>
);

export const NftButtonSVGActiveIcon = (color: string) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 9V7C2 4 4 2 7 2H17C20 2 22 4 22 7V9"
      stroke={color}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 15V17C2 20 4 22 7 22H17C20 22 22 20 22 17V15"
      stroke={color}
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g opacity="0.4">
      <path
        d="M6.7002 9.26001L12.0002 12.33L17.2602 9.28003"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 17.77V12.32" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.7602 6.29L7.56023 8.07003C6.84023 8.47003 6.24023 9.48002 6.24023 10.31V13.7C6.24023 14.53 6.83023 15.54 7.56023 15.94L10.7602 17.72C11.4402 18.1 12.5602 18.1 13.2502 17.72L16.4502 15.94C17.1702 15.54 17.7702 14.53 17.7702 13.7V10.31C17.7702 9.48002 17.1802 8.47003 16.4502 8.07003L13.2502 6.29C12.5602 5.9 11.4402 5.9 10.7602 6.29Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const TasksButtonSVGActiveIcon = (color: string) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M12.3701 8.88H17.6201"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M6.37988 8.88L7.12988 9.63L9.37988 7.38"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M12.3701 15.88H17.6201"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.4"
      d="M6.37988 15.88L7.12988 16.63L9.37988 14.38"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
