import routes from 'resources/routes';
import { instance } from './instance';

const getHistoryAll = (): Promise<any> => {
  return instance.get(routes.historyAll);
};

const getHistoryBalance = (): Promise<any> => {
  return instance.get(routes.historyBalance);
};

const getHistoryTokens = (): Promise<any> => {
  return instance.get(routes.historyTokens);
};

export default { getHistoryAll, getHistoryBalance, getHistoryTokens };
