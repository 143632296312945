import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'store';
import { colorsRoulette } from '../mockupData';

import Loading from 'components/main/Template/Loading';
import { rouletteStart, rouletteRotate, fetchNFTs } from 'services/game';
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as InfoGameIcon } from '../../../assets/icons/info-circlegame.svg';
import ArrowTop from './ArrowTop';
import SVGLeft from './SVGLeft';
import SVGRight from './SVGRight';
// import rollSound from '../../../assets/sounds/reel_big.mp3';
// import winSound from '../../../assets/sounds/win.mp3';
import { useAsyncState } from '../../../hooks/useAsyncState';
import './style.css';

const shuffleArray = (array: any) => {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const RouletteModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [boxes] = useState(Array.from({ length: 120 }, (_, i) => i - 1));
  const [randomValue, setRandomValue] = useState(0);
  const [test, setTest] = useState(false);
  const [shuffledImages, setShuffledImages] = useAsyncState([]);
  const [shuffledColors, setShuffledColors] = useState<any>([]);
  const [isRolling, setIsRolling] = useState(false);
  const [cooldownActive, setCooldownActive] = useState(false);
  const [cooldownTime, setCooldownTime] = useState<number>(0); // In seconds
  const halfIndex = Math.round(boxes.length / 2) + 1;
  const itemBoxRef = useRef<any>(null);
  const audioRef = useRef<any>(null);
  const audioWinRef = useRef<any>(null);
  const itemBoxParentRef = useRef<any>(null);
  const [transitionDuration, setTransitionDuration] = useState('none');
  const [freeSpinData, setFrispinData] = useState<any | null>(null);
  const { t } = useTranslation();
  const amazon_url = 'https://reboot-bucket.s3.eu-north-1.amazonaws.com';
  const [callRoll, setCallRoll] = useState<boolean>(false);
  const [targetCard, setTargetCard] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchRouletteData = async () => {
    const response = await rouletteStart();
    if (response.success) {
      setFrispinData(response);
      setShuffledImages(response.nfts);
      setLoading(false);
      if (!response.isFree) {
        startCooldown(response.remainingTime);
        setCooldownActive(true);
      }
    }
  };

  useEffect(() => {
    setShuffledColors(shuffleArray(colorsRoulette));
    fetchRouletteData();
  }, []);

  const prepareImages = async () => {
    try {
      const response = await rouletteRotate({ isFree: freeSpinData.isFree });
      if (response.success) {
        setCallRoll(true);
        setTargetCard(response.data);
      }
    } catch (error: any) {
      const message = error.response?.data?.message || 'Something went wrong.';
      showMessage({
        messages: [message],
        options: { variant: 'error' },
      });
    }
  };

  useEffect(() => {
    if (callRoll && !isRolling) {
      rollRandomLeft();
      setCallRoll(false);
    }
  }, [shuffledImages, callRoll, isRolling]);

  useEffect(() => {
    if (cooldownActive) {
      const interval = setInterval(() => {
        if (cooldownTime > 0) {
          setCooldownTime((prev) => prev - 1);
        } else {
          setCooldownActive(false);
          setCooldownTime(0);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [cooldownActive]);

  const startCooldown = (times: any) => {
    setCooldownTime(times.hours * 3600 + times.minutes * 60 + times.seconds);
  };

  const formatCooldownTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    if (test) {
      const timer = setTimeout(() => {
        if (itemBoxRef.current) {
          itemBoxRef.current.classList.add('highlight');
          if (audioWinRef.current) {
            audioWinRef.current.play();
          }
          fetchRouletteData();
        }
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [test]);

  const rollRandomLeft = async () => {
    if (isRolling) return;

    setIsRolling(true);
    setShuffledColors(shuffleArray(colorsRoulette));

    setRandomValue(0);
    setTransitionDuration('none');
    setTest(false);

    setTimeout(() => {
      const randomInRange = -49.8;
      setRandomValue(randomInRange);
      setTransitionDuration('all 7s cubic-bezier(0.0, 0.0, 0.0, 1.0)');
      setTest(true);

      setTimeout(() => {
        setRandomValue(Math.floor(randomInRange));
        setTransitionDuration('all 0.5s cubic-bezier(0.0, 0.0, 0.0, 1.0)');
        setIsRolling(false);
      }, 7500);
    }, 10);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {open && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="layout-wrapper">
              <div className="main-wrapper">
                <div style={{ position: 'absolute', top: '1%', textAlign: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      gap: '40px',
                    }}
                  >
                    {/* <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '35px',
                        backgroundColor: '#FFFFFF26',
                        borderRadius: '50%',
                        height: '35px',
                      }}
                      onClick={() => alert('Info about the roulette game!')}
                    >
                      <InfoGameIcon width={30} height={30} color="#fff" />
                    </button> */}
                    <h1>{t('roulette')}</h1>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '35px',
                        backgroundColor: '#FFFFFF26',
                        borderRadius: '50%',
                        height: '35px',
                      }}
                      onClick={onClose}
                    >
                      <CloseCircleIcon width={30} height={30} color="#fff" />
                    </button>
                  </div>
                </div>
                <div className="vertical-container">
                  <div className="container-grid">
                    <SVGLeft />
                    <div className="box-wrapper">
                      <div className="box-grid">
                        <div className="box-container">
                          <div
                            className="item-grid"
                            style={{
                              transform: `translateX(${randomValue}%)`,
                              transition: randomValue !== 0 ? transitionDuration : 'none',
                            }}
                            ref={itemBoxParentRef}
                          >
                            {boxes.map((box, index) => {
                              const color = shuffledColors[index % shuffledColors.length];
                              const choosenCard =
                                index === halfIndex && targetCard
                                  ? targetCard
                                  : shuffledImages[index % shuffledImages.length];
                              return (
                                <div
                                  className="item-box"
                                  key={index}
                                  data-index={index + 1}
                                  ref={index === halfIndex ? itemBoxRef : null}
                                >
                                  <div className="item-box-content">
                                    <div
                                      className="item"
                                      style={{
                                        borderColor: color.border,
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                      }}
                                    >
                                      <div
                                        className="rarity-shadow"
                                        style={{
                                          backgroundColor: color?.background,
                                        }}
                                      />
                                      <div className="item-cover">
                                        <img src={`${amazon_url}/${choosenCard?.image}`} alt={choosenCard.name} />
                                      </div>
                                      <div className="item-info">
                                        {choosenCard.name}
                                        <span>{choosenCard.price}</span>
                                      </div>
                                      <div className="rarity-border" style={{ backgroundColor: color.background }} />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <SVGRight />
                  </div>
                </div>
                <button
                  className="Gamebutton"
                  style={{ bottom: '8%' }}
                  onClick={() => prepareImages()}
                  disabled={isRolling}
                >
                  {freeSpinData?.isFree ? t('free_spin') : t('spin_for_tone')}
                </button>
                <button className="Gamebutton" style={{ bottom: '3%', background: 'rgba(255, 255, 255, 0.2)' }}>
                  {`${t('next_free_spin')} ${formatCooldownTime(cooldownTime)}`}
                </button>
                {/* <audio ref={audioRef} src={rollSound} />
                <audio ref={audioWinRef} src={winSound} /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RouletteModal;
