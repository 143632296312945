import { Box, Typography } from '@mui/material';
import Header from './Header';
import Grid from '@mui/material/Grid2';
import { routsPatterns } from 'resources/constants';
import { ReactComponent as Logo } from 'assets/icons/IMG_0342 1.svg';
import { useTranslation } from 'react-i18next';
import RuleSection from './RuleSection';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#000',
        color: '#fff',
        height: '100vh',
        overflow: 'scroll',
        p: 2,
      }}
    >
      <Header title={t('privacy_policy')} path={routsPatterns.SETTINGS} />
      <Grid mt={2} display="flex" alignItems="center" justifyContent="start">
        <Logo />
        <Typography
          fontWeight={700}
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            color: '#FFFFFF',
          }}
        >
          {t('pump_battle')}
        </Typography>
        <Typography
          height={22}
          fontWeight={500}
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '8px',
            color: '#FFFFFF',
            margin: '4px 0 0 5px',
          }}
        >
          {t('beta')}
        </Typography>
      </Grid>
      <RuleSection title={t('general_provisions.title')} description={t('general_provisions.content')} />
      <RuleSection title={t('data_collection.title')} description={t('data_collection.content')} />
      <RuleSection title={t('usage_purposes.title')} description={t('usage_purposes.content')} />
      <RuleSection title={t('data_sharing.title')} description={t('data_sharing.content')} />
      <RuleSection title={t('data_security.title')} description={t('data_security.content')} />

      <RuleSection title={t('user_rights.title')} description={t('user_rights.rights')} />
      <RuleSection title={t('cookies_usage.title')} description={t('cookies_usage.content')} />
      <RuleSection title={t('policy_changes.title')} description={t('policy_changes.content')} />
      <RuleSection title={t('contact_information.title')} description={t('contact_information.content')} lastSection />
    </Box>
  );
}

export default PrivacyPolicy;
