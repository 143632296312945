import { createAsyncThunk } from '@reduxjs/toolkit';
import routes from 'resources/routes';
import services from 'services';

const getSliderData = createAsyncThunk(
  routes.getSliderData,
  async (): Promise<any> => {
    const response = await services.getSliderFullData();

    return response.data;
  },
);

const getLastNftData = createAsyncThunk(
  routes.getLastNftData,
  async (): Promise<any> => {
    const response = await services.getLastNftData();
    return response.data;
  },
);

const getLastTransactionData = createAsyncThunk(
  routes.getLastTransactionData,
  async (): Promise<any> => {
    const response = await services.getLastTransactionData();
    return response.data;
  },
);

export { getSliderData, getLastNftData, getLastTransactionData };
