import { configureStore } from '@reduxjs/toolkit';
import homeSlice from './home';
import tokenSlice from './token';

import appSlice, { AppState, showMessage as showMessageAction } from './app';
import languageSlice from './languageSlice';
import colorsSlice from './colors';
import NftSlice from './nft';
import SliderSlice from './slider';
import loginSlice from './login';
import HistorySlice from './history';
const NOTIFIER_TIMEOUT: number = 10;

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    home: homeSlice.reducer,
    colors: colorsSlice.reducer,
    language: languageSlice.reducer,
    nft: NftSlice.reducer,
    slider: SliderSlice.reducer,
    tokenInfo: tokenSlice.reducer,
    login: loginSlice.reducer,
    history: HistorySlice.reducer,
  },
});

export const showMessage = (notifier: Omit<AppState['notifier'], 'message'> & { messages: Array<string> }) => {
  notifier.messages.forEach((message) => {
    setTimeout(() => {
      store.dispatch(showMessageAction({ ...notifier, message }));
    }, NOTIFIER_TIMEOUT);
  });
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
