import Grid from '@mui/material/Grid2';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Button, Typography, useTheme } from '@mui/material';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-1.svg';
import { ReactComponent as ProfileUsersIcon } from 'assets/icons/profile-2user.svg';
import { ReactComponent as SliderVerticalIcon } from 'assets/icons/slider-vertical.svg';
import ListItems from './ListItems';
import { useParams } from 'react-router-dom';
import useTokenTransactionsInvest from 'hooks/useTokenTransactionsInvest';

export type HistoryVariant = 'all' | 'buy' | 'sell' | 'investments';

function TransactionsHistory() {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const { tokenId } = useParams();
  const [variant, setVariant] = useState<HistoryVariant>('all');
  const changeVariant = (event: MouseEvent<HTMLButtonElement>) => {
    const name = event.currentTarget.name;
    setVariant(name as HistoryVariant);
  };

  const {transactionsData, tokenInvestorsData} =  useTokenTransactionsInvest(tokenId ?? '', variant);

  return (
    <Grid px={2} mt={3} spacing={1} container width="100%">
      <Grid
        container
        spacing={0.4}
        width="100%"
        sx={(theme: Theme) => ({
          pb: 1,
          top: -1,
          zIndex: 999,
          position: 'sticky',
          backgroundColor: theme.palette.secondary.dark,
        })}>
        <Grid size={12} mb={2}>
          <Typography
            sx={(theme: Theme) => ({
              fontSize: 14,
              fontWeight: 700,
              lineHeight: '17px',
              color: theme.palette.white.main,
            })}>
            {t('view_token_transactions_history')}
          </Typography>
        </Grid>

        <Grid size={3}>
          <Button
            name="all"
            type="submit"
            variant="contained"
            onClick={changeVariant}
            color={variant === 'all' ? 'primary' : 'secondary'}
            sx={(theme: Theme) => ({
              fontSize: 10,
              width: '100%',
              fontWeight: 700,
              borderRadius: '10px',
              '& .MuiButton-icon': { mr: 0.5 },
              ...(variant === 'all' && {
                color: theme.palette.secondary.dark,
                background: theme.palette.gradient.primaryVertical,
              }),
            })}
            startIcon={
              <SliderVerticalIcon
                stroke={variant === 'all' ? currentTheme.palette.secondary.dark : currentTheme.palette.primary.main}
              />
            }>
            {t('view_token_transactions_all')}
          </Button>
        </Grid>

        <Grid size={3}>
          <Button
            name="buy"
            type="submit"
            variant="contained"
            color={variant === 'buy' ? 'primary' : 'secondary'}
            onClick={changeVariant}
            sx={(theme: Theme) => ({
              fontSize: 10,
              width: '100%',
              fontWeight: 700,
              borderRadius: '10px',
              '& .MuiButton-icon': { mr: 0.5 },
              ...(variant === 'buy' && {
                color: theme.palette.secondary.dark,
                background: theme.palette.gradient.primaryVertical,
              }),
            })}
            startIcon={
              <ArrowUpIcon
                fill="none"
                stroke={
                  variant === 'buy' ? currentTheme.palette.secondary.dark : currentTheme.palette.primary.main
                }
              />
            }>
            {t('view_token_transactions_purchase')}
          </Button>
        </Grid>

        <Grid size={3}>
          <Button
            name="sell"
            type="submit"
            variant="contained"
            color={variant === 'sell' ? 'primary' : 'secondary'}
            onClick={changeVariant}
            sx={(theme: Theme) => ({
              fontSize: 10,
              width: '100%',
              fontWeight: 700,
              borderRadius: '10px',
              '& .MuiButton-icon': { mr: 0.5 },
              ...(variant === 'sell' && {
                color: theme.palette.secondary.dark,
                background: theme.palette.gradient.primaryVertical,
              }),
            })}
            startIcon={
              <ArrowDownIcon
                fill="none"
                stroke={variant === 'sell' ? currentTheme.palette.secondary.dark : currentTheme.palette.primary.main}
              />
            }>
            {t('view_token_transactions_sale')}
          </Button>
        </Grid>

        <Grid size={3}>
          <Button
            name="investments"
            type="submit"
            variant="contained"
            color={variant === 'investments' ? 'primary' : 'secondary'}
            onClick={changeVariant}
            sx={(theme: Theme) => ({
              fontSize: 10,
              width: '100%',
              fontWeight: 700,
              borderRadius: '10px',
              '& .MuiButton-icon': { mr: 0.5 },
              ...(variant === 'investments' && {
                color: theme.palette.secondary.dark,
                background: theme.palette.gradient.primaryVertical,
              }),
            })}
            startIcon={
              <ProfileUsersIcon
                fill="none"
                stroke={
                  variant === 'investments' ? currentTheme.palette.secondary.dark : currentTheme.palette.primary.main
                }
              />
            }>
            {t('view_token_transactions_investments')}
          </Button>
        </Grid>
      </Grid>

      <Grid sx={{ width: 'inherit' }} pb={5}>
        <ListItems
          transactionState={variant === 'investments' ? tokenInvestorsData : transactionsData}
          variant={variant}
          tokenId={tokenId}
        />
      </Grid>
    </Grid>
  );
}

export default TransactionsHistory;
