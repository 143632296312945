import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';
import NftCard from './NftCard';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import FilterButtons from 'components/FilterButtons';
import { FilterButton } from 'resources/types/filterButtons';

import { ReactComponent as HighPriceIcon } from 'assets/icons/arrow-square-up.svg';
import { ReactComponent as LowPriceIcon } from 'assets/icons/arrow-square-down.svg';
import { ReactComponent as  MyNftIcon} from 'assets/icons/slider-horizontal.svg';
import { ReactComponent as  ByUsersIcon} from 'assets/icons/profile.svg';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add-square.svg';
import { getNFTList, getNFTUser } from 'store/nft';
import { useAppDispatch, useAppSelector } from 'hooks';
import CreateModal from './CreateModal';



function NftMarketPage() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const NFTListState = useAppSelector((state) => state.nft);
  const userLoginInfo = useAppSelector((state) => state.login);

  const [modalSource, setModalSource] = useState<'add' | 'filter' | null>(null);
  const title = modalSource === 'add' ? 'nft_creation' : 'filter';
  const [selectedButton, setSelectedButton] = useState('My');
  const [searchParams, setSearchParams] = useSearchParams({ owner: '1' });

  const transactionButtons: FilterButton[] = [
    {
      text: 'High Price',
      type: 'sort',
      field: 'price',
      order: 'desc',
      icon: (color: string) => <HighPriceIcon stroke={color} height={18} width={18} />,
    },
    {
      text: 'Low Price',
      type: 'sort',
      field: 'price',
      order: 'asc',
      icon: (color: string) => <LowPriceIcon stroke={color} height={18} width={18} />,
    },
    {
      text: 'My',
      type: 'filter',
      field: 'userId_eq',
      value: userLoginInfo.id,
      icon: (color: string) => <MyNftIcon stroke={color} height={18} width={18} />,
    },
    {
      text: 'By users',
      type: 'filter',
      field: 'userId_ne',
      value: userLoginInfo.id,
      icon: (color: string) => <ByUsersIcon stroke={color} height={18} width={18} />,
    },
  ];

  useEffect(() => {
    dispatch(getNFTList(searchParams));
    dispatch(getNFTUser())
  }, [dispatch, searchParams]);

  const handleButtonsActive = (filterField: string) => {
    return searchParams.has(filterField);
  };

  // const handleCreateSubmit = () => {
    // const resultAction = await dispatch(createNft({  }));
    // if (createNft.fulfilled.match(resultAction)) {
    // setIsStateUpdated(!isStateUpdated);
    // }
  // };

  const filterModalContent = (
    <>
      <Typography fontSize="16px" fontWeight={600} color="#fff" fontFamily="Montserrat" mt={1}>
        {t('coming_soon')}
      </Typography>
    </>
  );

  return (
    <Box
      sx={{
        bgcolor: '#000',
        color: '#fff',
        p: 1,
        overflow: 'none',
      }}
    >
      <Stack flexDirection="row" alignItems="center">
        <Box bgcolor="#FFFFFF26" borderRadius="50%">
          <IconButton
            onClick={() => {
              setModalSource('add');
              setIsOpen(true);
            }}
          >
            <AddIcon width={22} height={22} />
          </IconButton>
        </Box>
        <Header title={t('nft_market')} path={routsPatterns.HOME} withoutArrowBackBtn />
        <Box bgcolor="#FFFFFF26" borderRadius="50%">
          <IconButton
            onClick={() => {
              setModalSource('filter');
              setIsOpen(true);
            }}
          >
            <FilterIcon width={22} height={22} />
          </IconButton>
        </Box>
        <BottomDrawerModal isOpen={isOpen} setIsOpen={setIsOpen} title={t(title)}>
          {modalSource === 'add' ? <CreateModal searchParams={searchParams} setIsOpen={setIsOpen} /> : filterModalContent}
        </BottomDrawerModal>
      </Stack>
      <FilterButtons
        buttons={transactionButtons}
        onApplyFilters={(queryParams) => dispatch(getNFTList(queryParams))}
        clearOnSelect
        setSelectedButton={setSelectedButton}
      />
      <Box>
        {NFTListState?.nftAllData?.data?.map((item: any) => (
          <NftCard item={item} selectedButton={selectedButton} userNftData={NFTListState.nftUserData?.data} />
        ))}
      </Box>
    </Box>
  );
}

export default NftMarketPage;
