import React, { useState } from 'react';
import { Box, Button, TextField, InputAdornment, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AmountInputProps {
  actionType: string;
  amount?: string;
  setAmount: (value: string) => void;
}

function AmountInput({ actionType, amount, setAmount }: AmountInputProps) {

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if(inputValue === '' || inputValue) {
      setAmount(event.target.value);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TextField
        value={amount}
        onChange={handleChange}
        placeholder={t('amountInTon')}
        fullWidth
        variant="outlined"
        slotProps={{
          input: {
            sx: {
              backgroundColor: '#F2F2F2',
              borderRadius: '12px',
              padding: '0px',
            },
            endAdornment: (
              <InputAdornment position="end">
                {actionType === 'takeOff' && (
                  <Button
                    variant="contained"
                    sx={(theme: Theme) => ({
                      background: theme.palette.gradient.primary,
                      textTransform: 'none',
                      color: '#fff',
                      width: '54px',
                      height: '34px',
                      marginRight: '4px',
                      borderRadius: '12px',
                      border: '2px solid #75FF47',
                    })}
                  >
                    {t('all')}
                  </Button>
                )}
              </InputAdornment>
            ),
          },
        }}
      />
    </Box>
  );
}

export default AmountInput;
