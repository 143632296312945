import { useTonConnectUI } from '@tonconnect/ui-react';
import { beginCell } from "@ton/ton";
import { environment } from 'resources/constants';
import { useCallback, useEffect, useState } from 'react';
import services from 'services';
import { CandlestickData, UTCTimestamp } from 'lightweight-charts';


const chart: CandlestickData[] = [
  { open: 10.00, high: 10.76, low: 9.06, close: 10.57, time: 1704099600 as UTCTimestamp },
  { open: 10.57, high: 11.44, low: 9.84, close: 10.02, time: 1704100500 as UTCTimestamp},
  { open: 10.02, high: 10.59, low: 9.37, close: 10.25, time: 1704101400 as UTCTimestamp},
  { open: 10.25, high: 11.21, low: 9.57, close: 10.64, time: 1704102300 as UTCTimestamp},
  { open: 10.64, high: 11.14, low: 9.82, close: 11.00, time: 1704103200 as UTCTimestamp},
  { open: 11.00, high: 11.82, low: 10.50, close: 10.89, time: 1704104100 as UTCTimestamp},
  { open: 10.89, high: 11.52, low: 9.99, close: 11.45, time: 1704105000 as UTCTimestamp},
  { open: 11.45, high: 12.06, low: 10.90, close: 11.26, time: 1704105900 as UTCTimestamp},
  { open: 11.26, high: 11.76, low: 10.26, close: 10.61, time: 1704106800 as UTCTimestamp},
  { open: 10.61, high: 11.45, low: 9.86, close: 10.46, time: 1704107700 as UTCTimestamp},
];

function useChart(tokenId: string) {
  const [chartData, setChartData] = useState<any[]>([]);

  // Memoize the fetch function using useCallback
  const fetchData = useCallback(async () => {
    try {
      const data = await services.getTokenChartsData(tokenId);
      setChartData(data?.data?.data);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  }, [tokenId]);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchData]); // Dependency array only includes fetchData

  return [chartData, setChartData] as const;
}

export default useChart;
