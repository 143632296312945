import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import theme from 'resources/theme';
import { useNavigate } from 'react-router-dom';
import slideBg from '../../../../assets/images/slide-bg.svg';
interface SlideData {
  id: number | string;
  imageUrl?: string;
  image?: any;
  token?: {
    shortName?: string;
    imageUrl?: string;
  };
  amountTon?: number;
  amountToken?: number;
  usdtAmount?: number;
}

interface ImageCarouselProps {
  items: SlideData[];
  slideSx: Record<string, any>;
  isTransaction?: boolean;
}

function Slide({
  item,
  slideSx,
  isTransaction,
}: {
  item: SlideData;
  slideSx: Record<string, any>;
  isTransaction?: boolean;
}) {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  console.log(56, isTransaction && item);

  if (isTransaction) {
    const { amountTon, amountToken, usdtAmount, token } = item;
    const imageUrl = `https://reboot-bucket.s3.eu-north-1.amazonaws.com/${token?.imageUrl}`;
    const activeIconColor = currentTheme.palette.primary.main;
    return (
      <Box
        sx={{
          padding: '4px',
          borderWidth: '2px',
          borderColor: (item as any)?.type === 'buy' ? '#33CC66' : '#F14A38',
          borderStyle: 'solid',
          backgroundImage: `url(${slideBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: slideSx.height,
          width: slideSx.width,
          overflow: 'hidden',
          backgroundColor: '#FFFFFF26',
          zIndex: -999,
          position: 'relative',
          boxShadow: slideSx.boxShadow && `0px 0px 12px 1px rgba(0, 0, 0, 0.2)`,
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '25px',
        }}>
        <p
          style={{
            fontSize: '9px',
            color: '#fff',
            fontFamily: 'Audiowide',
            textAlign: 'center',
            width: '100%',
            marginTop: '10px',
          }}>
          Active Tokens
        </p>

        <Box
          sx={{
            marginTop: '-10px',
            marginLeft: '3px',
          }}>
          <p
            style={{
              fontFamily: 'Audiowide',
              fontWeight: 600,
              color: (item as any)?.type == 'buy' ? '#33CC66' : '#F14A38',

              display: 'flex',
              flexDirection: 'column',
            }}>
            <span style={{ fontWeight: '400', fontSize: '8px' }}>Bought</span>
            <span style={{ fontWeight: '400', fontSize: '8px' }}>
              {item?.amountToken} {item?.token?.shortName}
            </span>
            <span style={{ fontWeight: '400', fontSize: '8px', color: '#FFFFFF' }}>{item?.amountTon} TON</span>
          </p>
        </Box>
      </Box>
    );
  }

  const imageUrl = item?.imageUrl
    ? `https://reboot-bucket.s3.eu-north-1.amazonaws.com/${item?.imageUrl}`
    : `https://reboot-bucket.s3.eu-north-1.amazonaws.com/${item?.image}`;

  return (
    <Box
      sx={{
        height: slideSx.height,
        width: slideSx.width,
        borderRadius: '14px',
        padding: '10px 5px',
        overflow: 'hidden',
        margin: '5px auto',
      }}>
      <div
        style={{
          height: slideSx.height,
          width: slideSx.width,
          borderRadius: '14px',
          overflow: 'hidden',
          boxShadow: slideSx.boxShadow && `0px 0px 12px 1px #75FF47;`,
          margin: '0px auto',
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
        }}
        onClick={() => navigate('/card-game')}
      />
    </Box>
  );
}

function ImageCarousel({ items, slideSx, isTransaction }: ImageCarouselProps) {
  const isMdOrUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Carousel
      sx={{ width: '100%', height: 'auto' }}
      indicatorContainerProps={{
        style: {
          marginTop: '0px',
          position: 'relative',
          right: isMdOrUp ? '15%' : 'none',
          display: items?.length > 6 ? 'none' : 'block',
        },
      }}
      swipe
      navButtonsAlwaysInvisible>
      {items?.map((item) => (
        <Slide key={item.id} item={item} slideSx={slideSx} isTransaction={isTransaction} />
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
