import { useState } from 'react';
import '@fontsource/audiowide';
import { Box, Typography, IconButton, Stack, Theme } from '@mui/material';
import { ReactComponent as RightArrowIcon } from 'assets/icons/send.svg';
import { useTranslation } from 'react-i18next';
import { docSlides } from 'resources/constants';
import { useNavigate } from 'react-router-dom';
import BackgroundImg from 'assets/images/1.ScreenSaver.png';

interface DocumentationPageProps {
  setIsFirstVisit: (value: boolean) => void;
}

const DocumentationPage = ({ setIsFirstVisit }: DocumentationPageProps) => {
  const navigation = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    if (currentIndex === docSlides.length - 1) {
      setIsFirstVisit(false);
      navigation('/');
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'scroll',
        textAlign: 'center',
        padding: 1,
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1, maxWidth: '330px' }}>
          <img
            src={docSlides[currentIndex].image}
            alt={docSlides[currentIndex].title}
            style={{ width: '100%', borderRadius: 8 }}
          />
          <Typography
            fontWeight={400}
            fontSize="26px"
            fontFamily="Audiowide"
            lineHeight="35px"
            textAlign="start"
            mt={1}
          >
            {t(docSlides[currentIndex].title)}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="14px"
            fontFamily="Montserrat"
            lineHeight="17px"
            textAlign="start"
            mt={1}
          >
            {t(docSlides[currentIndex].description)}
          </Typography>
        </Box>
      </Box>

      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" padding="0px 25px" mb={5}>
        <Box sx={{ marginTop: 2 }}>
          {docSlides.map((_, index) => (
            <span
              key={index}
              style={{
                height: '7px',
                width: '7px',
                marginRight: '5px',
                background: currentIndex === index ? '#000' : '#ccc',
                borderRadius: '50%',
                display: 'inline-block',
              }}
            />
          ))}
        </Box>
        <IconButton
          sx={(theme: Theme) => ({
            width: '65px',
            height: '50px',
            borderRadius: '60px',
            border: '3px solid #75FF47',
            background: theme.palette.gradient.primary,
          })}
          onClick={handleNext}
        >
          <RightArrowIcon width={24} height={24} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default DocumentationPage;
