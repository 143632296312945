import { Box, Button, IconButton, TextField, Theme, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Header from 'components/main/Header';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import BackArrowButton from 'components/main/Template/BackArrowButton';
import TokenInfo from './TokenInfo';
import SellToken from './SellToken';
import TransactionsHistory from './TransactionsHistory';
import { useEffect, useState } from 'react';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import ChartV1 from './ChartV1';
import { useParams } from 'react-router-dom';
import { getTokenDetails } from 'store/token';
import { useAppDispatch, useAppSelector } from 'hooks';
import Chart from './Chart';
import CandlestickChart from './Candlestick';

function ViewToken() {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const reportProblemModalContent = (
    <Box>
      <Typography
        sx={{ mb: 1, mt: 3, textAlign: 'start', fontSize: '12px', fontWeight: 400, fontFamily: 'Montserrat' }}>
        {t('text_appeal')}{' '}
      </Typography>
      <TextField
        placeholder={t('appeal')}
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', mb: 5 }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={(theme: Theme) => ({
          background: theme.palette.highlightRed.main,
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '12px',
          textTransform: 'none',
          border: '2px solid #FFFFFF80',
        })}>
        {t('send')}
      </Button>
    </Box>
  );

  return (
    <Grid>
      <Header
        title={t('view_token_title')}
        starIcon={<BackArrowButton path=".." />}
        endIcon={
          <IconButton
            sx={{
              width: '36px',
              height: '36px',
              display: 'flex',
              borderRadius: '50%',
              alignItems: 'center',
              background: '#FFFFFF26',
              justifyContent: 'center',
            }}
            onClick={() => setIsOpen(true)}>
            <DangerIcon width={30} height={30} />
          </IconButton>
        }
      />
      <Grid
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 36px)',
        }}>
        <TokenInfo />
        <CandlestickChart />
        <SellToken />
        <TransactionsHistory />
      </Grid>

      <BottomDrawerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('report_problem')}
        boxShadow={currentTheme.palette.highlightRed.main}>
        {reportProblemModalContent}
      </BottomDrawerModal>
    </Grid>
  );
}

export default ViewToken;
