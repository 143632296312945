import { Button, Stack, useTheme } from '@mui/material';
import { ReactComponent as StatusIconActive } from 'assets/icons/status.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/status-active.svg';
import { ReactComponent as AllIcon } from 'assets/icons/slider-vertical2.svg';
import { ReactComponent as AllIconActive } from 'assets/icons/slider-vertical-black.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/empty-wallet.svg';
import { ReactComponent as WalletIconActive } from 'assets/icons/empty-wallet-active.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { routsPatterns } from 'resources/constants';

function HeaderButtons() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTheme = useTheme();
  const activeIconColor = currentTheme.palette.primary.main;
  const inactiveIconColor = currentTheme.palette.secondary.main;
  const getActiveButton = () => {
    if (location.pathname === routsPatterns.STORY) return 'tokens';
    if (location.pathname === routsPatterns.BALANCE) return 'balance';
    return 'all';
  };

  const activeButton = getActiveButton();

  const handleButtonClick = (route: string) => {
    if (location.pathname !== route) {
      navigate(route);
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt: 2 }}>
      <Button
        variant="contained"
        startIcon={activeButton === 'all' ? <AllIconActive /> : <AllIcon />}
        sx={{
          backgroundColor: activeButton === 'all' ? activeIconColor : inactiveIconColor,
          color: activeButton === 'all' ? '#000' : '#fff',
          borderRadius: '10px',
          fontSize: '10px',
          fontWeight: 700,
          width: '130px',
          height: '29px',
        }}
        onClick={() => handleButtonClick(routsPatterns.HISTORYALL)}>
        {t('all')}
      </Button>
      <Button
        variant="contained"
        startIcon={activeButton === 'tokens' ? <StatusIconActive /> : <StatusIcon />}
        sx={{
          backgroundColor: activeButton === 'tokens' ? activeIconColor : inactiveIconColor,
          color: activeButton === 'tokens' ? '#000' : '#fff',
          borderRadius: '10px',
          fontSize: '10px',
          fontWeight: 700,
          width: '130px',
          height: '29px',
        }}
        onClick={() => handleButtonClick(routsPatterns.STORY)}>
        {t('tokens')}
      </Button>
      <Button
        variant="contained"
        startIcon={activeButton === 'balance' ? <WalletIconActive /> : <WalletIcon />}
        sx={{
          backgroundColor: activeButton === 'balance' ? activeIconColor : inactiveIconColor,
          color: activeButton === 'balance' ? '#000' : '#fff',
          borderRadius: '10px',
          fontSize: '10px',
          fontWeight: 700,
          width: '130px',
          height: '29px',
        }}
        onClick={() => handleButtonClick(routsPatterns.BALANCE)}>
        {t('balance')}
      </Button>
    </Stack>
  );
}

export default HeaderButtons;
