import qs from 'qs';
import axios, { AxiosInstance } from 'axios';
import { environment } from 'resources/constants';

const { BASE_URL } = environment;

const createInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10000, // Timeout after 10 seconds
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', encode: false }),
  });

  // Add request interceptors if needed
  instance.interceptors.request.use(
    (config) => {
      // Optionally modify the request config before sending
      return config;
    },
    (error) => {
      // Handle request errors
      console.error('Request Error:', error);
      return Promise.reject(error);
    },
  );

  // Add response interceptors for error handling
  instance.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response) {
        console.error('API Response Error:', error.response.data);
      } else if (error.request) {
        console.error('No Response from Server:', error.request);
      } else {
        console.error('Axios Configuration Error:', error.message);
      }
      return Promise.reject(error); // Propagate the error
    },
  );

  return instance;
};

const instance: AxiosInstance = createInstance();

export { instance };
