import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createLogin } from '../../services/login';
import LoadingScreen from 'components/main/Template/Loading';
import DocumentationPage from 'pages/documentation/DocumentationPage';
import { useAppDispatch } from 'hooks';
import { useDispatch } from 'react-redux';
import { saveLoginData } from 'store/login';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isWorked, setIsWorked] = useState<boolean>(false);

  useEffect(() => {
    // Parse query parameters
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('accessToken');
    const refreshToken = params.get('refreshToken');

    if (accessToken && refreshToken) {
      authenticateUser(accessToken, refreshToken);
      setIsWorked(true);
    } else {
      setError('Access or refresh token missing in the URL.');
      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    // Clear local storage on page load
    localStorage.clear();
  }, []);

  const authenticateUser = async (accessToken: string, refreshToken: string) => {
    try {
      setLoading(true);
      const response = await createLogin({ accessToken, refreshToken });

      if (response?.success) {
        const { data } = response;
        dispatch(saveLoginData(data));

        localStorage.setItem('isLoggedIn', 'true');
        navigate('/'); // Redirect to the home page
      } else {
        throw new Error('Failed to log in. Please try again.');
      }
    } catch (err) {
      setLoading(false);
      if ((window as any).Telegram?.WebApp) {
        (window as any).Telegram.WebApp.close();
      }
    }
  };

  if (loading) return <LoadingScreen />;
  if (isFirstVisit) return <DocumentationPage setIsFirstVisit={setIsFirstVisit} />;

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && !isWorked && <p>
        Please open the bot via{' '}
        <a href="https://t.me/pumpbattlestartbot" target="_blank" rel="noopener noreferrer">
          GooseRouletteBot
        </a>
      </p> }
    </div>
  );
};

export default LoginPage;
