import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Theme, useTheme } from '@mui/material';
import commaNumber from 'comma-number';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { TokensByOwnerIdDataItem } from 'resources/types';
import { roundNumber, getLastSeen, calculatePercentageIncrease } from 'utils';
import { environment } from 'resources/constants';

const { AWS_S3_BUCKET_URL, AWS_S3_IMAGE_FOLDER_PATH} = environment;

function CreatedTokens(props: {createdTokenData: TokensByOwnerIdDataItem[] | []}) {
  const { createdTokenData } = props;
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const reportProblemModalContent = (
    <Box>
      <Typography
        sx={{ mb: 1, mt: 3, textAlign: 'start', fontSize: '12px', fontWeight: 400, fontFamily: 'Montserrat' }}>
        {t('text_appeal')}
      </Typography>
      <TextField
        placeholder={t('appeal')}
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', mb: 5 }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={(theme: Theme) => ({
          background: theme.palette.highlightRed.main,
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '12px',
          textTransform: 'none',
          border: '2px solid #FFFFFF80',
        })}>
        {t('send')}
      </Button>
    </Box>
  );

  return (
    <Grid container rowSpacing={1}>
      {createdTokenData?.map((tokenData: any) => {

        const {
          description,
          circulatingSupply,
          shortName,
          currentPrice,
          imageUrl,
          createdAt,
          totalSupply
        } = tokenData;
      const imageSrc = `${AWS_S3_BUCKET_URL}/${imageUrl}`;

      return (
        <Grid
          p={1}
          container
          direction='row'
          width='100%'
          sx={{
            background: '#070707',
            borderRadius: '15px',
          }}
        >
          <Grid size={5} display='flex'>
            <Grid pr={1}>
              <img src={imageSrc ?? ''} alt={'name'} width={40} height={40} style={{ borderRadius: '50%'}} />
            </Grid>
            <Grid display='flex' flexDirection='column' justifyContent='center'>
              <Typography
                color='white'
                variant='body2'
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {shortName}
                <PeopleIcon height={24} width={24} style={{ marginLeft: '4px', marginRight: '2px' }} />
                {totalSupply}
              </Typography>
              <Typography color='primary' variant='subtitle1'>
                {getLastSeen(createdAt)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            size={7}
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Grid>
              <Typography color='white' variant='body2' px={1}>
                {`MC $${commaNumber(roundNumber(circulatingSupply * currentPrice))}`}
              </Typography>
              <Typography color='primary' variant='body2' px={1}>
                {calculatePercentageIncrease(currentPrice)}
              </Typography>
            </Grid>
            {/* TODO */}
            <Button
              sx={{
                border: '2px solid #F14A38',
                borderRadius: '12px',
                background: 'background: #FFFFFF26'
              }}
              onClick={() => setIsOpen(true)}
            >
              <Typography color='white' variant='body2'>
                Complaint
              </Typography>
            </Button>
          </Grid>
        </Grid>
        );
      })}
      <BottomDrawerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('report_problem')}
        boxShadow={currentTheme.palette.highlightRed.main}>
        {reportProblemModalContent}
      </BottomDrawerModal>
    </Grid>
  );
}

export default CreatedTokens;
