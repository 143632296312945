import { Stack, Skeleton } from '@mui/material';
import ImageCarousel from 'components/main/Template/ImageCarousel';
import { responsiveWidths } from 'resources/constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'store';

function SlideShow({ sliderState }: any) {
  const sliderInfo = useAppSelector((state: RootState) => state.slider);
  const { loading } = sliderInfo;
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '5px',
        width: '100%',
        ml: {
          sm: '10%',
        },
      }}>
      <ImageCarousel
        items={sliderState?.data?.data?.imagesDataValues}
        slideSx={{ width: '97%', height: '230px', boxShadow: true }}
      />
      <Stack sx={{ width: '35%', marginTop: '5%' }}>
        <ImageCarousel
          items={sliderState?.data?.data?.transactions}
          slideSx={{ width: '86%', height: '80px' }}
          isTransaction
        />
        <ImageCarousel items={sliderState?.data?.data?.nfts} slideSx={{ width: '95%', height: '120px' }} />
      </Stack>
    </Stack>
  );
}

export default SlideShow;
