import { Link, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Header from 'components/main/Header';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';
import BackArrowButton from 'components/main/Template/BackArrowButton';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { getTokenDetails } from 'store/token';
import TokenInfo from 'pages/ViewToken/TokenInfo';
import useTokenDetails from 'hooks/useTokenDetails';

function AboutToken() {
  const { t } = useTranslation();

  const TokenDetailsState = useAppSelector((state) => state.tokenInfo);
  const { tokenId } = useParams();

  const { tokenDetails } = useTokenDetails(tokenId ?? '');
  return (
    <Grid>
      <Header title={t('about_token_title')} starIcon={<BackArrowButton path=".." />} />

      <Grid sx={{ px: 1 }}>
        <Grid
          container
          width="100%"
          sx={(theme: Theme) => ({
            mt: 0.5,
            p: 1,
            borderRadius: '15px',
            bgcolor: theme.palette.secondary.main,
          })}
        >
          <img
            src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${tokenDetails?.info?.imageUrl}`}
            alt={tokenDetails.info?.fullName}
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
          />
          <Grid ml={1} container alignItems="start" direction="column" justifyContent="center">
            <Typography color="primary" variant="body2" px={1}>
              {tokenDetails?.info?.shortName}
            </Typography>
            <Typography
              px={1}
              color="white"
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '19.5px',
              }}
            >
              {tokenDetails?.info?.fullName}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          width="100%"
          sx={(theme: Theme) => ({
            px: 1,
            py: 2,
            mt: 1,
            borderRadius: '15px',
            bgcolor: theme.palette.secondary.main,
          })}
        >
          <Grid size={12} container>
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '17px',
              }}
            >
              Creator
            </Typography>
            <Grid
              sx={{
                flex: 1,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#FFFFFF66 !important',
              }}
            />
            <Typography
              color="primary"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '17px',
              }}
            >
              {tokenDetails?.info?.walletAddress}
            </Typography>
          </Grid>

          <Grid mt={2} size={12} container>
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '17px',
              }}
            >
              {t('about_token_name')}
            </Typography>
            <Grid
              sx={{
                flex: 1,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#FFFFFF66 !important',
              }}
            />
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '17px',
              }}
            >
              {tokenDetails?.info?.fullName}
            </Typography>
          </Grid>

          <Grid mt={2} size={12} container>
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '17px',
              }}
            >
              {t('about_token_short_name')}
            </Typography>
            <Grid
              sx={{
                flex: 1,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#FFFFFF66 !important',
              }}
            />
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '17px',
              }}
            >
              {tokenDetails?.info?.shortName}
            </Typography>
          </Grid>

          <Grid mt={2} size={12} container>
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '17px',
              }}
            >
              {t('about_token_telegram_chanel')}
            </Typography>
            <Grid
              sx={{
                flex: 1,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#FFFFFF66 !important',
              }}
            />
            <Link
              color="primary"
              onClick={(event) => event.stopPropagation()}
              to={tokenDetails?.info?.telegramChannel || '#'}
              style={{ display: 'flex', textDecoration: 'none' }}
            >
              <Typography
                color="primary"
                sx={{
                  px: 1,
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: '17px',
                }}
              >
                {/* {t('about_token_telegram_chanel_link')} */}

                {tokenDetails?.info?.telegramChannel}
              </Typography>
            </Link>
          </Grid>

          <Grid mt={2} size={12} container>
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '17px',
              }}
            >
              {t('about_token_holders')}
            </Typography>
            <Grid
              sx={{
                flex: 1,
                borderStyle: 'solid',
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderColor: '#FFFFFF66 !important',
              }}
            />
            <Typography
              color="white"
              sx={{
                px: 1,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '17px',
              }}
            >
              {tokenDetails?.details?.holder}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          width="100%"
          sx={(theme: Theme) => ({
            px: 2,
            py: 2,
            mt: 1,
            borderRadius: '15px',
            bgcolor: theme.palette.secondary.main,
          })}
        >
          <Typography
            color="white"
            sx={{
              px: 1,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: '17px',
            }}
          >
            {t('about_token_description')}
          </Typography>
          <Typography
            color="white"
            sx={{
              px: 1,
              mt: 1,
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '14px',
            }}
          >
            {tokenDetails?.details?.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutToken;
