import Grid from '@mui/material/Grid2';
import TokenForm from './TokenForm';
import Header from 'components/main/Header';
import { useTranslation } from 'react-i18next';
import BackArrowButton from 'components/main/Template/BackArrowButton';

function CreateToken() {
  const { t } = useTranslation();

  return (
    <Grid>
      <Header
        title={t('create_token_title')}
        starIcon={<BackArrowButton path='..' />}
      />
      <Grid p={2} sx={{ maxHeight: 'calc(100vh - 68px)', overflowY: 'auto' }}>
        <TokenForm />
      </Grid>
    </Grid>
  )
}

export default CreateToken;
