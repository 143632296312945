import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './resources/translations/en.json';
import ru from './resources/translations/ru.json';
import zh from './resources/translations/zh.json';
import tr from './resources/translations/tr.json';
import de from './resources/translations/de.json';
import fr from './resources/translations/fr.json';
import uk from './resources/translations/uk.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ru: { translation: ru },
    zh: { translation: zh },
    tr: { translation: tr },
    de: { translation: de },
    fr: { translation: fr },
    uk: { translation: uk },
  },
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
