import React from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useChart from 'hooks/useChart';

const CandlestickChart = () => {
  const { tokenId } = useParams();
  const [chartData] = useChart(tokenId ?? '');

  const option = {
    backgroundColor: '#f4f4f4',
    title: {
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: chartData.map((item) => moment(item[0]).format('MMM DD HH:mm:ss')),
      axisLine: { lineStyle: { color: '#aaa' } },
      splitLine: { show: true, lineStyle: { type: 'dashed', color: '#ddd' } },
    },
    yAxis: {
      scale: true,
      position: 'right',  // Move y-axis to the right
      axisLine: { lineStyle: { color: '#aaa' } },
      splitLine: { show: true, lineStyle: { type: 'dashed', color: '#ddd' } },
    },
    dataZoom: [     
      {
        type: 'inside', // Zoom by dragging
        xAxisIndex: 0,
      },
    ],
    series: [
      {
        type: 'candlestick',
        data: chartData.map((el) => [el.open, el.close, el.low, el.high]),
        itemStyle: {
          color: '#00da3c', // Green for increasing
          color0: '#ec0000', // Red for decreasing
          borderColor: '#00da3c',
          borderColor0: '#ec0000',
        },
      },
    ],
  };

  return (
    <div>
      <ReactECharts option={option} style={{ height: '300px', margin: '10px' }} />
    </div>
  );
};

export default CandlestickChart;
