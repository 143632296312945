import { List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { ReactComponent as PurchaseIcon } from 'assets/icons/token-arrow-up.svg';
import { ReactComponent as SaleIcon } from 'assets/icons/token-arrow-down.svg';
import Grid from '@mui/material/Grid2';
import { dynamicRouts } from 'resources/constants';
import { useNavigate } from 'react-router-dom';
import useTokenDetails from 'hooks/useTokenDetails';

const TransactionListItem = styled(ListItem)(({ theme }) => ({
  width: 'inherit',
  background: '#252525',
  marginTop: '8px',
  borderRadius: '12px',
  maxHeight: '34px',
  height: '34px',
}));

const InvestmentsListItem = styled(ListItem)(({ theme }) => ({
  width: 'inherit',
  background: '#252525',
  marginTop: '8px',
  borderRadius: '12px',
  maxHeight: '47px',
  height: '47px',
}));

function ListItems(props: { transactionState: any; variant: any; tokenId: any }) {
  const { transactionState, variant, tokenId } = props;
  const navigate = useNavigate();
  const goAboutTokenPage = () => {
    navigate(dynamicRouts.TOKENS_ABOUT_TOKEN(tokenId));
  };

  const formatToCompact = (value: number) => {
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    }
    return value.toString();
  };
  const { tokenDetails } = useTokenDetails(tokenId ?? '');

  return (
    <List sx={{ width: 'inherit' }}>
      {transactionState?.map((item: any, index: any) => {
        return variant === 'investments' ? (
          <InvestmentsListItem onClick={() => goAboutTokenPage()} key={`${index}_${item.state}`}>
            <Grid container sx={{ width: '100%' }}>
              <Grid container direction="column" justifyContent="center" size={6}>
                <Typography px={1} color="white" variant="body2" sx={{ width: 'max-content' }}>
                  {item?.walletAddress}
                </Typography>
                <Typography
                  px={1}
                  color="primary"
                  variant="body2"
                  sx={{
                    width: 'max-content',
                  }}
                >
                  {item.isCreator ? 'Token Creator' : ''}
                </Typography>
              </Grid>

              <Grid container direction="column" alignItems="end" justifyContent="center" size={6}>
                <Typography
                  px={1}
                  color="white"
                  variant="body2"
                  sx={{
                    width: 'max-content',
                  }}
                >
                  {item?.totalAmount} {tokenDetails?.info?.shortName}
                </Typography>
                <Typography
                  px={1}
                  color="primary"
                  variant="body2"
                  sx={{
                    width: 'max-content',
                  }}
                >
                  {item.percentage}%
                </Typography>
              </Grid>
            </Grid>
          </InvestmentsListItem>
        ) : (
          <TransactionListItem key={index}>
            {item.type === 'sell' ? <SaleIcon /> : <PurchaseIcon />}
            <ListItemText style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography color="white" variant="body2" px={1} py={2} sx={{ width: 'max-content' }}>
                {item?.type === 'buy' ? 'Buying' : 'Selling'} {item?.amountTon} TON &#62;{' '}
                {formatToCompact(item?.amountToken)} {item?.usdtAmount} USDT
              </Typography>
            </ListItemText>
          </TransactionListItem>
        );
      })}
    </List>
  );
}

export default ListItems;
