import { instance } from './instance';
const TOKENS = '/auth/login';

const getLogin = (): Promise<any> => {
  return instance.get(TOKENS);
};

export const createLogin = (payload: any): Promise<any> => {
    return instance.post(TOKENS, payload).then(response => response.data);
};

export default {
  getLogin,
  createLogin
};
