import { instance } from './instance';
import type { TokenCategory, GetTokenListResponse, CreateTokenRequestParams } from 'resources/types';
import routes from 'resources/routes';

import { endpoints } from 'resources/constants';

const TOKENS = '/users/tokens/';
const TRANSACTIONS = '/tokens/transactions';

import { HistoryVariant } from 'pages/ViewToken/TransactionsHistory';
import { keyboard } from '@testing-library/user-event/dist/keyboard';

const getTokensByCategory = (tokenCategory?: TokenCategory): Promise<any> => {
  return instance.get(endpoints.TOKENS_BY_CATEGORY, {
    params: {
      ...(tokenCategory && { category: tokenCategory }),
    },
  });
};

const getTokensByKeywordAndCategory = (keyboard: string, category: string): Promise<any> => {
  return instance.get(endpoints.TOKENS_BY_CATEGORY, {
    params: {
      name: keyboard,
      category: category,
    },
  });
};
const getTokens = (): Promise<GetTokenListResponse> => {
  return instance.get(TOKENS);
};

const createToken = (params: CreateTokenRequestParams): Promise<void> => {
  return instance.post(routes.tokensCreate, { ...params });
};

const getTokensListByOwnerId = (id: string): Promise<any> => {
  return instance.get(`${TOKENS}${id}`);
};

const getTransactionsByCategory = (transactionsCategory: HistoryVariant, tokenId: any): Promise<any> => {
  const queryParams =
    transactionsCategory === 'all' ? { tokenId_eq: tokenId } : { tokenId_eq: tokenId, type_eq: transactionsCategory };
  return instance.get(TRANSACTIONS, {
    params: queryParams,
  });
};

const getTokenDeatial = (id: any): Promise<any> => {
  return instance.get(routes.tokenDeatial.replace('{{tokenId}}', id));
};
const getTokenInvestors = (id: any): Promise<any> => {
  return instance.get(routes.tokenInvestors.replace(':{{tokenId}}', id));
};

const PostTokenSell = (id: any, data: any): Promise<any> => {
  return instance.post(routes.tokenSell.replace('{{tokenId}}', id), data);
};
const PostTokenbuy = (id: any, amount: any): Promise<any> => {
  const data = { ...amount };
  return instance.post(routes.tokenBuy.replace('{{tokenId}}', id), data);
};

const getTokenChartsData = (id: string): Promise<any> => {
  return instance.get(routes.tokenCharts.replace('{{tokenId}}', id));
};
export const getTokenPrice = ({
  id,
  type,
  amount,
}: {
  id: string;
  type: string;
  amount: string | number;
}): Promise<any> => {
  return instance.get(`/tokens/count/price/${id}`, {
    params: {
      type: type,
      amount: amount,
    },
  });
};
export default {
  getTokens,
  createToken,
  getTokensByCategory,
  getTokensListByOwnerId,
  getTransactionsByCategory,
  getTokenDeatial,
  getTokenChartsData,
  getTokenInvestors,
  PostTokenSell,
  PostTokenbuy,
  getTokensByKeywordAndCategory,
  getTokenPrice,
};
