
import { Button, Theme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TokenPageContent from './TokenContent';
import Header from 'components/main/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routsPatterns } from 'resources/constants';

function TokenPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid p={2}>
      <Header title='Tokens'/>
      <TokenPageContent />
      <Button
          variant="contained"
          sx={(theme: Theme) => ({
            color: '#fff',
            background: theme.palette.gradient.primaryVertical,
            border: '1px solid rgba(255, 255, 255, 0.5)',
            textTransform: 'none',
            left: '16px',
            right: '16px',
            width: 'inherit',
            borderRadius: '12px',
            position: 'fixed',
            bottom: '102px',
          })}
          onClick={() => navigate(routsPatterns.TOKENS_CREATE)}
        >
          {t('Create your own token')}
      </Button>
    </Grid>
  )
};

export default TokenPage;
