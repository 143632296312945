import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';

const mockData = [
  {
    id: 1,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 2,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 3,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 4,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 5,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 6,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
  {
    id: 7,
    name: '@User121313',
    avatar: 'https://via.placeholder.com/40',
    timestamp: '27/11/2024 15:43',
  },
];

function InvitationsPage() {
  const { t } = useTranslation();
  const count = 13;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: '#000',
        color: '#fff',
        height: '100vh',
        overflow: 'auto',
        p: 2,
        pt: 1,
      }}
    >
      <Box>
        <Header title={t('invite_friend')} path={routsPatterns.TASKS} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 700, color: '#75FF47', fontFamily: 'Montserrat', mt: 2, mb: 1 }}
        >
          {count} {t('friends')}
        </Typography>
        <List
          sx={{
            padding: 0,
          }}
        >
          {mockData.map((user) => (
            <ListItem
              key={user.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '15px',
                padding: '12px',
                marginBottom: '4px',
                bgcolor: '#333',
                '&:last-child': {
                  marginBottom: 0,
                },
              }}
            >
              <Grid container alignItems="center">
                <ListItemAvatar>
                  <Avatar
                    src={user.avatar}
                    alt={user.name}
                    sx={{
                      border: '2px solid #75FF47',
                      width: 40,
                      height: 40,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#fff',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {user.name}
                    </Typography>
                  }
                />
              </Grid>
              <Typography
                component="span"
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#fff',
                  fontFamily: 'Montserrat',
                }}
              >
                {user.timestamp}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      <Button
        variant="contained"
        sx={(theme: Theme) => ({
          color: '#fff',
          background: theme.palette.gradient.primaryVertical,
          border: '2px solid #FFFFFF80',
          textTransform: 'none',
          width: '100%',
          borderRadius: '12px',
          mb: 4,
          fontSize: '14px',
          fontWeight: 700,
          mt: 1,
        })}
      >
        {t('copy_link')}
      </Button>
    </Box>
  );
}

export default InvitationsPage;
