import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import type { CreateTokenRequest, TokenRow } from 'resources/types';

const GET_TOKENS = 'home/getTokens';
const CREATE_TOKEN = 'home/createToken';

const getTokens = createAsyncThunk(
  GET_TOKENS,
  async (): Promise<Array<TokenRow>> => {
    const response = await services.getTokens();
    return response.data;
  },
);

// const createToken = createAsyncThunk(
//   CREATE_TOKEN,
//   async (tokenData: CreateTokenRequest): Promise<TokenRow> => {
//     const response = await services.createToken(tokenData);
//     return response.data;
//   },
// );

export { getTokens };
