import Grid from '@mui/material/Grid2';
import Header from 'components/main/Header';
import { useTranslation } from 'react-i18next';
import BackArrowButton from 'components/main/Template/BackArrowButton';
import { Button, Theme, Typography, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CreatedTokens from './CreatedTokens';
import { ReactComponent as InvestmentSelectedIcon } from 'assets/icons/receipt-item.svg';
import { ReactComponent as CreateTokenIcon } from 'assets/icons/create_token.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/receipt.svg';
import { ReactComponent as StatusTokenIcon } from 'assets/icons/status_token.svg';
import Investments from './Investments';
import { useState, MouseEvent, useEffect } from 'react';
import { getTokensListByOwnerId } from 'store/token';
import { useAppDispatch, useAppSelector } from 'hooks';
import Loading from 'components/main/Template/Loading';
import { useParams, useSearchParams } from 'react-router-dom';
export interface CreatedTokenTypes {
  id: number;
  amountTon: number;
  amountToken: number;
  usdtAmount: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  tokenId: number;
  token: {
    id: number;
    fullName: string;
    shortName: string;
    description: string;
    imageUrl: string;
    ownerId: number;
    currentPrice: number;
    totalSupply: number;
    circulatingSupply: number;
    channelUrl: string;
    startAt: number;
    createdAt: string;
    updatedAt: string;
  };
}

export type OwnerTokensVariant = 'investments' | 'createdTokens';

function OwnerToken() {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const dispatch = useAppDispatch();
  const tokenInfo = useAppSelector((state) => state.tokenInfo);
  const { id } = useParams();

  const [tokensListState, setTokensListState] = useState<OwnerTokensVariant>('investments');
  const {loading, userInfo, tokenList, investments} = tokenInfo;

  const changeVariant = (event: MouseEvent<HTMLButtonElement>) => {
    const name = event.currentTarget.name;
    setTokensListState(name as OwnerTokensVariant);
  };

  useEffect(() => {
    dispatch(getTokensListByOwnerId(id ?? ''));
  }, [id]);


  return (
    <Grid>
      {loading && <Loading />}
      <Header
        title={t('owner_token_title')}
        starIcon={<BackArrowButton path='..' />}
      />
      <Grid container padding={2}>
        <Grid>
          <Avatar
            alt="Token owner"
            src={''}
            sx={(theme: Theme) => ({
              height: '50px',
              width: '50px',
              boxShadow: `0px 0px 15px 1px ${theme.palette.primary.main}}`,
            })}
          />
        </Grid>
        <Grid ml={2} display="flex" flexDirection="column" justifyContent="center">
          <Typography
            color="primary"
            sx={{
              fonSize: '10px',
              lineHeight: '12.19px',
              fontWeight: 600,
            }}
          >
            {`@${userInfo?.firstName?.toLocaleLowerCase()}`}
          </Typography>

          <Typography
            color="White"
            sx={{
              fonSize: '16px',
              lineHeight: '19.5px',
              fontWeight: 700,
            }}>
            {userInfo?.firstName}
          </Typography>
          <Typography
            color="primary"
            sx={{
              fonSize: '10px',
              lineHeight: '12.19px',
              fontWeight: 500,
            }}>
            {userInfo?.walletAddress}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.secondary.main,
          borderTopLeftRadius: '38px 38px',
          borderTopRightRadius: '38px 38px',
        })}>
        <Grid container spacing={1} display="flex" sx={{ height: '27px' }} mb={1} p={2}>
          <Grid size={6}>
            <Button
              variant="contained"
              fullWidth
              startIcon={
                tokensListState === 'createdTokens' ? (
                  <CreateTokenIcon
                    // fill={currentTheme.palette.primary.main}
                    height={14}
                    width={14}
                  />
                ) : (
                  <StatusTokenIcon fill={currentTheme.palette.primary.main} height={14} width={14} />
                )
              }
              sx={(theme: Theme) => ({
                background: '#FFFFFF26',
                color: '#fff',
                padding: '12px',
                fontSize: '14px',
                fontWeight: 'bold',
                borderRadius: '12px',
                height: '27px',
                '& .MuiButton-icon': { mr: 0.5 },
                ...(tokensListState === 'createdTokens' && {
                  color: theme.palette.secondary.dark,
                  background: theme.palette.gradient.primaryVertical,
                }),
              })}
              name="createdTokens"
              color={tokensListState === 'createdTokens' ? 'primary' : 'secondary'}
              onClick={changeVariant}>
              <Typography variant="captureBold">{t('Created Tokens')}</Typography>
            </Button>
          </Grid>
          <Grid size={6}>
            <Button
              variant="contained"
              fullWidth
              sx={(theme: Theme) => ({
                background: '#FFFFFF26',
                color: '#fff',
                padding: '12px',
                fontSize: '14px',
                fontWeight: 'bold',
                borderRadius: '12px',
                height: '27px',
                '& .MuiButton-icon': { mr: 0.5 },
                ...(tokensListState === 'investments' && {
                  color: theme.palette.secondary.dark,
                  background: theme.palette.gradient.primaryVertical,
                }),
              })}
              startIcon={
                tokensListState === 'investments' ? (
                  <InvestmentSelectedIcon height={14} width={14} />
                ) : (
                  <InvestmentIcon fill={currentTheme.palette.primary.main} height={14} width={14} />
                )
              }
              // InvestmentSelectedIcon
              color={tokensListState === 'investments' ? 'primary' : 'secondary'}
              name="investments"
              onClick={changeVariant}>
              <Typography variant="captureBold">{t('Investments')}</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          p={2}
          sx={{
            height: '72vh',
            maxHeight: '72vh',
            overflow: 'scroll',
          }}>
          {tokensListState === 'createdTokens' ? (
            <CreatedTokens createdTokenData={tokenList} />
          ) : (
            <Investments investmentsTokenData={investments} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OwnerToken;
