import { createSlice } from '@reduxjs/toolkit';
import { getHistoryAllData, getHistoryBalanceData, getHistoryTokensData } from './thunks';

export interface HistoryState {
  historyAllData: any;
  historyBalanceData: any;
  getHistoryTokensData: any;
  loading: boolean;
  error: string | null;
}

const initialState: HistoryState = {
  historyAllData: [],
  historyBalanceData: [],
  getHistoryTokensData: [],
  loading: false,
  error: null,
};

const HistorySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    resetSlider: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getHistoryAllData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHistoryAllData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch slider data';
    });
    builder.addCase(getHistoryAllData.fulfilled, (state, action) => {
      state.loading = false;
      state.historyAllData = action.payload;
    });

    builder.addCase(getHistoryBalanceData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHistoryBalanceData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch NFT data';
    });
    builder.addCase(getHistoryBalanceData.fulfilled, (state, action) => {
      state.loading = false;
      state.historyBalanceData = action.payload;
    });

    // Transaction Data
    builder.addCase(getHistoryTokensData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHistoryTokensData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch transaction data';
    });
    builder.addCase(getHistoryTokensData.fulfilled, (state, action) => {
      state.loading = false;
      state.getHistoryTokensData = action.payload;
    });
  },
});

export const { resetSlider } = HistorySlice.actions;
export default HistorySlice;
