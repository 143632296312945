
const ENV = process.env;
const BASE_URL = ENV.REACT_APP_API_URL ?? 'http://localhost:4000/api/';
const MANIFEST_URL = ENV.REACT_APP_MANIFEST_URL ?? 'https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json';
const ENCRYPTION_KEY = ENV.REACT_APP_ENCRYPTION_KEY ?? '';
const TRANSACTION_API_KEY = ENV.REACT_APP_ENCRYPTION_KEY ?? '';
const WALLET_ADDRESS = ENV.REACT_APP_WALLET_ADDRESS ?? '';
const SUPPLY_TOTAL = ENV.REACT_APP_SUPPLY_TOTAL ?? '1000000000';
const AWS_S3_BUCKET_URL = ENV.REACT_APP_AWS_S3_BUCKET_URL ?? '';
const AWS_S3_IMAGE_FOLDER_PATH = ENV.REACT_APP_AWS_S3_IMAGE_FOLDER_PATH ?? '';
const AWS_S3_VIDEO_FOLDER_PATH = ENV.REACT_APP_AWS_S3_VIDEO_FOLDER_PATH ?? '';

export default {
  BASE_URL,
  MANIFEST_URL,
  ENCRYPTION_KEY,
  TRANSACTION_API_KEY,
  WALLET_ADDRESS,
  SUPPLY_TOTAL,
  AWS_S3_BUCKET_URL,
  AWS_S3_IMAGE_FOLDER_PATH,
  AWS_S3_VIDEO_FOLDER_PATH
}
