import { Box, Button, Card, CardContent, CardMedia, Stack, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routsPatterns } from 'resources/constants';
import { NftItem } from 'types/app';

interface NftCardProps {
  item: NftItem;
  selectedButton?: string;
  userNftData?: any;
}

function NftCard({ item, selectedButton, userNftData}: NftCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMyButton = selectedButton === 'My';
  const currUserNftsIds = userNftData?.map((nft: any) => nft.id);

  const handleClick = (item: NftItem) => {
    navigate(
      isMyButton
        ? routsPatterns.SELL_NFT.replace(':id', item.nft.id.toString())
        : routsPatterns.BUY_NFT.replace(':id', item.nft.id.toString())
    );
  };

  return (
    <Card
      key={item.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#2c2c2c',
        color: '#fff',
        borderRadius: '10px',
        mb: '4px',
        p: '0 8px',
      }}>
      <Box width="45px">
        <CardMedia
          component="img"
          image={`https://reboot-bucket.s3.eu-north-1.amazonaws.com/${item.nft.image}`}
          alt={item.nft.name}
          sx={{ borderRadius: '12px', width: '100%', height: '65px', padding: '10% 0', objectFit: 'cover' }}
        />
      </Box>
      <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 500, fontFamily: 'Audiowide' }}>{item.nft.name}</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#75FF47', fontFamily: 'Montserrat' }}>
            {item.price} TON
          </Typography>
        {/* <Typography sx={{ fontSize: '10px', fontWeight: 400, fontFamily: 'Montserrat' }}>
          ({item.quantity || 1} )
        </Typography> */}
      </CardContent>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Button
          variant="contained"
          sx={(theme: Theme) => ({
            background: theme.palette.gradient.primary,
            textTransform: 'none',
            color: '#fff',
            width: '80px',
            height: '34px',
            marginRight: '4px',
            borderRadius: '12px',
            border: '2px solid #75FF47',
          })}
          disabled={(isMyButton && item.forSale) || currUserNftsIds?.includes(item.id)}
          onClick={() => handleClick(item)}>
          {item.forSale ? t('buy') : t('sell')}
        </Button>
      </Stack>
    </Card>
  );
}

export default NftCard;
