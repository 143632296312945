import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showMessage } from 'store';
import { useTranslation } from 'react-i18next';
import { useTonConnectUI, useTonAddress, useIsConnectionRestored, useTonWallet } from '@tonconnect/ui-react';
import i18n from 'i18n';

import Grid from '@mui/material/Grid2';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from '@mui/material/IconButton';
import { Theme, Typography, Skeleton } from '@mui/material';

import { ReactComponent as TonCoinIcon } from 'assets/icons/ton_coin.svg';

function AppBar() {
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const rawAddress = useTonAddress(false);
  const connectionRestored = useIsConnectionRestored();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  // wallet.connectItems?.tonProof?.proof && wallet.connectItems.tonProof.proof

  // const encryptId = (id: string, secretKey: string): string => {
  //   return CryptoJS.AES.encrypt(id, secretKey).toString();
  // };
  const handleWallet = async () => {
    try {
      if (isConnected) {
        // Disconnect wallet and clear local storage
        await tonConnectUI.disconnect();
        localStorage.removeItem('tonWalletAddress');
        showMessage({
          messages: ['Disconnected successfully!'],
          options: { variant: 'success' },
        });
        setIsConnected(false);
      } else {
        // Open connection modal
        await tonConnectUI.openModal();
      }
    } catch (error) {
      showMessage({
        messages: ['Error during wallet operation!'],
        options: { variant: 'error' },
      });
    }
  };

  useEffect(() => {
    if (rawAddress && connectionRestored) {
      // Save wallet address to localStorage
      localStorage.setItem('tonWalletAddress', rawAddress);
      setIsConnected(true);
    }
  }, [rawAddress, connectionRestored]);

  useEffect(() => {
    const savedAddress = localStorage.getItem('tonWalletAddress');
    if (savedAddress && !isConnected) {
      // Use the `connector` to check and handle wallet connection
      tonConnectUI.connector.restoreConnection()
        .then(() => {
          setIsConnected(true);
        })
        .catch((error) => {
          console.error('Failed to restore connection:', error);
          showMessage({
            messages: ['Failed to restore connection. Please reconnect!'],
            options: { variant: 'error' },
          });
          localStorage.removeItem('tonWalletAddress');
        });
    }
  }, [tonConnectUI.connector, isConnected]);
  

  return (
    <Grid
      container
      sx={{
        height: '46px',
        maxHeight: '46px',
        paddingLeft: '16px',
        mt: 1,
      }}>
      <Grid size={{ xs: 3 }} display="flex" alignItems="center">
        <IconButton
          sx={(theme: Theme) => ({
            height: '36px',
            width: '36px',
            fontFamily: '',
            backgroundColor: theme.palette.secondary.main,
            textTransform: 'none',
          })}
          onClick={() => navigate('/settings')}>
          <SettingsOutlinedIcon
            sx={(theme: Theme) => ({
              fill: theme.palette.primary.contrastText,
            })}
          />
        </IconButton>
      </Grid>
      <Grid size={{ xs: 6 }} display="flex" alignItems="center" justifyContent="center">
        <Typography
          fontWeight={700}
          sx={(theme: Theme) => ({
            fontFamily: 'Montserrat',
            fontSize: '14px',
            color: '#FFFFFF',
          })}>
          {t('pump_battle')}
        </Typography>
        <Typography
          height={22}
          fontWeight={500}
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '8px',
            color: '#FFFFFF',
            margin: '4px 0 0 5px',
          }}>
          {t('beta')}
        </Typography>
      </Grid>
      <Grid size={{ xs: 3 }} display="flex" alignItems="center" justifyContent="end">
        {connectionRestored ? (
          <Grid
            container
            display="flex"
            alignItems="center"
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.secondary.main,
              width: '98px',
              height: '36px',
              borderTopLeftRadius: '19px 19px',
              borderBottomLeftRadius: '19px 19px',
              paddingLeft: '3px',
            })}>
            <IconButton sx={{ padding: 0 }} onClick={handleWallet}>
              <Grid
                container
                sx={(theme: Theme) => ({
                  background: rawAddress ? theme.palette.error.main : theme.palette.gradient.primary,
                  width: rawAddress ? '84px' : '75px',
                  height: '30px',
                  borderRadius: '19px',
                })}>
                <Grid sx={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
                  <TonCoinIcon width={16} height={14} />
                  <Typography
                    fontWeight={700}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: i18n.language === 'ru' ? '7px' : '8px',
                      color: '#FFFFFF',
                      marginLeft: '2px',
                    }}>
                    {rawAddress ? t('disconnect') : t('connect_wallet')}
                  </Typography>
                </Grid>
              </Grid>
            </IconButton>
          </Grid>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.secondary.main,
              width: '98px',
              height: '36px',
              borderTopLeftRadius: '19px 19px',
              borderBottomLeftRadius: '19px 19px',
              paddingLeft: '3px',
            })}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AppBar;
