import { Box, Button, CardContent, Stack, Theme, Typography } from '@mui/material';
import Header from 'pages/Settings/Header';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';
import NftBattle from './NftBattle';
import Loading from 'components/main/Template/Loading';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getNFTOne, buyNft } from 'store/nft/thunks';
import { environment } from 'resources/constants';

const { AWS_S3_BUCKET_URL } = environment;

function BuyNft() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {loading, nftOneData} = useAppSelector((state) => state.nft);

  useEffect(() => {
    dispatch(getNFTOne(id));
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
 
  const handleBuyNft = (data: any) => {
    if(id) {
      dispatch(buyNft({id, ownerId: data.nft.creatorId, count: 1}))
    }
  } 


  return (
    <Box
      sx={{
        bgcolor: '#000',
        color: '#fff',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '97vh',
        overflow: 'scroll',
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Header title={t('about_nft')} path={routsPatterns.NFT_MARKET} />
        <Box
          component="img"
          src={`${AWS_S3_BUCKET_URL}/${nftOneData?.data?.nft.image}`}
          alt={nftOneData?.data.nft.name}
          sx={{
            width: '100%',
            height: '350px',
            objectFit: 'cover',
            borderRadius: '15px',
            mb: '6px',
            mt: 1,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography sx={{ fontWeight: 600, fontSize: '16px', fontFamily: 'Montserrat' }}>
                {nftOneData?.data.nft.name}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontFamily: 'Montserrat' }}>({nftOneData?.data?.quantity} {t('available')})</Typography>
            </Stack>
            <Stack flexDirection="row" gap={1}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#75FF47', fontFamily: 'Montserrat' }}>
                {t('from')}{' '}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#75FF47', fontFamily: 'Montserrat' }}>
                {' '}
                {nftOneData?.data.price}
              </Typography>
            </Stack>
          </Box>
          <NftBattle nftData={nftOneData?.data.nft} />
        </Stack>
        <CardContent sx={{ mt: 1, bgcolor: '#292929', borderRadius: '12px', p: 2 }}>
          <Typography sx={{ fontWeight: 600, fontSize: '14px', mb: 1, fontFamily: 'Montserrat' }}>
            {t('description')}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '12px', fontFamily: 'Montserrat' }}>
            {/* {t('nft_description')} */}
            {nftOneData?.data.nft.description}
          </Typography>
        </CardContent>
      </Box>
      <Box mt={1}>
        <Button
          variant="contained"
          onClick={() => handleBuyNft(nftOneData.data)}
          sx={(theme: Theme) => ({
            background: theme.palette.gradient.primary,
            color: '#fff',
            mb: 2,
            width: '100%',
            borderRadius: '12px',
            textTransform: 'none',
            height: '40px',
            fontWeight: 700,
            fontSize: '14px',
            border: '2px solid #FFFFFF80',
          })}>
          {t('view_token_transactions_purchase')}
        </Button>
      </Box>
    </Box>
  );
}

export default BuyNft;
