import { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppBar from 'components/main/AppBar';
import ActionBar from '../ActionBar';
import Grid from '@mui/material/Grid2';

function AppTemplate(props: { children?: ReactNode }) {
  const { children } = props;
  const appBarTemplatesList = ['/'];
  const location = useLocation();
  const showAppBar = appBarTemplatesList.includes(location.pathname);
  return (
    <Grid
      height="100vh"
      display="flex"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: '#070707',
      }}>
      {showAppBar && <AppBar />}
      <Grid
        sx={{
          flex: 1,
          overflowY: 'auto',
          // padding: '8px 8px 0 8px',
          backgroundColor: 'black',
        }}>
        {children || <Outlet />}
      </Grid>
      <ActionBar />
    </Grid>
  );
}

export default AppTemplate;

AppTemplate.defaultProps = {
  children: null,
};
