import { useState } from 'react';
import { Button, Theme, Typography } from '@mui/material';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { ReactComponent as TopUpIcon } from 'assets/icons/top_up.svg';
import { ReactComponent as TakeOffIcon } from 'assets/icons/take_off.svg';
import TopUpOptions from './TopUpOptions';
import AmountInput from './TonAmountInput';
import NumbersSelection from './NumbersSelection';
import QRCodeSection from './QRCodeSection';
import { useTranslation } from 'react-i18next';
import useTransaction from 'hooks/useTransaction';
import { v4 as uuid } from 'uuid';
import { encryptTransactionId } from 'utils/encryptTransactionId';
import { showMessage } from 'store';
import services from 'services';

const numberInputValues = ['1', '10', '20', '200'];

function BalanceActionModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActiveActionType] = useState<'topUp' | 'withdraw'>('topUp');
  const [selectedOption, setSelectedOption] = useState('tonSpace');
  const [tonUpValue, setTonUpValue] = useState();
  const [amount, setAmount] = useState('');
  const transactionId = encryptTransactionId(uuid());
  const { tonConnectUI, transaction } = useTransaction(amount, transactionId);
  const [numberSelectionValue, setNumberSelectionValue] = useState<string | null>(null);
  const tonValueRegex = /^(0\.(00[5-9]|0[1-9]\d|[1-9]\d{1,2})|([1-9]\d{0,8}|1000000000)(\.\d{1,3})?)$/;

  const updateAmountValue = (value: string) => {
    if(value || value === '') {
      setAmount(value);
      if(numberInputValues.includes(value)) {
        setNumberSelectionValue(value);
      } else {
        setNumberSelectionValue(null);
      }
    }
  }

  const updateNumberSelectionValue = (value: string | null) => {
    if(value) {
      setAmount(value);
      setNumberSelectionValue(value);
    }
  }

  const { t } = useTranslation();
  // const encryptedData = encryptTransactionId(uuid());
  // const {tonConnectUI, transaction } = useTransaction('1', encryptedData);

  const handleSave = () => {
    console.log('Save button clicked');
    // Logic to save the QR code
  };

  const handleShare = () => {
    console.log('Share button clicked');
    // Logic to share the QR code
  };

  const handleButtonClick = (action: 'topUp' | 'withdraw') => () => {
    setActiveActionType(action);
    setIsOpen(true);
  };

  const title = actionType === 'withdraw' ? 'Withdraw Funds' : 'topUp_title';
  const buttonText = actionType === 'withdraw' ? 'Withdraw' : 'top_up';

  const takeOffModalContent = (
    <>
      <AmountInput amount={amount} setAmount={updateAmountValue} actionType={actionType} />

      <Typography variant="body2" sx={{ mb: 1, mt: 3, textAlign: 'start' }} fontFamily="Montserrat">
        {t('need_help')}{' '}
        <a href="#" style={{ color: '#00FF00', textDecoration: 'underline' }}>
          {t('contact_us')}
        </a>
      </Typography>
    </>
  );

  const topUpModalContent = (
    <>
      <TopUpOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
      {selectedOption !== 'address' && <AmountInput amount={amount} setAmount={updateAmountValue} actionType={actionType} />}

      {selectedOption === 'tonSpace' ? (
        <NumbersSelection
          value={numberSelectionValue}
          setValue={updateNumberSelectionValue}
        />
      ) : selectedOption === 'address' ? (
        <QRCodeSection
          qrValue="123456789" // Replace with actual QR value
          onSave={handleSave}
          onShare={handleShare}
        />
      ) : null}

      <Typography variant="body2" sx={{ mb: 1, mt: 3, textAlign: 'start' }} fontFamily="Montserrat">
        {t('need_help')}{' '}
        <a href="#" style={{ color: '#00FF00', textDecoration: 'underline' }}>
          {t('contact_us')}
        </a>
      </Typography>
    </>
  );

  const topUpHandler = async () => {
    if (!tonConnectUI.wallet) {
      showMessage({
        messages: ['Wallet not connected!'],
        options: { variant: 'warning' },
      });
      return;
    }

    if (!tonValueRegex.test(amount)) {
      showMessage({
        messages: ['Min 0.005 Ton  Max 200000Ton'],
        options: { variant: 'warning' },
      });
    } else {
      try {
        const res = await tonConnectUI.sendTransaction(transaction);
        showMessage({
          messages: ['Transaction is pending'],
          options: { variant: 'success' },
        });
        if (res && res.boc) {
          services.createTransaction({
            amount,
            transactionId
          })
        }
      } catch (error) {
        console.log('Error Message   ', error)
        showMessage({
          messages: ['Transaction failed. Please check your wallet'],
          options: { variant: 'error' },
        });
      }
    }
  };

  const withdrawHandler = async () => {
    try {
      services.createWithdraw({
        amount,
        transactionId: ''
      })
    } catch (error) {
      console.log('Error Message   ', error)
      showMessage({
        messages: ['Withdraw failed. Please check your wallet'],
        options: { variant: 'error' },
      });
    }
    setIsOpen(false);
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<TopUpIcon height={20} width={20} />}
        sx={(theme: Theme) => ({
          width: '125px',
          height: '32.5px',
          borderRadius: '10px 10px 4px 4px',
          lineHeight: '14.63px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: theme.palette.gradient.primary,
        })}
        onClick={handleButtonClick('topUp')}
      >
        <Typography
          variant="subtitle2"
          fontFamily="Audiowide"
          fontSize={12}
          alignSelf="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('top_up')}
        </Typography>
      </Button>

      <Button
        variant="contained"
        color="white"
        startIcon={<TakeOffIcon height={20} width={20} />}
        sx={{
          width: '125px',
          height: '32.5px',
          borderRadius: '4px 4px 10px 10px',
          lineHeight: '14.63px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        onClick={handleButtonClick('withdraw')}
      >
        <Typography
          variant="subtitle2"
          fontFamily="Audiowide"
          fontSize={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          alignSelf="end"
        >
          {t('Withdraw')}
        </Typography>
      </Button>
      <BottomDrawerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        buttonText={buttonText}
        topUpHandler={actionType === 'withdraw' ? withdrawHandler : topUpHandler}
      >
        {actionType === 'withdraw' ? takeOffModalContent : topUpModalContent}
      </BottomDrawerModal>
    </>
  );
}

export default BalanceActionModal;
