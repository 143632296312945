import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY="testCryptoSecretKey"
const TRANSACTION_API_KEY="testCryptoSecretKey"

export const encryptTransactionId = (transactonId: string) => {
  const appTransactionId = `${TRANSACTION_API_KEY}:${transactonId}`;
  return CryptoJS.AES.encrypt(appTransactionId, ENCRYPTION_KEY).toString();
};

export {};