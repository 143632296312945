import { instance } from './instance';
import type { GetTokenListResponse, CreateTokenRequest, CreateTokenResponse } from 'resources/types';
import routes from 'resources/routes';

const getColors = (): Promise<GetTokenListResponse> => {
  return instance.get(routes.getColors);
};

const getUserColors = (): Promise<GetTokenListResponse> => {
  return instance.get(routes.getUserColors);
};

const chooseColor = (payload: CreateTokenRequest): Promise<CreateTokenResponse> => {
  return instance.put(routes.chooseColor, payload);
};

const buyColor = (payload: CreateTokenRequest): Promise<CreateTokenResponse> => {
  return instance.post(routes.buyColor, payload);
};

export default {
  getColors,
  getUserColors,
  chooseColor,
  buyColor,
};
