import { Box, Typography, ToggleButton, ToggleButtonGroup, Theme } from '@mui/material';
import { ReactComponent as TonSpaceIcon } from 'assets/icons/token_ton.svg';
import { ReactComponent as TonSpaceActiveIcon } from 'assets/icons/Vector.svg';
import { ReactComponent as AddressIcon } from 'assets/icons/textalign-left.svg';
import { ReactComponent as AddressActiveIcon } from 'assets/icons/textalign-left1.svg';
import { useTranslation } from 'react-i18next';

interface TopUpOptionsProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

function TopUpOptions({ selectedOption, setSelectedOption }: TopUpOptionsProps) {
  const { t } = useTranslation();

  const handleSelection = (newOption: string | null) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#000' }}>
      <ToggleButtonGroup
        value={selectedOption}
        exclusive
        onChange={(_, newOption) => handleSelection(newOption)}
        sx={(theme: Theme) => ({
          display: 'flex',
          justifyContent: 'center',
          gap: '4px',
          mb: '16px',
          '& .MuiToggleButton-root': {
            flex: 1,
            borderRadius: '12px',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: '14px',
            '&.Mui-selected': {
              background: theme.palette.gradient.primary,
              color: '#000',
            },
            '&:not(.Mui-selected)': {
              backgroundColor: '#333',
              color: '#fff',
            },
          },
        })}
      >
        <ToggleButton value="tonSpace">
          {selectedOption === 'tonSpace' ? <TonSpaceActiveIcon /> : <TonSpaceIcon />}
          <Typography fontWeight="bold" fontSize="14px" ml={1} fontFamily="Montserrat">
            {t('ton_space')}
          </Typography>
        </ToggleButton>

        <ToggleButton value="address">
          {selectedOption === 'address' ? <AddressIcon /> : <AddressActiveIcon />}
          <Typography fontWeight="bold" fontSize="14px" ml={1} fontFamily="Montserrat">
            {t('address')}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>

      {selectedOption !== 'address' && (
        <Typography
          variant="body2"
          fontFamily="Montserrat"
          sx={{ color: '#fff', textAlign: 'left', mt: '8px', mb: '6px' }}
        >
          {t('enter_amount')} <span style={{ fontWeight: 'bold', color: '#fff' }}>{t('minimum')} 0.005 TON</span>
        </Typography>
      )}
    </Box>
  );
}

export default TopUpOptions;
