import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { HistoryVariant } from 'pages/ViewToken/TransactionsHistory';
import type { ApiResponseTokensByOwnerId, Token, TokenCategory } from 'resources/types';
import routes from 'resources/routes';

const GET_TOKENS_BY_ID = 'token/tokensList/id';
const GET_TOKENS_BY_CATEGORY = 'token/getTokensByCategory';
const GET_TOKEN_TRANSACTIONS = 'token/get/transactions';

const getTokensListByOwnerId = createAsyncThunk(
  GET_TOKENS_BY_ID,
  async (id: string): Promise<ApiResponseTokensByOwnerId> => {
    const response = await services.getTokensListByOwnerId(id);
    const ownerTokens = response?.data as ApiResponseTokensByOwnerId;
    return ownerTokens ?? [];
  },
);

const getTokensByCategory = createAsyncThunk(
  GET_TOKENS_BY_CATEGORY,
  async (tokenCategory?: TokenCategory): Promise<Array<Token>> => {
    const response = await services.getTokensByCategory(tokenCategory);
    const tokensList = response?.data?.data as Array<Token>;
    return tokensList ?? [];
  },
);
const getTokensByKeyword = createAsyncThunk(
  GET_TOKENS_BY_CATEGORY,
  async ({ keyword, category }: { keyword: string; category: string }): Promise<Array<Token>> => {
    const response = await services.getTokensByKeywordAndCategory(keyword, category);
    const tokensList = response?.data?.data as Array<Token>;
    return tokensList ?? [];
  },
);
const getTransactionsByCategory = createAsyncThunk(
  GET_TOKEN_TRANSACTIONS,
  async (params: { transactionCategory: HistoryVariant; tokenId: any }): Promise<any> => {
    const { transactionCategory, tokenId } = params;
    const response = await services.getTransactionsByCategory(transactionCategory, tokenId);
    return response?.data?.data;
  },
);
const getTokenDetails = createAsyncThunk(routes.tokenDeatial, async (id: any): Promise<any> => {
  const response = await services.getTokenDeatial(id);
  const tokensList = response?.data?.data as Array<Token>;
  return tokensList ?? [];
});
const getTokenInvestorsData = createAsyncThunk(routes.tokenInvestors, async (id: any): Promise<any> => {
  const response = await services.getTokenInvestors(id?.tokenId);
  const tokenInvestorsData = response?.data as Array<Token>;
  return tokenInvestorsData ?? [];
});
const PostTokenSellData = createAsyncThunk(routes.tokenSell, async (params: any): Promise<any> => {
  const response = await services.PostTokenSell(params.id, { amount: params.amount });
  const tokenSellData = response?.data as Array<Token>;
  return tokenSellData ?? [];
});
const PostTokenBuyData = createAsyncThunk(routes.tokenBuy, async (params: any): Promise<any> => {
  const response = await services.PostTokenbuy(params.id, { amount: params.amount });
  const tokenBuyData = response?.data as Array<Token>;
  return tokenBuyData ?? [];
});

export {
  getTokensByCategory,
  getTokensListByOwnerId,
  getTransactionsByCategory,
  getTokenDetails,
  getTokenInvestorsData,
  PostTokenSellData,
  PostTokenBuyData,
  getTokensByKeyword,
};
