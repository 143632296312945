import React from 'react';

const SVGRight = () => {
  return (
    <svg viewBox="0 0 44 180" className="css-qs6ru0">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0C36.8366 0 44 7.16344 44 16V70C32.9543 70 24 78.9543 24 90C24 101.046 32.9543 110 44 110V164C44 172.837 36.8366 180 28 180H2V156H0V144H2V136H0V124H2V116H0V104H2V96H0V84H2V76H0V64H2V56H0V44H2V36H0V24H2V16H0V4H2V0H28Z"></path>
    </svg>
  );
};

export default SVGRight;
