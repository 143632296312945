import moment from 'moment';

export const calculatePercentageIncrease = (x: number) =>  {
  const baseValue = 0.001;
  const percentageIncrease = ((x - baseValue) / baseValue) * 100;
  return `${percentageIncrease.toFixed(2)} %`;
}

export const getLastSeen = (timestamp: number | string) => {
  const now = moment();
  const past = moment(timestamp);
  const duration = moment.duration(now.diff(past));

  const years = duration.years();
  const months = duration.months();
  const weeks = Math.floor(duration.asWeeks());
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (years >= 1) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months >= 1) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (weeks >= 1) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (days >= 1) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours >= 1) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes >= 1) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `just now`;
  }
}

export const roundNumber = (number: number, count = 100): number => (
  Math.round(number * count) / count
);

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result as string;
      resolve(result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
