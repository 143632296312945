import { Box, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import HeaderButtons from './HeaderButtons';
import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';
import React, { useEffect } from 'react';
import { ReactComponent as SendIcon } from 'assets/icons/send-sqaure-2.svg';
import { ReactComponent as ReceiveIcon } from 'assets/icons/receive-square-2.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/Alt Arrow Right.svg';
import theme from 'resources/theme';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHistoryBalanceData } from 'store/history/thunks';

function Balance() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const HistoryState = useAppSelector((state) => state.history);
  const isHistoryEmpty = HistoryState.historyAllData.length === 0;

  useEffect(() => {
    dispatch(getHistoryBalanceData());
  }, [dispatch]);

  return (
    <Box sx={{ bgcolor: '#000', color: '#fff', minHeight: '97vh', p: 1, mt: 1 }}>
      <Header title={t('history')} path={routsPatterns.HOME} />
      <Box p={2} sx={{ height: '82vh' }}>
        <HeaderButtons />
        {isHistoryEmpty ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '90%',
            }}
          >
            <Typography sx={{ color: '#fff', fontWeight: 400, fontFamily: 'Audiowide', fontSize: '18px', marginBottom: "10px" }}>
              {t('emptyHistory')}
            </Typography>
            <Typography sx={{color: '#fff', fontWeight: 500, fontFamily: 'Montserrat', fontSize: '12px', textAlign: 'center', width: "275px"}}>
              {t('emptyHistoryDescription')}
            </Typography>
          </Box>
        ) : (
          <List>
            {HistoryState?.historyBalanceData?.data?.map((transaction: any) => (
              <React.Fragment key={transaction.id}>
                <ListItem
                  sx={{
                    backgroundColor: '#252525',
                    borderRadius: '15px',
                    marginBottom: '4px',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack>
                    <Typography sx={{ fontWeight: 500, fontSize: '12px', fontFamily: 'Montserrat', color: '#fff' }}>
                      {transaction.amount}
                    </Typography>
                    <Stack flexDirection="row" alignItems="center" gap="3px">
                      {transaction.type === 'Withdrawal' ? (
                        <SendIcon width={15} height={15} />
                      ) : (
                        <ReceiveIcon width={15} height={15} />
                      )}
                      <Typography
                        sx={{
                          color:
                            transaction.type === 'Withdrawal'
                              ? theme.palette.highlightRed.main
                              : theme.palette.success.main,
                          fontWeight: 600,
                          fontSize: '10px',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {transaction.type}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <Box>
                      <Stack alignItems="end">
                        <Typography
                          sx={{
                            color:
                              transaction.status === 'Rejected'
                                ? theme.palette.error.main
                                : transaction.status === 'Completed'
                                ? theme.palette.success.main
                                : '#F7A600',
                            fontWeight: 600,
                            fontSize: '10px',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          {transaction.status}
                        </Typography>
                      </Stack>

                      <Typography
                        sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: 'Montserrat', fontSize: '10px' }}
                      >
                        {transaction.date}
                      </Typography>
                    </Box>
                    <IconButton onClick={() => navigate(routsPatterns.TRANSACTIONS_STORY)}>
                      <ArrowRightIcon width={20} height={20} />
                    </IconButton>
                  </Stack>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
}

export default Balance;
