import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Card, CardActionArea, CardContent, Typography, Box } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { useTonConnectUI, useTonAddress, useIsConnectionRestored } from '@tonconnect/ui-react';
import { routsPatterns } from 'resources/constants';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'store';

interface SettingsCardProps {
  icon: React.ReactNode;
  title: string;
  bgColor?: string;
  size?: number;
  path?: string;
  setActiveCard: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  name?: string | null;
}

const SettingsCard: React.FC<SettingsCardProps> = ({
  icon,
  title,
  bgColor,
  size,
  path,
  setActiveCard,
  setIsOpen,
  name,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();
  const rawAddress = useTonAddress(false);
  const connectionRestored = useIsConnectionRestored();
  const [isConnected, setIsConnected] = useState(false);

  const getWalletConnectionState = () => {
    if(connectionRestored && !!rawAddress) {
      return 'disconnect'
    } else {
      return 'connect_wallet'
    }
  }

  const handleWallet = async () => {
    if (title === 'connect_wallet') {
      try {
        console.log('isConnected', isConnected);
        if (isConnected) {
          // Disconnect wallet and clear local storage
          await tonConnectUI.disconnect();
          localStorage.removeItem('tonWalletAddress');
          showMessage({
            messages: ['Disconnected successfully!'],
            options: { variant: 'success' },
          });
          setIsConnected(false);
        } else {
          // Open connection modal
          await tonConnectUI.openModal();
        }
      } catch (error) {
        showMessage({
          messages: ['Error during wallet operation!'],
          options: { variant: 'error' },
        });
      }
    } else {
      setActiveCard(title.toLowerCase().replace(/\s+/g, ''));
      navigate(path || routsPatterns.SETTINGS);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (rawAddress && connectionRestored) {
      // Save wallet address to localStorage
      localStorage.setItem('tonWalletAddress', rawAddress);
      setIsConnected(true);
    }
  }, [rawAddress, connectionRestored]);

  useEffect(() => {
    const savedAddress = localStorage.getItem('tonWalletAddress');
    if (savedAddress && !isConnected) {
      // Use the `connector` to check and handle wallet connection
      tonConnectUI.connector.restoreConnection()
        .then(() => {
          setIsConnected(true);
        })
        .catch((error) => {
          console.error('Failed to restore connection:', error);
          showMessage({
            messages: ['Failed to restore connection. Please reconnect!'],
            options: { variant: 'error' },
          });
          localStorage.removeItem('tonWalletAddress');
        });
    }
  }, [tonConnectUI.connector, isConnected]);

  return (
    <Grid size={{ xs: size || 12 }}>
      <Card
        sx={{
          bgcolor: bgColor || '#FFFFFF',
          borderRadius: '15px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <ArrowDownIcon />
        </Box>

        <CardActionArea onClick={handleWallet}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: i18n.language === 'de' ? '8px 4px' : '14px',
              gap: 1,
            }}
          >
            {icon}
            <Typography variant="body1" fontFamily="Montserrat">
              {name === 'connectWallet' ? t(`${getWalletConnectionState()}`) : t(title)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default SettingsCard;
