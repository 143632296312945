import { useEffect, useState } from 'react';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { Box, Checkbox, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n';
import { setLanguage } from 'store/languageSlice';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

interface LanguagesProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const languages = ['English', 'Русский', 'Chinese', 'Türkçe', 'Deutsch', 'Français', 'Українська'];

function Languages({ isOpen, setIsOpen }: LanguagesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state: RootState) => state.language);

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    switch (currentLanguage) {
      case 'en':
        return 'English';
      case 'ru':
        return 'Русский';
      case 'zh':
        return 'Chinese';
      case 'tr':
        return 'Türkçe';
      case 'de':
        return 'Deutsch';
      case 'fr':
        return 'Français';
      case 'uk':
        return 'Українська';
      default:
        return 'English';
    }
  });

  useEffect(() => {
    switch (currentLanguage) {
      case 'en':
        setSelectedLanguage('English');
        break;
      case 'ru':
        setSelectedLanguage('Русский');
        break;
      case 'zh':
        setSelectedLanguage('Chinese');
        break;
      case 'tr':
        setSelectedLanguage('Türkçe');
        break;
      case 'de':
        setSelectedLanguage('Deutsch');
        break;
      case 'fr':
        setSelectedLanguage('Français');
        break;
      case 'uk':
        setSelectedLanguage('Українська');
        break;
      default:
        setSelectedLanguage('English');
    }
  }, [currentLanguage]);

  const handleLanguageChange = (lang: string) => {
    let languageCode = 'en';
    if (lang === 'Русский') languageCode = 'ru';
    else if (lang === 'Chinese') languageCode = 'zh';
    else if (lang === 'Türkçe') languageCode = 'tr';
    else if (lang === 'Deutsch') languageCode = 'de';
    else if (lang === 'Français') languageCode = 'fr';
    else if (lang === 'Українська') languageCode = 'uk';

    dispatch(setLanguage(languageCode));
    i18n.changeLanguage(languageCode);
    setSelectedLanguage(lang);
  };

  const languageList = (
    <List sx={{ bgcolor: '#000' }}>
      {languages.map((language, index) => (
        <Box key={language}>
          <ListItem
            disablePadding
            sx={{
              bgcolor: '#000',
              '&.Mui-selected, &.Mui-selected:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            <ListItemButton
              onClick={() => handleLanguageChange(language)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ListItemText
                primary={language}
                primaryTypographyProps={{
                  color: selectedLanguage === language ? '#75FF47' : '#fff',
                  fontWeight: selectedLanguage === language ? 'bold' : 'normal',
                  fontFamily: 'Montserrat',
                }}
              />
              {selectedLanguage === language && (
                <Checkbox
                  checked
                  sx={{
                    color: '#75FF47',
                    '&.Mui-checked': {
                      color: '#75FF47',
                    },
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
          {index < languages.length - 1 && <Divider sx={{ bgcolor: '#333' }} />}
        </Box>
      ))}
    </List>
  );

  return (
    <BottomDrawerModal isOpen={isOpen} setIsOpen={setIsOpen} title={t('select_language')}>
      {languageList}
    </BottomDrawerModal>
  );
}

export default Languages;
