import { batch } from 'react-redux';
import { MouseEvent, useEffect, useState } from 'react';
import ListItem from 'components/main/ListItem';
import Grid from '@mui/material/Grid2';
import { Theme, Divider, Typography, Button, IconButton, Box, sliderClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { changeTheme } from 'store/app';


import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as StabilityIcon } from 'assets/icons/stability.svg';
import { ReactComponent as TrendingIcon } from 'assets/icons/trending.svg';
import { ReactComponent as NewIcon } from 'assets/icons/star.svg';
import { ReactComponent as StoryIcon } from 'assets/icons/story.svg';

import SlideShow from 'pages/Home/SlideShow';
import BalanceActionModal from './BottomDrawerModal/BalanceActionModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { routsPatterns } from 'resources/constants';
import { getTokens } from 'store/home';
import { useAppDispatch, useAppSelector } from 'hooks';
import i18n from 'i18n';
import { getSliderData } from 'store/slider/thunks';
import { RootState } from 'store';
import { TokenCategory } from 'resources/types';
import { getTokensByCategory } from 'store/token';
import { LoadingItem } from 'pages/Token/TokenContent';
import { calculatePercentageIncrease, getLastSeen, roundNumber } from 'utils';
import commaNumber from 'comma-number';
import { environment } from 'resources/constants';
import { instance } from 'services/instance';
import routes from 'resources/routes';
import { saveLoginData } from 'store/login';

const { AWS_S3_BUCKET_URL, AWS_S3_IMAGE_FOLDER_PATH } = environment;

const fakeSliderData = {
  data: {
    data: {
      imagesDataValues: [
        { id: '1', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/1.webp' },
        { id: '2', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/2.webp' },
        { id: '3', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/3.webp' },
        { id: '4', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/4.webp' },
        { id: '5', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/Roulette.gif' },
      ],
      nfts: [
        { id: '1', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/1.webp' },
        { id: '2', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/2.webp' },
      ],
      transactions: [
        { id: '1', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/1.webp' },
        { id: '2', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/2.webp' },
        { id: '3', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/3.webp' },
        { id: '4', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/4.webp' },
        { id: '5', imageUrl: 'https://reboot-bucket.s3.eu-north-1.amazonaws.com/prod/images/slides/2.webp' },
      ],
    },
  },
};


const transactionButtons = [
  {
    text: 'stability',
    icon: (color: string) => <StabilityIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'my',
    icon: (color: string) => <StoryIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'trending',
    icon: (color: string) => <TrendingIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'new',
    icon: (color: string) => <NewIcon stroke={color} height={18} width={18} />,
  },
];

function Home() {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const currentPrimaryColor = currentTheme.palette.primary.main;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homeState = useAppSelector((state) => state.home);
  const userLoginInfo = useAppSelector((state) => state.login);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const params = new URLSearchParams(location.search);
  const accessToken = params.get('accessToken');
  const refreshToken = params.get('refreshToken');
  const { pathname } = useLocation();
  useEffect(() => {
    try {
      const getData = async () => {
        const { data } = await instance.get(routes.getUserData);
        dispatch(saveLoginData(data.data));
      };
      if (!accessToken && !refreshToken) {
        getData();
      }
    } catch (error) {
      console.log(error);
    }
  }, [pathname]);


  const sliderState = useAppSelector((state) => state.slider);
  console.log("sliderState", sliderState)
  const tokenInfo = useAppSelector((state: RootState) => state.tokenInfo);
  const { tokens, tokensLoading } = tokenInfo;
  const [selectedTokenCategory, setSelectedTokenCategory] = useState<TokenCategory>('stability');

  useEffect(() => {
    dispatch(getSliderData());
    dispatch(getTokensByCategory(selectedTokenCategory));
    const loggedIn = localStorage.getItem('isLoggedIn');
    const isLoggedIn = loggedIn === 'true';
    setIsLoggedIn(isLoggedIn);
    if (!isLoggedIn) {
      navigate(routsPatterns.LOGIN);
    }

    // if ((window as any).Telegram?.WebApp) {
    //   (window as any).Telegram.WebApp.expend();
    // }
  }, [dispatch, selectedTokenCategory]);

  const selectTokenCategory = (event: MouseEvent<HTMLButtonElement>) => {
    const selectedCategory = event.currentTarget.name as TokenCategory;
    setSelectedTokenCategory(selectedCategory);
  };

  return (
    <Grid p={2} sx={{ maxHeight: 'calc(100vh - 135px)', overflow: 'auto',}}>
      <Grid
        container
        sx={(theme: Theme) => ({
          height: '90px',
          maxHeight: '90px',
          width: 'inherit',
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '18px',
          border: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
        })}>
        <Grid container size={{ xs: 7 }} display="flex" justifyContent="space-between" alignItems="center" position="relative">
          <Grid
            container
            sx={{
              height: 'fit-content',
              flex: 1,
              pr: 1,
            }}
            p={1}
            spacing={0.5}>
            <Grid container display="flex" justifyContent="space-between" width="100%">
              <Typography color="white" variant="subtitle3" px={1} fontFamily="Audiowide" fontWeight="400" fontSize="18px">
                {t('balance')}
              </Typography>
            </Grid>
            <Typography
              sx={(theme: Theme) => ({
                background: theme.palette.gradient.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              })}
              variant="subtitle2"
              fontFamily="Montserrat"
              px={1}>
              {userLoginInfo?.balance} TON
            </Typography>
          </Grid>
          <Box position="absolute" display="flex" justifyContent="end" top={5} right={5}>
            <IconButton onClick={() => navigate(routsPatterns.STORY)}>
              <ClockIcon />
            </IconButton>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{
              borderColor: '#FFFFFF66',
              borderWidth: '1px',
            }}
          />
        </Grid>
        <Grid
          container
          size={{ xs: 5 }}
          sx={{
            display: 'flex',
            height: 'inherit',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Grid
            container
            sx={(theme: Theme) => ({
              borderRadius: '12px',
              height: '74px',
              width: '130px',
              backgroundColor: theme.palette.secondary.light,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            })}
            spacing={0.3}>
            <BalanceActionModal />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          minHeight: '240px',
          width: 'inherit',
          marginTop: '14px',
        }}>
        <SlideShow sliderState={sliderState} />
      </Grid>

      <Grid
        container
        sx={(theme: Theme) => ({
          height: '27px',
          maxHeight: '27px',
          width: 'inherit',
          margin: '8px  0 8px 0',
        })}
        spacing={0.4}>
        {transactionButtons.map((item) => (
          <Grid container key={item.text} size={{ xs: 3 }}>
            <Button
              variant="contained"
              onClick={selectTokenCategory}
              name={item.text}
              startIcon={item.icon(
                selectedTokenCategory === item.text
                  ? currentTheme.palette.secondary.dark
                  : currentTheme.palette.primary.main,
              )}
              color="black"
              sx={(theme: Theme) => ({
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                border: '1.5px',
                'span > svg *': {
                  fill: { currentPrimaryColor },
                  stroke: { currentPrimaryColor },
                },
                ...(selectedTokenCategory === item.text && {
                  color: theme.palette.secondary.dark,
                  background: theme.palette.gradient.primaryVertical,
                }),
              })}>
              <Typography
                variant="subtitle2"
                fontSize={i18n.language === 'ru' || i18n.language === 'uk' ? '7px' : '10px'}
                lineHeight="12.19px"
                fontFamily= 'Audiowide'
                fontWeight='400'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {t(`${item.text}`)}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>

      <Grid>
        {tokensLoading
          ? Array(20)
              .fill('')
              .map(() => <LoadingItem />)
          : tokens?.map((token, index) => {
              const { shortName, createdAt, imageUrl, circulatingSupply, currentPrice, startAt } = token;
              const imageSrc = `https://reboot-bucket.s3.eu-north-1.amazonaws.com/${imageUrl}`;
              return (
                <ListItem
                  name={shortName}
                  src={imageSrc ?? ''}
                  lastSeen={getLastSeen(createdAt)}
                  mc={`MC $${commaNumber(roundNumber(circulatingSupply * currentPrice))}`}
                  percent={`${token.changed24Hours} %`}
                  startAt={startAt}
                  selectedTokenCategory={selectedTokenCategory}
                  onClick={() => navigate(`/tokens/view/${token.id}`)}
                />
              );
            })}
      </Grid>
    </Grid>
  );
}

export default Home;
