import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { FilterButton, FilterButtonsProps } from 'resources/types/filterButtons';

const FilterButtons: React.FC<FilterButtonsProps> = ({ buttons, onApplyFilters, clearOnSelect = false, setSelectedButton }) => {
  const currentTheme = useTheme();
  const currentPrimaryColor = currentTheme.palette.primary.main;
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const { t, i18n } = useTranslation();

  const updateFilters = (newFilters: Record<string, any> = {}) => {
    const queryParams: Record<string, any> = {
      ...newFilters,
      // ...(sortBy && sortOrder && { sort_by: sortBy, sort_order: sortOrder }),
      limit: 20,
      offset: 10,
    };

    onApplyFilters(queryParams);
  };

  const handleFilterChange = (field: string, value: any) => {
    const updatedFilters = clearOnSelect
      ? { [`${field}`]: value }
      : { ...filters, [`${field}`]: value };

    setFilters(updatedFilters);
    updateFilters(updatedFilters);
    setSortBy(null);
    setSortOrder(null);
  };

  const handleSortChange = (field: string, order: 'asc' | 'desc') => {
    setSortBy(field);
    setSortOrder(order);

    if (clearOnSelect) {
      setFilters({});
      updateFilters({ sort_by: field, sort_order: order });
    } else {
      updateFilters(filters);
    }
  };

  const handleButtonClick = (button: FilterButton) => {
    if (button.type === 'filter') {
      const { field, value }: any = button;
      handleFilterChange(field, value);
    } else if (button.type === 'sort') {
      const { field, order }: any = button;
      handleSortChange(field, order);
    }
    setSelectedButton(button.text);
  };

  return (
    <Grid container spacing={1} sx={{ mt: '12px', mb: '12px' }} >
      {buttons.map((button) => {
        let isActive = false;

        if (button.type === 'filter') {
          isActive = filters[`${button.field}`];
        } else if (button.type === 'sort') {
          isActive = sortBy === button.field && sortOrder === button.order;
        }

        return (
          <Grid item xs={12 / buttons.length}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick(button)}
              startIcon={button.icon(isActive ? '#fff' : currentPrimaryColor)}
              sx={{
                width: '100%',
                borderRadius: '10px',
                border: '1.5px solid transparent',
                padding: '6px',
                backgroundColor: isActive ? currentPrimaryColor : '#FFFFFF26',
                color: isActive ? '#070707' : '#FFFFFF',
                'span > svg *': {
                  stroke: isActive ? '#070707' : currentPrimaryColor,
                },
              }}
            >
              <Typography
                variant="subtitle2"
                fontSize={i18n.language === 'ru' || i18n.language === 'uk' ? '7px' : '10px'}
                lineHeight="12.75px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: "Audiowide"
                }}
              >
                {t(`${button.text}`)}
              </Typography>
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FilterButtons;
