import { Box, Button, Card, CardContent, Stack, TextField, Theme, Typography } from '@mui/material';
import Header from 'pages/Settings/Header';
import { useTranslation } from 'react-i18next';
import { routsPatterns } from 'resources/constants';
import { ReactComponent as SendIcon } from 'assets/icons/send-sqaure-2.svg';
import { ReactComponent as ReceiveIcon } from 'assets/icons/receive-square-2.svg';
import theme from 'resources/theme';
import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { useState } from 'react';

const balanceData = {
  id: 1,
  amount: '2.43004302 TON',
  type: 'Withdrawal',
  status: 'Pending',
  statusColor: 'orange',
  typeColor: 'red',
  date: '27/11/2024 15:43',
};

function TransactionsStory() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const reportProblemModalContent = (
    <Box>
      <Typography
        sx={{ mb: 1, mt: 3, textAlign: 'start', fontSize: '12px', fontWeight: 400, fontFamily: 'Montserrat' }}
      >
        {t('text_appeal')}{' '}
      </Typography>
      <TextField
        placeholder={t('appeal')}
        variant="outlined"
        fullWidth
        multiline
        rows={2}
        sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', mb: 5 }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={(theme: Theme) => ({
          background: theme.palette.highlightRed.main,
          color: '#fff',
          fontSize: '14px',
          fontWeight: 'bold',
          borderRadius: '12px',
          textTransform: 'none',
          border: '2px solid #FFFFFF80',
        })}
      >
        {t('send')}
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        bgcolor: '#000',
        color: '#fff',
        minHeight: '97vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 2,
      }}
    >
      <Box>
        <Header title={t('transaction_history')} path={routsPatterns.BALANCE} />
        <Card
          sx={{
            bgcolor: '#1c1c1c',
            color: '#fff',
            borderRadius: "15px",
            p: 1,
            mt: 3,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '14px', fontFamily: 'Montserrat' }}>
                {t('amount')}
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '14px', fontFamily: 'Montserrat' }}>
                {balanceData.amount}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '14px', fontFamily: 'Montserrat' }}>
                {t('action')}
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap="3px">
                {balanceData.type === 'Withdrawal' ? (
                  <SendIcon width={15} height={15} />
                ) : (
                  <ReceiveIcon width={15} height={15} />
                )}
                <Typography
                  sx={{
                    color:
                      balanceData.type === 'Withdrawal' ? theme.palette.highlightRed.main : theme.palette.success.main,
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {balanceData.type}
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '14px', fontFamily: 'Montserrat' }}>
                {t('status')}
              </Typography>
              <Typography
                sx={{
                  color:
                    balanceData.status === 'Rejected'
                      ? theme.palette.error.main
                      : balanceData.status === 'Completed'
                        ? theme.palette.success.main
                        : '#F7A600',
                  fontWeight: 600,
                  fontSize: '14px',
                  fontFamily: 'Montserrat',
                }}
              >
                {balanceData.status}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '14px', fontFamily: 'Montserrat' }}>{t('date')}</Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '14px', fontFamily: 'Montserrat' }}>
                {balanceData.date}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          sx={(theme: Theme) => ({
            color: '#fff',
            background: theme.palette.gradient.primaryVertical,
            border: '2px solid #FFFFFF80',
            textTransform: 'none',
            mb: 1,
            width: '100%',
            borderRadius: '12px',
            fontWeight: 700, 
            fontSize: '14px'
          })}
        >
          {t('repeat_transaction')}
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#FFFFFF33',
            border: '2px solid #FFFFFF80',
            color: '#fff',
            textTransform: 'none',
            width: '100%',
            borderRadius: '12px',
            fontWeight: 700, 
            fontSize: '14px'
          }}
          onClick={() => setIsOpen(true)}
        >
          {t('report_problem')}
        </Button>
        <BottomDrawerModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={t('report_problem')}
          boxShadow={theme.palette.highlightRed.main}
        >
          {reportProblemModalContent}
        </BottomDrawerModal>
      </Box>
    </Box>
  );
}

export default TransactionsStory;
