import { useState, useEffect } from 'react';
import moment from 'moment';
import { Typography, Box, Stack, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavLink, useNavigate } from 'react-router-dom';
import { routsPatterns } from 'resources/constants';
import { TokenCategory } from 'resources/types';
import { useTranslation } from 'react-i18next';
import { formatTimeLeft, TimeLeft } from 'utils/formatTime';

export interface ListItemProps {
  title?: string;
  duration?: string;
  name: string;
  src: string;
  lastSeen: string;
  mc: string;
  percent: string;
  closed?: boolean;
  startAt: number;
  selectedTokenCategory?: TokenCategory;
  onClick?: () => void;
  navLink?: string;
  linkName?: string;
}

function ListItem(props: ListItemProps) {
  const currentTheme = useTheme();
  const currentPrimaryColor = currentTheme.palette.primary.main;

  const { t } = useTranslation();
  const { src, name, lastSeen, mc, percent, startAt, navLink, linkName, selectedTokenCategory, onClick } = props;
  const navigate = useNavigate();

  const currentTime = moment.utc().unix();;
  const timeLeftDate = startAt - currentTime;
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(formatTimeLeft(timeLeftDate));
  const isStability = selectedTokenCategory === 'stability';

  useEffect(() => {
    if (timeLeftDate <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft: TimeLeft) => {
        const remainingSeconds =
          prevTimeLeft.days * 86400 + prevTimeLeft.hours * 3600 + prevTimeLeft.minutes * 60 + prevTimeLeft.seconds - 1;

        return formatTimeLeft(remainingSeconds);
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeftDate]);

  return (
    <Box
      sx={(theme) => ({
        mt: 0.5,
        borderRadius: '15px',
        bgcolor: theme.palette.secondary.main,
        padding: `${startAt ? '4px 6px 6px 6px' : '6px'}`,
        display: 'flex',
        flexDirection: 'column',
      })}
      onClick={onClick}
    >
      {timeLeftDate > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px 0 5px',
          }}
        >
          <Typography color={currentPrimaryColor} fontSize="12px" fontWeight={400} fontFamily="Audiowide">
            {t('closedForSale')}
          </Typography>
          {isStability && (
            <Typography color={currentPrimaryColor}fontSize="12px" fontWeight={500} fontFamily="Montserrat">
              {`${timeLeft.days} ${t('days')} ${timeLeft.hours} ${t('h')} ${timeLeft.minutes} ${t('m')} ${
                timeLeft.seconds
              } ${t('s')}`}
            </Typography>
          )}
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" alignItems="center">
          <img src={src} alt={name} width={40} height={40} style={{ borderRadius: '50%', marginRight: '12px' }} />
          <Box>
            <Typography color="white" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
              {name}
            </Typography>
            <Typography color="primary" fontSize="10px" fontWeight={500} fontFamily="Montserrat">
              {lastSeen}
              {navLink && (
                <NavLink
                  to={navLink}
                  onClick={(event) => event.stopPropagation()}
                  style={{
                    textDecoration: 'none',
                    marginLeft: '8px',
                    color: 'inherit',
                  }}
                >
                  <Typography color="primary" variant="caption">
                    {linkName}
                  </Typography>
                </NavLink>
              )}
            </Typography>
          </Box>
        </Box>

        <Stack flexDirection="row" textAlign="right" alignItems="center" gap={1}>
          <Box>
            <Typography color="white" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
              {mc}
            </Typography>
            <Typography color="primary" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
              {percent}
            </Typography>
          </Box>
          <IconButton onClick={onClick}> <ArrowForwardIosIcon sx={{ fontSize: 14}} color='white' /></IconButton>
        </Stack>
      </Box>
    </Box>
  );
}

export default ListItem;
