import services from 'services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import routes from 'resources/routes';

const getColors = createAsyncThunk(
  routes.getColors,
  async (): Promise<Array<any>> => {
    const response = await services.getColors();
    return response.data;
  },
);

const getUserColors = createAsyncThunk(
  routes.getUserColors,
  async (): Promise<any> => {
    const response = await services.getUserColors();
    return response.data;
  },
);

const chooseColor = createAsyncThunk(
  routes.chooseColor,
  async (payload: any): Promise<any> => {
    const response = await services.chooseColor(payload);
    return response.data;
  },
);

const buyColor = createAsyncThunk(
  routes.buyColor,
  async (payload: any): Promise<any> => {
    const response = await services.buyColor(payload);
    return response.data;
  },
);

export { getColors, getUserColors, chooseColor, buyColor };
