import { instance } from './instance';
import type { GetTokenListResponse } from 'resources/types';
import routes from 'resources/routes';

interface createTransactionBody {
  amount: string,
  transactionId: string
}

const createTransaction = (transactionData: createTransactionBody): Promise<void> => {
  return instance.post(routes.createTransaction, {
    amount: transactionData.amount,
    transactionId: transactionData.transactionId
  });
};

const createWithdraw = (transactionData: createTransactionBody): Promise<void> => {
  return instance.post(routes.withdraw, {
    amount: transactionData.amount,
  });
};

export default {
  createTransaction,
  createWithdraw
};
