import { createSlice } from '@reduxjs/toolkit';
import type { Investment, TokensByOwnerIdDataItem, UserInfo, Token } from 'resources/types';
import {
  getTokensListByOwnerId,
  getTokensByCategory,
  getTokenDetails,
  getTransactionsByCategory,
  getTokenInvestorsData,
  PostTokenSellData,
  PostTokenBuyData,
  
} from './thunks';

export interface OwnerTokensState {
  tokenList: Array<TokensByOwnerIdDataItem> | [];
  userInfo: UserInfo | null;
  investments: Array<Investment>;
  loading: boolean;
  transactions: any;
  tokens: Array<Token>;
  tokensLoading: boolean;
  tokenDeatialData: any;
  tokenInvestorsData: any;
  tokenSell: any;
  tokenBuy: any;
}

const initialState: OwnerTokensState = {
  tokenList: [],
  userInfo: null,
  loading: false,
  investments: [],
  transactions: {
    data: [],
    transactionsLoading: false,
  },
  tokens: [],
  tokensLoading: false,
  tokenDeatialData: [],
  tokenInvestorsData: [],
  tokenSell: [],
  tokenBuy: [],
};

/* eslint-disable no-param-reassign */

const tokenSlice = createSlice({
  name: 'tokenInfo',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getTokensListByOwnerId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTokensListByOwnerId.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTokensListByOwnerId.fulfilled, (state, action) => {
      state.loading = false;
      state.tokenList = action.payload.data.tokenList;
      state.userInfo = action.payload.data.userInfo;
      state.investments = action.payload.data.investments;
    });

    builder.addCase(getTokensByCategory.pending, (state) => {
      state.tokensLoading = true;
    });
    builder.addCase(getTokensByCategory.rejected, (state) => {
      state.tokens = [];
      state.tokensLoading = false;
    });
    builder.addCase(getTokensByCategory.fulfilled, (state, action) => {
      state.tokens = action.payload;
      state.tokensLoading = false;
    });

    builder.addCase(getTransactionsByCategory.pending, (state) => {
      state.transactions.loading = true;
    });
    builder.addCase(getTransactionsByCategory.rejected, (state) => {
      state.transactions.data = [];
      state.transactions.loading = false;
    });
    builder.addCase(getTransactionsByCategory.fulfilled, (state, action) => {
      state.transactions.data = action.payload;
      state.transactions.loading = false;
    });

    builder.addCase(getTokenDetails.pending, (state) => {
      state.tokensLoading = true;
    });
    builder.addCase(getTokenDetails.rejected, (state) => {
      state.tokenDeatialData = [];
      state.tokensLoading = false;
    });
    builder.addCase(getTokenDetails.fulfilled, (state, action) => {
      state.tokenDeatialData = action.payload;
      state.tokensLoading = false;
    });
    builder.addCase(getTokenInvestorsData.pending, (state) => {
      state.tokensLoading = true;
    });
    builder.addCase(getTokenInvestorsData.rejected, (state) => {
      state.tokenInvestorsData = [];
      state.tokensLoading = false;
    });
    builder.addCase(getTokenInvestorsData.fulfilled, (state, action) => {
      state.tokenInvestorsData = action.payload;
      state.tokensLoading = false;
    });
    builder.addCase(PostTokenSellData.pending, (state) => {
      state.tokensLoading = true;
    });
    builder.addCase(PostTokenSellData.rejected, (state) => {
      state.tokenSell = [];
      state.tokensLoading = false;
    });
    builder.addCase(PostTokenSellData.fulfilled, (state, action) => {
      state.tokenSell = action.payload;
      state.tokensLoading = false;
    });
    builder.addCase(PostTokenBuyData.pending, (state) => {
      state.tokensLoading = true;
    });
    builder.addCase(PostTokenBuyData.rejected, (state) => {
      state.tokenBuy = [];
      state.tokensLoading = false;
    });
    builder.addCase(PostTokenBuyData.fulfilled, (state, action) => {
      state.tokenBuy = action.payload;
      state.tokensLoading = false;
    });
  },
});

const { reset } = tokenSlice.actions;

export { reset, getTokensListByOwnerId, getTokensByCategory, getTokenDetails, PostTokenSellData, PostTokenBuyData };

export default tokenSlice;
