import React, { createContext, useState, useMemo, ReactNode, FC, ReactElement } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themes, ThemeNames } from 'resources/theme';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import useTheme from 'hooks/useTheme';

function ThemeAppProvider(props: { children: ReactElement }) {
  const { children } = props;
  const currentTheme = useTheme();
  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}

export default ThemeAppProvider;
