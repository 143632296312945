import { Box, TextField } from '@mui/material';
import { t } from 'i18next';
// import QRCode from 'qrcode.react';

interface QRCodeSectionProps {
  qrValue: string;
  onSave: () => void;
  onShare: () => void;
}

function QRCodeSection({ qrValue, onSave, onShare }: QRCodeSectionProps) {
  return (
    <Box sx={{ mt: 3 }}>
      <TextField
        fullWidth
        label={t('address_ton')}
        // value={addressValue}
        sx={{
          mb: 2,
          '& .MuiInputBase-root': {
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
          },
        }}
      />

      <TextField
        fullWidth
        label={t('memo')}
        // value={memoValue}
        helperText="Required for fund security"
        sx={{
          mb: 3,
          '& .MuiInputBase-root': {
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
          },
        }}
      />

      {/* QR Code */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 3,
        }}
      >
        {/* <Box
          sx={{
            display: 'inline-block',
            padding: '16px',
            backgroundColor: '#fff',
            borderRadius: '12px',
          }}
        > */}
        {/* <QRCode value={qrValue} size={128} /> */}
        {/* </Box> */}
      </Box>
    </Box>
  );
}

export default QRCodeSection;
