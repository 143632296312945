import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ReactComponent as BackArrowIcon } from 'assets/icons/Icon.svg';
import { useNavigate } from 'react-router-dom';

interface BackArrowButtonProps {
  path: string;
}

function BackArrowButton({ path }: BackArrowButtonProps) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        background: '#FFFFFF26',
        width: '60px',
        height: '36px',
        borderRadius: '0 19px 19px 0',
        position: 'relative',
        left: '-16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <IconButton onClick={() => navigate(path)} sx={{ p: 0, pl: 2 }}>
        <BackArrowIcon width={20} height={20} />
      </IconButton>
    </Grid>
  );
}

export default BackArrowButton;
