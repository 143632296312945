import { Provider } from 'react-redux';
import i18n from 'i18n';
import { I18nextProvider } from 'react-i18next';
import Router from 'components/main/Router';
import store from 'store';
import NotifierProvider from 'components/main/NotifierProvider';
import './App.css';
import ThemeContextProvider from 'components/main/ThemeContextProvider';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import RestoreUser from 'components/providers/RestoreUser';

function App() {
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);

  document.addEventListener('gesturestart', (event) => event.preventDefault());
  document.addEventListener('gesturechange', (event) => event.preventDefault());
  document.addEventListener('gestureend', (event) => event.preventDefault());

  return (
    <Provider store={store}>
      <RestoreUser>
          <ThemeContextProvider>
            <I18nextProvider i18n={i18n}>
              <NotifierProvider>
                <Router />
              </NotifierProvider>
            </I18nextProvider>
          </ThemeContextProvider>
      </RestoreUser>
    </Provider>
  );
}

export default App;
