import { useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

export interface NumberSelectionProps {
  value: string | null;
  setValue: (value: string | null) => void;
}

function NumberSelection(props: NumberSelectionProps) {
  const { value, setValue } = props;

  const handleSelection = (newValue: string | null) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        padding: '16px 0px',
        borderRadius: '12px',
      }}
    >
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={(_, newValue) => handleSelection(newValue)}
        sx={{
          gap: '8px',
          width: '100%',
          '& .MuiToggleButton-root': {
            borderRadius: '12px',
            backgroundColor: '#333',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '10px 16px',
            '&.Mui-selected': {
              color: '#fff',
              border: '2px solid #75FF47',
              backgroundColor: '#333',
            },
          },
        }}
      >
        <ToggleButton value="1" sx={{ width: '100%' }}>
          1
        </ToggleButton>
        <ToggleButton value="10" sx={{ width: '100%' }}>
          10
        </ToggleButton>
        <ToggleButton value="20" sx={{ width: '100%' }}>
          20
        </ToggleButton>
        <ToggleButton value="100" sx={{ width: '100%' }}>
          100
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default NumberSelection;
