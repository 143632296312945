import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface RuleSectionProps {
  title?: string;
  description?: string;
  lastSection?: boolean;
}

function RuleSection({ title, description, lastSection }: RuleSectionProps) {
  const { t } = useTranslation();

  return (
    <Typography
      fontSize="12px"
      fontWeight={500}
      mt={1}
      lineHeight="16px"
      fontFamily="Montserrat"
      mb={lastSection ? 3 : 0}
    >
      {title && (
        <Typography component="span" fontSize="12px" fontWeight={600}>
          {title}
        </Typography>
      )}{' '}
      {description}
    </Typography>
  );
}

export default RuleSection;
