
import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'hooks';
import { RootState } from 'store';
import { themes } from 'resources/theme';
import { publicThemesColors, privateThemesColors } from 'resources/constants';

function useTheme() {
  const { themeName } = useAppSelector((state: RootState) => state.app);
  switch(themeName) {
    case publicThemesColors.lightGreenTheme:
      return themes.lightGreenTheme;
    case publicThemesColors.softCyanTheme:
      return themes.softCyanTheme;
    case publicThemesColors.vividMagentaTheme:
      return themes.vividMagentaTheme;
    case publicThemesColors.lightOrangeTheme:
      return themes.lightOrangeTheme;
    case publicThemesColors.vividRedTheme:
      return themes.vividRedTheme;
    case publicThemesColors.veryDarkGrayTheme:
      return themes.veryDarkGrayTheme;
    case publicThemesColors.lightGrayishMagentaTheme:
      return themes.lightGrayishMagentaTheme;
    case publicThemesColors.softPinkTheme:
      return themes.softPinkTheme;
    case privateThemesColors.darkModerateYellowTheme:
      return themes.darkModerateYellowTheme;
    case privateThemesColors.darkGrayishBlueTheme:
      return themes.darkGrayishBlueTheme;
    case privateThemesColors.slightlyDesaturatedGreenTheme:
      return themes.slightlyDesaturatedGreenTheme;
    case privateThemesColors.verySoftCyanTheme:
      return themes.verySoftCyanTheme;
    case privateThemesColors.vividOrangeTheme:
      return themes.vividOrangeTheme;
    case privateThemesColors.slightlyDesaturatedCyan:
      return themes.slightlyDesaturatedCyan;
    case privateThemesColors.darkCyanTheme:
      return themes.darkCyanTheme;
    case privateThemesColors.vividGoldTheme:
      return themes.vividGoldTheme;
    default:
      return themes.lightGreenTheme
  }
}

export default useTheme;
