import { beginCell } from '@ton/ton';
import { environment } from 'resources/constants';
import { useCallback, useEffect, useState } from 'react';
import services from 'services';
import { CandlestickData, UTCTimestamp } from 'lightweight-charts';

function useTokenDetails(tokenId: string) {
  const [tokenDetails, setTokenDetails] = useState<any>({});

  // Memoize the fetch function using useCallback
  const fetchData = async () => {
    try {
      const data = await services.getTokenDeatial(tokenId);
      setTokenDetails(data?.data?.data);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [tokenId]); // Dependency array only includes fetchData

  return { tokenDetails } as const;
}

export default useTokenDetails;
