import Grid from '@mui/material/Grid2';
import { TextField, Box, Button, Theme, Typography, InputAdornment, Skeleton } from '@mui/material';
import { ReactComponent as StabilityIcon } from 'assets/icons/stability.svg';
import { ReactComponent as TrendingIcon } from 'assets/icons/trending.svg';
import { ReactComponent as NewIcon } from 'assets/icons/star.svg';
import { ReactComponent as StoryIcon } from 'assets/icons/story.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TokenItem from './TokenItem';
import i18n from 'i18n';
import { useAppSelector, useAppDispatch } from 'hooks';
import { RootState } from 'store';
import { getTokensByCategory } from 'store/token';
import { MouseEvent, useEffect, useState } from 'react';
import { TokenCategory } from 'resources/types';
import { useLocation } from 'react-router-dom';
import { getTokensByKeyword } from 'store/token/thunks';
import { useDebounce } from 'use-debounce';

const transactionButtons: Array<{ text: TokenCategory; icon: (color: string) => JSX.Element }> = [
  {
    text: 'stability',
    icon: (color: string) => <StabilityIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'my',
    icon: (color: string) => <StoryIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'trending',
    icon: (color: string) => <TrendingIcon stroke={color} height={18} width={18} />,
  },
  {
    text: 'new',
    icon: (color: string) => <NewIcon stroke={color} height={18} width={18} />,
  },
];

export function LoadingItem() {
  return (
    <Box
      sx={(theme) => ({
        mt: 0.5,
        borderRadius: '15px',
        bgcolor: theme.palette.secondary.main,
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      })}
    >
      <Grid container>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={(theme: Theme) => ({ bgcolor: theme.palette.grey[600] })}
        />
      </Grid>
      <Grid container sx={{ flex: '1' }} justifyContent="space-around" alignItems="center">
        <Grid size={4}>
          <Skeleton
            variant="rounded"
            width="100%"
            height={10}
            sx={(theme: Theme) => ({ bgcolor: theme.palette.grey[600] })}
          />
        </Grid>
        <Grid size={4}>
          <Skeleton
            variant="rounded"
            width="100%"
            height={10}
            sx={(theme: Theme) => ({ bgcolor: theme.palette.grey[600] })}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function TokenContent() {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const dispatch = useAppDispatch();
  const tokenInfo = useAppSelector((state: RootState) => state.tokenInfo);
  const { state } = useLocation();
  const defaultCategory = state?.category ?? 'stability';
  const { tokens, tokensLoading } = tokenInfo;
  const [search, setSearch] = useState('');
  const [value] = useDebounce(search, 400);
  const [selectedTokenCategory, setSelectedTokenCategory] = useState<TokenCategory>(defaultCategory);

  useEffect(() => {
    dispatch(getTokensByCategory(selectedTokenCategory));
  }, [selectedTokenCategory]);
  useEffect(() => {
    if (value.length) {
      dispatch(getTokensByKeyword({ keyword: value, category: selectedTokenCategory }));
    } else {
      dispatch(getTokensByCategory(selectedTokenCategory));
    }
  }, [value]);

  const selectTokenCategory = (event: MouseEvent<HTMLButtonElement>) => {
    const selectedCategory = event.currentTarget.name as TokenCategory;
    setSelectedTokenCategory(selectedCategory);
    setSearch('');
  };

  const currentPrimaryColor = currentTheme.palette.primary.main;

  return (
    <Grid>
      <Grid>
        <Box width="fullWidth">
          <TextField
            fullWidth
            placeholder="Search"
            id="fullWidth"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <Button
                    variant="contained"
                    sx={(theme: Theme) => ({
                      borderRadius: '12px',
                      border: '1px solid rgba(255, 255, 255, 0.5)',
                      background: theme.palette.gradient.primaryVertical,
                    })}
                  >
                    {t('search')}
                  </Button> */}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <Grid
        container
        sx={(theme: Theme) => ({
          height: '27px',
          maxHeight: '27px',
          width: 'inherit',
          margin: '8px  0 8px 0',
          // backgroundColor: theme.palette.secondary.main,
          // margin: '23px 0 23px 0',
        })}
        spacing={0.4}
      >
        {/* TO DO */}
        {transactionButtons.map((item) => (
          <Grid container key={item.text} size={{ xs: 3 }}>
            <Button
              variant="contained"
              onClick={selectTokenCategory}
              name={item.text}
              startIcon={item.icon(
                selectedTokenCategory === item.text
                  ? currentTheme.palette.secondary.dark
                  : currentTheme.palette.primary.main,
              )}
              color="black"
              sx={(theme: Theme) => ({
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                border: '1.5px',
                'span > svg *': {
                  fill: { currentPrimaryColor },
                  stroke: { currentPrimaryColor },
                },
                ...(selectedTokenCategory === item.text && {
                  color: theme.palette.secondary.dark,
                  background: theme.palette.gradient.primaryVertical,
                }),
              })}
            >
              <Typography
                variant="subtitle2"
                fontSize={i18n.language === 'ru' || i18n.language === 'uk' ? '7px' : '10px'}
                lineHeight="12.19px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t(`${item.text}`)}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid
        sx={{
          maxHeight: 'calc(100vh - 290px)',
          overflow: 'scroll',
          paddingBottom: '25px',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {tokensLoading
          ? Array(6)
              .fill('')
              .map(() => <LoadingItem />)
          : tokens.length && tokens?.map((item) => <TokenItem token={item} selectedTokenCategory={selectedTokenCategory}/>)}
      </Grid>
    </Grid>
  );
}

export default TokenContent;
