import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
interface LoginState {
  id: number | null;
  colorId: number | null;
  colorName: string | null;
  isFirstLogin: boolean;
  balance: string | null;
}

const initialState: LoginState = {
  id: null,
  colorId: null,
  colorName: null,
  isFirstLogin: false,
  balance: null,
};
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    saveLoginData: (
      state,
      action: PayloadAction<{
        id: number;
        colorId: number;
        colorName: string;
        isFirstLogin: boolean;
        balance: string;
      }>,
    ) => {
      state.id = action.payload.id;
      state.colorId = action.payload.colorId;
      state.colorName = action.payload.colorName;
      state.isFirstLogin = action.payload.isFirstLogin;
      state.balance = action.payload.balance;
    },
    resetLoginData: () => initialState,
  },
});

export const { saveLoginData, resetLoginData } = loginSlice.actions;
export default loginSlice;
const selectSelf = (state: any) => state.login as LoginState;

export const selectUserData = createSelector(selectSelf, (state) => state);
