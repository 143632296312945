import services from 'services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import routes from 'resources/routes';
import { BuyNftPayload, BuyNftResponse, SellNftPayload, SellNftResponse } from '../../services/interface/nft';


const getNFTList = createAsyncThunk(
  routes.nftsList,
  async (searchParams: any): Promise<any> => {
    const response = await services.getNftsList(searchParams);
    return response.data;
  },
);

const getNFTOne = createAsyncThunk(
  routes.oneNFt,
  async (id: any): Promise<any> => {
    const response = await services.getOneNft(id);
    return response.data;
  },
);

const getNFTUser = createAsyncThunk(
  routes.userNfts,
  async (): Promise<any> => {
    const response = await services.getUserNft();
    return response.data;
  },
);

const createNft = createAsyncThunk(
  routes.createNft,
  async (data: any): Promise<any> => {
    const response = await services.createNft(data);
    return response.data;
  },
);

const sellNft = createAsyncThunk<SellNftResponse, SellNftPayload>(
  routes.sellNft,
  async ({ id, price, count }) => {
    const response = await services.sellNft({ id, price, count });
    return response;
  }
);

const buyNft = createAsyncThunk<BuyNftResponse, BuyNftPayload>(
  routes.buyNft,
  async ({ id, ownerId, count }) => {
    const response = await services.buyNft({ id, ownerId, count });
    return response;
  }
);

export { getNFTList, getNFTOne, getNFTUser, createNft, sellNft, buyNft };