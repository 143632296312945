import { createSlice } from '@reduxjs/toolkit';
import type { TokenRow } from 'resources/types';
import { getTokens } from './thunks';

export interface HomeState {
  data: Array<TokenRow>;
  loading: boolean;
}

const initialState: HomeState = {
  data: [],
  loading: false,
};

/* eslint-disable no-param-reassign */

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getTokens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTokens.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTokens.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload;
    });
  },
});

const { reset } = homeSlice.actions;

export { reset, getTokens };

export default homeSlice;
