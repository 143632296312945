import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import { Investment } from 'resources/types';
import { roundNumber, getLastSeen, calculatePercentageIncrease } from 'utils';
import commaNumber from 'comma-number';

export interface CreateTokenProp {
  investmentsTokenData: Investment[] | [];
}

function Investments(props: CreateTokenProp) {
  const { investmentsTokenData } = props;
  return (
    <Grid container rowSpacing={1}>
      {
        investmentsTokenData?.map((tokenData) => {
          const { token, amountTon, amountToken } = tokenData;
          const { shortName, totalSupply, imageUrl, createdAt } = token;
          return (
            <Grid
              p={1}
              container
              direction='row'
              width='100%'
              // height={60}
              sx={{
                background: '#070707',
                borderRadius: '15px',
              }}
            >
              <Grid size={6} display='flex' pl={1} >
                <Grid>
                  <img src={imageUrl ?? ''} alt={'name'} width={40} height={40} style={{ borderRadius: '50%', marginRight: '8px' }} />
                </Grid>
                <Grid display='flex' flexDirection='column' justifyContent='center'>
                    <Typography
                      color='white'
                      variant='body2'
                      sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {shortName}
                      <PeopleIcon height={12} width={12} style={{marginLeft: '4px'}}/>
                      {totalSupply}
                    </Typography>
                  <Typography color='primary' variant='subtitle1'>
                    {getLastSeen(createdAt)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                size={6}
                display='flex'
                flexDirection='column'
                alignItems='flex-end'
                justifyContent='center'
              >
                <Typography color='white' variant='body2' px={1}>
                  {amountToken}
                </Typography>
                <Typography color='primary' variant='body2' px={1}>
                  {`${amountTon} Ton`}
                </Typography>
              </Grid>
            </Grid>
        )
        }
        )
      }
    </Grid>
  );
}

export default Investments;
