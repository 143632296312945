import BottomDrawerModal from 'components/main/Template/BottomDrawerModal';
import { Box, Button, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OurChannelProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function OurChannel({ isOpen, setIsOpen }: OurChannelProps) {
  const { t } = useTranslation();

  const ChannelContent = (
    <Box sx={{ bgcolor: '#000' }}>
      <Typography variant="body2" fontFamily="Montserrat">
        {t('channel_guide')}
      </Typography>
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button
          variant="contained"
          sx={(theme: Theme) => ({
            color: '#fff',
            background: theme.palette.gradient.primaryVertical,
            border: '1px solid rgba(255, 255, 255, 0.5)',
            textTransform: 'none',
            mb: 1,
            width: '100%',
            borderRadius: '12px',
          })}
          onClick={() => window.open('https://t.me/pumpbattle', '_blank')}
        >
          {t('go')}
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#FFFFFF33',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            color: '#fff',
            textTransform: 'none',
            width: '100%',
            borderRadius: '12px',
          }}
        >
          {t('cancel')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <BottomDrawerModal isOpen={isOpen} setIsOpen={setIsOpen} title={t('go_channel')}>
      {ChannelContent}
    </BottomDrawerModal>
  );
}

export default OurChannel;
