import tokensService from './token';
import walletService from './wallet';
import colorsSevice from './colors';
import getNftsList from './nft';
import SliderService from './sliders';
import LoginService from './login';
import HistoryService from './history';
export default {
  ...tokensService,
  ...colorsSevice,
  ...getNftsList,
  ...SliderService,
  ...walletService,
  ...colorsSevice,
  ...LoginService,
  ...HistoryService,
};
