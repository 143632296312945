import commaNumber from 'comma-number';
import moment from 'moment';
import { Typography, Box, Stack, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavLink, useNavigate } from 'react-router-dom';
import { dynamicRouts, routsPatterns } from 'resources/constants';
import { Token } from 'resources/types';
import { roundNumber, getLastSeen, calculatePercentageIncrease } from 'utils';
import { environment } from 'resources/constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { formatTimeLeft, TimeLeft } from 'utils/formatTime';
const { AWS_S3_BUCKET_URL } = environment;

export interface ListItemProps {
  token: Token;
  selectedTokenCategory?: string;
}

function TokenItem(props: ListItemProps) {
  const currentTheme = useTheme();
  const currentPrimaryColor = currentTheme.palette.primary.main;

  const { t } = useTranslation();
  const { token, selectedTokenCategory } = props;
  const navigate = useNavigate();

  const currentTime = moment.utc().unix();;
  const startAt = token.startAt - currentTime;
  const isStability = selectedTokenCategory === 'stability';

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(formatTimeLeft(startAt));

  const navLink = dynamicRouts.TOKENS_VIEW_OWNER(token.ownerId);
  const imageSrc = `${AWS_S3_BUCKET_URL}/${token?.imageUrl}`;

  useEffect(() => {
    if (startAt <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft: TimeLeft) => {
        const remainingSeconds =
          prevTimeLeft.days * 86400 + prevTimeLeft.hours * 3600 + prevTimeLeft.minutes * 60 + prevTimeLeft.seconds - 1;

        return formatTimeLeft(remainingSeconds);
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [startAt]);

  const onClick = () => {
    navigate(dynamicRouts.TOKENS_VIEW_TOKEN(token.id));
  };

  return (
    <Box
      sx={(theme) => ({
        mt: 0.5,
        borderRadius: '15px',
        bgcolor: theme.palette.secondary.main,
        padding: `${startAt ? '4px 6px 6px 6px' : '6px'}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      })}
      onClick={onClick}
    >
      {startAt > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px 0 5px',
          }}
        >
          <Typography color={currentPrimaryColor} fontSize="12px" fontWeight={400} fontFamily="Audiowide">
            {t('closedForSale')}
          </Typography>
          {isStability && (
            <Typography color={currentPrimaryColor} fontSize="12px" fontWeight={500} fontFamily="Montserrat">
              {`${timeLeft.days} ${t('days')} ${timeLeft.hours} ${t('h')} ${timeLeft.minutes} ${t('m')} ${
                timeLeft.seconds
              } ${t('s')}`}
            </Typography>
          )}
        </Box>
      ) : null}
      <Box sx={{
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
      }}>
      <Box display="flex" alignItems="center">
        <img
          src={imageSrc || ''}
          alt={token.fullName}
          width={40}
          height={40}
          style={{ borderRadius: '50%', marginRight: '12px' }}
        />
        <Box>
          <Typography color="white" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
            {token.shortName}
          </Typography>
          <Typography color="white" fontSize="10px" fontWeight={500} fontFamily="Montserrat">
            {getLastSeen(token.createdAt)}
            {navLink && (
              <NavLink
                to={navLink}
                onClick={(event) => event.stopPropagation()}
                style={{
                  textDecoration: 'none',
                  marginLeft: '8px',
                  color: 'inherit',
                }}
              >
                <Typography color="primary" variant="caption">
                  {token.walletAddress}
                </Typography>
              </NavLink>
            )}
          </Typography>
        </Box>
      </Box>

      <Stack flexDirection="row" textAlign="right" alignItems="center" gap={1}>
        <Box>
          <Typography color="white" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
            {`MC $${commaNumber(roundNumber(token.circulatingSupply * token.currentPrice))}`}
          </Typography>
          <Typography color="primary" fontSize="12px" fontWeight={500} fontFamily="Montserrat">
            {`${token.changed24Hours} %`}
          </Typography>
        </Box>
        <IconButton onClick={() => navigate(routsPatterns.TOKENS)}>
          {' '}
          <ArrowForwardIosIcon sx={{ fontSize: 14 }} color="white" />
        </IconButton>
      </Stack>
    </Box>
    </Box>
  );
}

export default TokenItem;
