export const routsPatterns: { [key: string]: string } = {
  HOME: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',
  PASSWORD_AND_SECURITY: '/settings/security',
  RULES: '/settings/rules',
  POLICY: '/settings/policy',
  THEME: '/settings/theme',
  TASKS: '/tasks',
  INVITATION: '/tasks/invitation',
  TOKENS: '/tokens',
  TOKENS_CREATE: '/tokens/create',
  TOKENS_VIEW_TOKEN: '/tokens/view/:tokenId',
  TOKENS_ABOUT_TOKEN: '/tokens/about/:tokenId',
  TOKEN_OWNER: '/tokens/owner/:id',
  STORY: '/history',
  BALANCE: '/history/balance',
  HISTORYALL: '/history/all',
  TRANSACTIONS_STORY: '/history/balance/transactions',
  NFT_MARKET: '/nft/market',
  GAME: '/card-game',
  BUY_NFT: '/nft/buy/:id',
  SELL_NFT: '/nft/sell/:id',
  DOCUMENTATION: '/documentation',
};

export const generateUserInviteLink = (dynamicValue: string | number) =>
  `https://t.me/pumpbattlebot?start=${dynamicValue}`;

// Rout patterns where the action bar should be visible
export const actionBarRoutes = [
  routsPatterns.HOME,
  routsPatterns.SETTINGS,
  routsPatterns.PASSWORD_AND_SECURITY,
  routsPatterns.RULES,
  routsPatterns.POLICY,
  routsPatterns.THEME,
  routsPatterns.TASKS,
  routsPatterns.INVITATION,
  routsPatterns.TOKENS,
  routsPatterns.TOKEN_OWNER,
  routsPatterns.STORY,
  routsPatterns.HISTORYALL,
  routsPatterns.BALANCE,
  routsPatterns.TRANSACTIONS_STORY,
  routsPatterns.NFT_MARKET,
  routsPatterns.BUY_NFT,
  routsPatterns.GAME,
];

export const dynamicRouts = {
  TOKENS_VIEW_TOKEN: (tokenId: number) => `/tokens/view/${tokenId}`,
  TOKENS_VIEW_OWNER: (ownerId: number) => `/tokens/owner/${ownerId}`,
  TOKENS_ABOUT_TOKEN: (ownerId: string) => `/tokens/about/${ownerId}`,
};

export const endpoints = {
  TOKENS: '/users/tokens',
  TOKENS_BY_CATEGORY: '/tokens/all',
};

export const API_BASE_URL = 'https://mmhub.io/api';


export const refreshToken =
  'Iy/qkNEm/5NA49sYKYP2wz98w3YWFg4mTje6JpNRemftgQnngxNgZuspi7pNAaiOIvzBDAQ1uJnIwkZ594nFpHor5bWAdu0z6C2rFsDT9aME1v41XwktQnjLmuyQTQ+/nK2LxiE6Muso0NIfDpkChuWGYhFNaeyWuZHnuSKzhHle4X54YKcaTUt3r4rhvXoksz/o1iioqKYMMkFzgttUk0bU8TFsGZ+bzfDQwSb0UTrz+pMVKayk8AXxNXs64uHv8AbMJz5nHeGUHPyPyrpYlQhgUfmgMTq3v+KxcvlL5b5uqIby5HVkqES7ZSoREiHvY/yQfcXLVLFJg3NqBfVepw== | RtqxxnE56c8tW0uqqXKuGOr6+U2+5Gx90QBR4K67KfJNVFz7CV2msdtbGgGnmmHeVPXaxAqeLnp/TZVo6qXqPl+k88ht0Pn1l2zHqQ28hkcTNrYgP3vPJyD8Ypbtj1cfTjsqayK4yG90pgFvh6BW7jKKpaDfyegR7WDqHRw+qmJjHpVGHAW0EGsaWCityUDwFEfMs4jMjPnI5FB4qMW0hMMXgo63DzjX8Yfpk/KGG8dSpkBZzP6qntRRaoanLZlASp0nYWCO5chfPFjvKircvhAXNbR/9HCYb/M8YwjggYscTddyol/K7fioYNXYJAeG/tp97h5vSP3712Y7VpKj9Q==';
export const accessToken =
  'Iy/qkNEm/5NA49sYKYP2wz98w3YWFg4mTje6JpNRemftgQnngxNgZuspi7pNAaiOIvzBDAQ1uJnIwkZ594nFpHor5bWAdu0z6C2rFsDT9aME1v41XwktQnjLmuyQTQ+/nK2LxiE6Muso0NIfDpkChuWGYhFNaeyWuZHnuSKzhHle4X54YKcaTUt3r4rhvXoksz/o1iioqKYMMkFzgttUk0bU8TFsGZ+bzfDQwSb0UTrz+pMVKayk8AXxNXs64uHv8AbMJz5nHeGUHPyPyrpYlQhgUfmgMTq3v+KxcvlL5b5uqIby5HVkqES7ZSoREiHvY/yQfcXLVLFJg3NqBfVepw== | RtqxxnE56c8tW0uqqXKuGOr6+U2+5Gx90QBR4K67KfJNVFz7CV2msdtbGgGnmmHeVPXaxAqeLnp/TZVo6qXqPl+k88ht0Pn1l2zHqQ28hkcTNrYgP3vPJyD8Ypbtj1cfTjsqayK4yG90pgFvh6BW7jKKpaDfyegR7WDqHRw+qmJjHpVGHAW0EGsaWCityUDwFEfMs4jMjPnI5FB4qMW0hMMXgo63DzjX8Yfpk/KGG8dSpkBZzP6qntRRaoanLZlASp0nYWCO5chfPFjvKircvhAXNbR/9HCYb/M8YwjggYscTddyol/K7fioYNXYJAeG/tp97h5vSP3712Y7VpKj9Q==';

import DocImage1 from 'assets/images/111c6146611cbb96dfbd10175ffb39f7059.png';
import DocImage2 from 'assets/images/2229115f950983e64291996f7b256335e4b.png';
import DocImage3 from 'assets/images/333001a517a9f9aa2a4a374c8d89fcf6ac5.png';
import DocImage4 from 'assets/images/44438c9360ce355fc199b7d1d1c3ce726e4.png';
import DocImage5 from 'assets/images/55Group.png';

export const docSlides = [
  {
    image: DocImage1,
    title: 'slide1.title',
    description: 'slide1.description',
  },
  {
    image: DocImage2,
    title: 'slide2.title',
    description: 'slide2.description',
  },
  {
    image: DocImage3,
    title: 'slide3.title',
    description: 'slide3.description',
  },
  {
    image: DocImage4,
    title: 'slide4.title',
    description: 'slide4.description',
  },
  {
    image: DocImage5,
    title: 'slide5.title',
    description: 'slide5.description',
  },
];

import Image1 from 'assets/images/842052e6669404ff86e9b41709ddb50f.png';
import Image2 from 'assets/images/Screenshot 2024-11-25 132425.png';
import Image3 from 'assets/images/Screenshot 2024-11-25 132757.png';

export const slide1 = [
  { id: 'image1', img: Image2 },
  { id: 'image1', img: Image1 },
  { id: 'image1', img: Image3 },
];

export const slide2 = [
  { id: 'image1', img: Image1 },
  { id: 'image2', img: Image2 },
];

export const slide3 = [
  { id: 'image1', img: Image3 },
  { id: 'image1', img: Image1 },
  { id: 'image2', img: Image2 },
];

export const responsiveWidths = {
  xs: '100%',
  sm: '80%',
  md: '65%',
  lg: '65%',
  xl: '50%',
};

export const appThemesInfo = {
  publicThemes: [
    {
      themeName: 'lightGreenTheme',
      color: '#75FF47',
      background: 'linear-gradient(360deg, #75FF47 1.54%, #119E04 100%)',
    },
    {
      themeName: 'softCyanTheme',
      color: '#2193B0',
      background: 'linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%)',
    },
    {
      themeName: 'vividMagentaTheme',
      color: '#DA22FF',
      background: 'linear-gradient(360deg, #DA22FF 1.54%, #9733EE 100%)',
    },
    {
      themeName: 'lightOrangeTheme',
      color: '#FFB347',
      background: 'linear-gradient(90deg, #FFB347 0%, #FFCC33 100%)',
    },
    {
      themeName: 'vividRedTheme',
      color: '#FF512F',
      background: 'linear-gradient(360deg, #FF512F 1.54%, #DD2476 100%)',
    },
    {
      themeName: 'veryDarkGrayTheme',
      color: '#4C4C4C',
      background: 'linear-gradient(360deg, #4C4C4C 1.54%, #9D9EA3 100%)',
    },
    {
      themeName: 'lightGrayishMagentaTheme',
      color: '#F4C4F3',
      background: 'linear-gradient(90deg, #F4C4F3 0%, #FC67FA 100%)',
    },
    {
      themeName: 'softPinkTheme',
      color: '#FD746C',
      background: 'linear-gradient(90deg, #FD746C 0%, #FF9068 100%)',
    },
  ],
  privateThemes: [
    {
      themeName: 'darkModerateYellowTheme',
      color: '#B1993A',
      background:
        'linear-gradient(135.34deg, #856220 15.43%, #F4E683 34.91%, #BF923D 50.85%, #4E341B 68.56%, #F1EA82 86.26%)',
      paid: false,
    },
    {
      themeName: 'darkGrayishBlueTheme',
      color: '#8489F5',
      background:
        'linear-gradient(138.8deg, #CCE8FE 5.7%, #CDA0FF 27.03%, #8489F5 41.02%, #CDF1FF 68.68%, #B591E9 94%)',
      paid: false,
    },
    {
      themeName: 'slightlyDesaturatedGreenTheme',
      color: '#7BB565',
      background: 'linear-gradient(314.54deg, #EBC738 3.25%, #86B861 51.02%, #1B9C85 96%)',
      paid: false,
    },
    {
      themeName: 'verySoftCyanTheme',
      color: '#A2ECDD',
      background:
        'linear-gradient(218.51deg, #FDFFFE 11%, #7ABBAC 31.04%, #B1FFEF 44.99%, #8AD2C3 57.63%, #CFFEF4 75.93%, #6CA196 90.75%, #35544E 98.15%)',
      paid: false,
    },
    {
      themeName: 'vividOrangeTheme',
      color: '##F88E01',
      background: 'linear-gradient(135deg, #1E9600 0%, #FFF200 49.16%, #FF0000 100%)',
      paid: false,
    },
    {
      themeName: 'slightlyDesaturatedCyan',
      color: '##F88E01',
      background: 'linear-gradient(135deg, #FF1E56 0%, #F9C942 50%, #1E90FF 100%)',
      paid: false,
    },
    {
      themeName: 'darkCyanTheme',
      color: '#01B17A',
      background: 'linear-gradient(135deg, #051937 0%, #004D7A 25%, #008793 50%, #00BF72 75%, #A8EB12 100%)',
      paid: true,
    },
    {
      themeName: 'vividGoldTheme',
      color: '#F88E01',
      background: 'linear-gradient(135deg, #FF0000 0%, #F7A600 50.11%, #FFE49E 100%)',
      paid: false,
    },
  ],
};

export const publicThemesColors = {
  lightGreenTheme: 'lightGreenTheme',
  softCyanTheme: 'softCyanTheme',
  vividMagentaTheme: 'vividMagentaTheme',
  lightOrangeTheme: 'lightOrangeTheme',
  vividRedTheme: 'vividRedTheme',
  veryDarkGrayTheme: 'veryDarkGrayTheme',
  lightGrayishMagentaTheme: 'lightGrayishMagentaTheme',
  softPinkTheme: 'softPinkTheme',
};

export const privateThemesColors = {
  darkModerateYellowTheme: 'darkModerateYellowTheme',
  darkGrayishBlueTheme: 'darkGrayishBlueTheme',
  slightlyDesaturatedGreenTheme: 'slightlyDesaturatedGreenTheme',
  verySoftCyanTheme: 'verySoftCyanTheme',
  vividOrangeTheme: 'vividOrangeTheme',
  slightlyDesaturatedCyan: 'slightlyDesaturatedCyan',
  darkCyanTheme: 'darkCyanTheme',
  vividGoldTheme: 'vividGoldTheme',
};

export { default as environment } from './environment';
