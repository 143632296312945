import { Theme, Button, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAppDispatch } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostTokenBuyData, PostTokenSellData } from 'store/token';
import { getTokenPrice } from 'services/token';

import { useParams } from 'react-router-dom';
import { showMessage } from 'store';
import { useSelector } from 'react-redux';
import { selectUserData } from 'store/login';
import useTokenDetails from 'hooks/useTokenDetails';
import { useDebounce } from 'use-debounce';

function truncateToDecimals(number: string, decimals: number) {
  const str = number.toString();
  const decimalIndex = str.indexOf('.');

  if (decimalIndex === -1) return number;

  return parseFloat(str.substring(0, decimalIndex + decimals + 1));
}
function SellToken() {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<'buy' | 'sell' | null>('buy');
  const { tokenDetails } = useTokenDetails(tokenId ?? '');
  const [calculatedValue, setCalculatedValue] = useState('');
  const [debouncedValue] = useDebounce(amount, 450);

  const userData = useSelector(selectUserData);
  const handleActionSelect = (action: 'buy' | 'sell') => {
    setSelectedAction(action);
    setAmount('');
  };
  useEffect(() => {
    const getCalculation = async () => {
      try {
        const {
          data: {
            data: { count },
          },
        } = await getTokenPrice({
          id: tokenId || '',
          type: selectedAction || '',
          amount: debouncedValue,
        });
        setCalculatedValue(count.toFixed(10));
      } catch (error) {
        console.log(error);
      }
    };
    getCalculation();
  }, [debouncedValue]);
  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const index = value.indexOf('.');

    if (index != -1) {
      setAmount(truncateToDecimals(value, 2).toString());
    } else {
      setAmount(value);
    }
  };

  const handleActionConfirm = async () => {
    if (!selectedAction) {
      return;
    }

    if (amount && !isNaN(Number(amount))) {
      const payload = { id: tokenId, amount: Number(amount) };
      if (selectedAction === 'buy') {
        const data = await dispatch(PostTokenBuyData(payload));

        if ((data as any)?.error?.message === 'Request failed with status code 400') {
          showMessage({ messages: ['Not enough balance'] });
        }
      } else {
        if (!tokenDetails?.info?.userBalance) {
          showMessage({ messages: ['Not Enough Balance'] });
          return;
        }
        const data = await dispatch(PostTokenSellData(payload));
        if ((data as any)?.error?.message === 'Request failed with status code 404') {
          showMessage({ messages: ['Currently you cant sell'] });
        }
      }
      setAmount('');
      // setSelectedAction(null);
    }
  };
  const handleMaxClick = () => {
    const maxValue = tokenDetails?.details?.liquidation;
    if (userData.balance && Number(userData.balance) > maxValue) {
      setAmount(String(maxValue.toFixed(2)));
    } else {
      setAmount(String(Number(userData.balance).toFixed(2) || 0));
    }
  };

  const handleSellMaxClick = () => {
    const maxValue = tokenDetails?.info?.userBalance;
    if (maxValue) {
      setAmount(String(maxValue.toFixed(2)));
    } else {
      setAmount('0');
    }
  };
  return (
    <Grid px={2} mt={2} spacing={1} container width="100%">
      <Grid size={6}>
        <Button
          type="submit"
          color="white"
          variant={'contained'}
          onClick={() => handleActionSelect('buy')}
          sx={(theme: Theme) => ({
            py: 0.5,
            width: '100%',
            fontWeight: 700,
            borderRadius: '12px',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '10px',
            background: selectedAction === 'buy' ? theme.palette.gradient.primaryVertical : undefined,
          })}
        >
          {t('view_token_buy')}
        </Button>
      </Grid>

      <Grid size={6}>
        <Button
          type="submit"
          color="white"
          variant={'contained'}
          onClick={() => handleActionSelect('sell')}
          sx={(theme: Theme) => ({
            py: 0.5,
            width: '100%',
            fontWeight: 700,
            borderRadius: '12px',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '5px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '5px',
            background: selectedAction === 'sell' ? theme.palette.gradient.primaryVertical : undefined,
          })}
        >
          {t('view_token_sell')}
        </Button>
      </Grid>

      <Grid size={12} style={{ position: 'relative' }}>
        <OutlinedInput
          fullWidth
          type="number"
          placeholder={t('view_token_enter_quantity')}
          value={amount}
          onChange={handleInputChange}
        />
        {selectedAction === 'buy' ? (
          <Button
            variant="contained"
            onClick={handleMaxClick}
            size="small"
            style={{ position: 'absolute', width: '45px', height: '25px', right: 5, top: 13, padding: 0, margin: 0 }}
          >
            Max
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSellMaxClick}
            size="small"
            style={{ position: 'absolute', width: '45px', height: '25px', right: 5, top: 13, padding: 0, margin: 0 }}
          >
            Max
          </Button>
        )}
        <p
          style={{
            margin: 0,
            position: 'absolute',
            width: '45px',
            height: '25px',
            marginRight: '150px',
            right: 0,
            top: 16,
            padding: 0,
            color: 'black',
            fontWeight: 600,
            fontSize: '14px',
            textWrap: 'nowrap',
          }}
        >
          {!amount?.length ? null : `~${Number(calculatedValue).toFixed(2)}`}
        </p>
      </Grid>

      <Grid mt={2} size={12}>
        <Button
          variant="contained"
          onClick={handleActionConfirm}
          sx={(theme: Theme) => ({
            width: '100%',
            borderWidth: 2,
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            background: theme.palette.gradient.primaryVertical,
          })}
        >
          {t('view_token_enter_confirm')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default SellToken;
